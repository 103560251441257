import React, { useState, useEffect } from "react";
import "./priceDropdown.css";
import TextButton from "../../../../components/Buttons/TextButton/TextButton";

export default function PriceDropdown({
  selectedPriceRange,
  setSelectedPriceRange,
  closePriceDropdown,
}) {
  const [, setIsSaveButtonPressed] = useState(false);
  const [minPrice, setMinPrice] = useState(selectedPriceRange.min);
  const [maxPrice, setMaxPrice] = useState(selectedPriceRange.max);

  const handleMinInputChange = (event) => {
    let value = event.target.value.trim();
    if (value === "") {
      value = 0;
    } else {
      value = Math.max(Number(value), 0);
    }
    setMinPrice(value);
    handlePriceRangeChange({
      min: value,
      max: maxPrice,
    });
  };

  const handleMaxInputChange = (event) => {
    let value = event.target.value.trim();
    if (value === "") {
      value = 50000;
    } else {
      value = Math.max(Number(value), minPrice);
      value = Math.min(value, 50000);
    }
    setMaxPrice(value);
    handlePriceRangeChange({
      min: minPrice,
      max: value,
    });
  };

  const handlePriceRangeChange = ({ min, max }) => {
    setMinPrice(min);
    setMaxPrice(max);
    setSelectedPriceRange({ min, max });

    sessionStorage.setItem("selectedPriceRange", JSON.stringify({ min, max }));
  };

  const handleClearButtonClick = () => {
    setSelectedPriceRange({ min: 0, max: 50000 });
    setMinPrice(0);
    setMaxPrice(50000);
    sessionStorage.removeItem("selectedPriceRange");
    setIsSaveButtonPressed(false);
  };

  useEffect(() => {
    const savedSelectedPriceRange = JSON.parse(
      sessionStorage.getItem("selectedPriceRange")
    );
    if (savedSelectedPriceRange) {
      setSelectedPriceRange(savedSelectedPriceRange);
      setMinPrice(savedSelectedPriceRange.min);
      setMaxPrice(savedSelectedPriceRange.max);
      if (
        savedSelectedPriceRange.min > 0 ||
        savedSelectedPriceRange.max < 50000
      ) {
        setIsSaveButtonPressed(true);
      }
    }
  }, [setSelectedPriceRange]);

  return (
    <div className="price-dropdown">
      <p>Kostnad</p>
      <div className="price-range-slider">
        <div className="price-slider-container">
          <div className="price-slider-price">
            <div className="price-slider__left-value">
              <p>Min pris:</p>
              <input
                type="number"
                min={0}
                max={maxPrice}
                defaultValue={minPrice}
                value={minPrice}
                onChange={handleMinInputChange}
              />
            </div>
            <div className="price-slider__right-value">
              <p>Max pris:</p>
              <input
                type="number"
                min={0}
                max={50000}
                value={maxPrice}
                defaultValue={maxPrice}
                onChange={handleMaxInputChange}
              />
              {maxPrice === 50000 && (
                <span className="price-price-plus">+</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="price-button-wrapper">
        <TextButton label="Rensa" onClick={handleClearButtonClick} />
      </div>
    </div>
  );
}
