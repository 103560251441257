import React, { useRef } from 'react';
import './filterDropdownButton.css';
import arrowUp from '../../../assets/icons/arrow-up.svg';

export default function FilterDropdownButton({ label, dropdownContent, isActive, isDropdownVisible, toggleDropdown }) {
  const dropdownRef = useRef();
  const buttonRef = useRef();

  const handleClick = () => {
    toggleDropdown();
  };

  return (
    <div className={`filter-dropdown-container`}>
      <div
        className={`filter-dropdown-button ${isActive ? 'active' : ''}`}
        ref={buttonRef}
        onClick={() => {
          handleClick();

        }}
      >
        <div className={`button-content`} >
          {label}
          <img
            className={`arrow-icon ${isDropdownVisible ? 'flipped' : ''}`}
            src={arrowUp}
            alt={isDropdownVisible ? 'Arrow pointing down' : 'Arrow pointing up'}
          />
        </div>
      </div>
      {isDropdownVisible && (
        <div className="dropdown" ref={dropdownRef}>
          {dropdownContent}
        </div>
      )}
    </div>
  );
}

