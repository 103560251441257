import React, { useEffect, useState, useContext } from "react";
import FacilitiesForm from "./FacilitiesForm";
import Timeslots from "./Timeslots";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import importedMunicipalities from "../../../assets/municipalities.json";
import axios from "axios";
import AdPreviewContext from "../../../context/AdPreviewContext";
import { Image, Transformation } from "cloudinary-react";
import { v4 as uuidv4 } from "uuid";
import ConfirmationModal from "../../../components/ConfirmationModal";
import importedRooms from "../../../assets/roomTypes.json";
import { requiredFieldsCheck, authenticateForm } from "../utilities/formAuth";
import Loading from "./Loading";
import { backend } from "../../../backend";
import { logAxiosError } from "../../../logging/error";
import WarningModal from "../../../components/WarningModal";
import { useCallbackPrompt } from "../../../context/useCallbackPrompt";
import { useAuth } from "../../../context/AuthContext";
import { Tooltip as ReactTooltip } from 'react-tooltip'


const defaultFormData = {
  name: "",
  owner_name: "",
  address: "",
  city: "",
  municipality: "",
  zip_code: "",
  type_of_room: "",
  area: "",
  capacity: "",
  workspaces: "",
  facilities: [],
  description: "",
  images: [],
  timeslots: {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  },
  fully_vacant: false,
  hourly_rent: "",
  daily_rent: "",
  monthly_rent: "",
  workspace_rent: "",
  vat_tax_required: false,
};
const { roomTypes } = importedRooms
const { municipalities } = importedMunicipalities

function EditAd(props) {
  const [formData, setFormData] = useState(defaultFormData);
  const [newImages, setNewImages] = useState([]);

  // These are to prevent user from accessing preview page without necessary info
  const [disableSubmit, setDisableSubmit] = useState(true);

  // Set to true when going to preview, triggers useEffect that navigates to preview
  const [viewPreview, setViewPreview] = useState(false);

  const [pageHeading, setPageHeading] = useState("");
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const { adPreviewInfo, setAdPreviewInfo } = useContext(AdPreviewContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const auth = useAuth();

  // For loading state, while waiting
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ImageTooBig, setImageTooBig] = useState(null);

  // Warn users when leaving that their ad is not saved
  // enable and disable the prompt with this state
  const [showPrompt, setShowPrompt] = useState(false);
  const {
    showPrompt: showWarningModal,
    confirmNavigation,
    cancelNavigation,
  } = useCallbackPrompt(showPrompt);

  const fetchFormData = async () => {
    try {
      const { data } = await axios.get(backend("/api/listings/get-by-id"), {
        params: {
          token: localStorage.getItem("token"),
          id: id,
        },
      });
      if (data.status) {
        setFormData({ ...formData, ...data.ad });
        setPageHeading("Redigera annons");
      }
    } catch (error) {
      logAxiosError(error);
      // TODO: notify user
    }
  };

  useEffect(() => {
    const { info, images, hasChanged } = adPreviewInfo;

    if (hasChanged) {
      setShowPrompt(true);
    }

    // If user returns from preview, set updated info and images
    if (location.state?.from === "preview") {
      setFormData(info);
      setNewImages(images);

      // And reset previewInfo
      setAdPreviewInfo({ info: {}, images: [], hasChanged: false });
      setPageHeading(info?.name ? info.name : "Redigera annons");
    } else {
      // Else fetch ad info
      fetchFormData();
    }

    // eslint-disable-next-line
  }, [location]);

  // Disable submit and preview if required fields are empty
  useEffect(() => {
    setDisableSubmit(!requiredFieldsCheck(formData, newImages));
  }, [formData, newImages]);

  const saveAdHandler = (activate) => {
    const active = activate === 'activate'
      ? true
      : activate === 'inactivate'
        ? false
        : formData?.active

    // Only send request if form authentication doesn't fail
    if (authenticateForm(formData, newImages, setInvalidFields)) {
      setLoading(true);
      saveChangesRequest(active);
    }
  };

  const saveChangesRequest = async (active) => {
    try {
      const { data } = await axios.patch(backend("/api/listings/edit"), {
        token: localStorage.getItem("token"),
        ad_id: id,
        data: {
          ...formData,
          images: [...formData?.images, ...newImages],
          active: active,
        },
      });

      if (data.status) {
        uploadImages();
      }
    } catch (error) {
      logAxiosError(error);
      setLoading(false);
      setShowErrorModal(true);
      if (error.response?.status === 401) {
        // invalid JWT token -> log out and return to landing page
        auth.logout();
      }
    }
  };

  const uploadImages = async () => {
    const imageData = new FormData();
    let errorOccured = false;

    for (let image of newImages) {
      imageData.append("file", image.file);
      imageData.append("upload_preset", "oi2aoqjs");
      imageData.append("public_id", `/${id}/${image.id}`);

      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/vakansa/image/upload",
        imageData
      );

      // TODO: will non 200 response throw and thus need try catch?
      if (response.status !== 200) {
        errorOccured = true;
      }
    }

    // All steps are done, set loading to false
    // and set custom error message if errors occured
    setLoading(false);
    setErrorMessage(
      errorOccured
        ? "Ett problem uppstod under bild uppladdningen. Det kan ha hänt att inte alla bilder blev uppladdade."
        : ""
    );

    // Show modal passed as argument
    if (!errorOccured) {
      setShowPrompt(false);
      setShowSaveChangesModal(true);
      setAdPreviewInfo({ info: {}, images: [], hasChanged: false });
    } else {
      setShowPrompt(true);
      setShowErrorModal(true);
    }
  };

  // Run on every on change handler
  const formHasChanged = () => {
    // Set hasChanged to true after changing form
    setAdPreviewInfo((prev) => ({ ...prev, hasChanged: true }));

    // Show prompt after form has changed
    setShowPrompt(true);
  };

  const onChangeHandler = (e) => {
    const isNumericInput = e.target.inputMode === "numeric";
    const isNumericValue = new RegExp(/^(\/?[0-9])*\/?$/).test(
      e.target.value
    );

    // Don't set value if non-numeric value (excl. spaces)
    // is typed into a numeric text field. For example:
    // <input type="text" inputMode="numeric" />
    if (isNumericInput && !isNumericValue) return;

    // Run on every valid onChange
    formHasChanged();

    // Else set value as usual
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const onImagesChange = (e) => {
    const files = e.target.files;

    // User can only add max 12 images at once (max is 12)
    if (files.length < 1 || files.length > 12) return;
    if (newImages.length + files.length > 12) {
      setImageTooBig("Du kan inte ladda upp fler än 12 bilder. Du har nått maximum");
      return;
    }

    // Check file sizes and reject if larger than 10MB
    const newImageObjects = [...files]
      .filter((file) => file.size <= 10 * 1024 * 1024)
      .map((file) => {
        // Check if an image with the same name already exists in the newImages state
        const existingImage = newImages.find((image) => image.file.name === file.name);
        if (existingImage) {
          console.warn(`Image "${file.name}" already exists in the list`);
          setImageTooBig(`Image "${file.name}" already exists in the list`);
          return null; // return null if image already exists
        }
        return {
          url: URL.createObjectURL(file),
          id: uuidv4(),
          file,
        };
      })
      .filter((image) => image !== null); // filter out null values from the array

    // Notify user if any images were rejected due to size
    const rejectedImages = [...files].filter((file) => file.size > 10 * 1024 * 1024);
    if (rejectedImages.length > 0) {
      setImageTooBig(
        `Bilden du försökte ladda upp var större än 10MB. Försök med en mindre bild`
      );
    } else {
      setImageTooBig(null); // set imageTooBig to null if all images were within size limits
    }

    // Run on every valid onChange
    formHasChanged();

    setNewImages((prevData) => [...prevData, ...newImageObjects]);
  };

  // For removing images the user has just added
  const removeNewImageHandler = (image) => {
    // Run on every valid onChange
    formHasChanged();

    setNewImages((prevData) =>
      prevData.filter((currentImage) => currentImage.id !== image)
    );
  };

  // For removing images already stored in cloudinary
  const removeOldImageHandler = (image) => {
    // Run on every valid onChange
    formHasChanged();

    setFormData((prevData) => ({
      ...prevData,
      images: formData?.images.filter((currentImage) => currentImage !== image),
    }));
  };

  useEffect(() => {
    if (!viewPreview && !showPrompt) return;

    // Navigate to preview page
    navigate("/mina-sidor/redigera-annons/forhandsvy", {
      state: { prevRoute: location.pathname },
    });
    // eslint-disable-next-line
  }, [viewPreview]);

  const adPreviewHandler = () => {
    // Disable prompt
    setShowPrompt(false);

    // Set the AdPreviewInfo (context) to use on preview page
    setAdPreviewInfo((prev) => ({
      ...prev,
      info: formData,
      images: newImages,
    }));

    // Navigate to preview page
    setViewPreview(true);
  };

  const validateField = (e) => {
    const { value, name } = e.target;
    const defaultDropdownValues = ["Välj kommun", "Typ av lokal"];
    const invalidDropdownValue = defaultDropdownValues.includes(value);

    if (!value || invalidDropdownValue) {
      setInvalidFields([...invalidFields, name]);
    } else {
      setInvalidFields(invalidFields.filter(
        (field) => field !== name
      ));
    }
  };

  return pageHeading && ( // Only show once listing data is fetched
    <>
      <EditAdContainer>
        <h2>{pageHeading}</h2>
        <form>
          <div className="edit-ad-input-row">
            <div className="edit-ad-input-col edit-ad-input-col-lg">
              <label htmlFor="editAdOwner">
                Fastighetsägare*
                {invalidFields.includes("owner_name") && (
                  <span className="edit-ad-error-msg">
                    Ange en giltig fastighetsägare{" "}
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="2rem"
                      {...props}
                    >
                      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                    </svg>
                  </span>
                )}
              </label>
              <input
                className="edit-ad-input-fastighet"
                type="text"
                id="editAdAddress"
                name="owner_name"
                placeholder="Fastighetsägare"
                value={formData?.owner_name}
                onChange={onChangeHandler}
                onBlur={validateField}
              />
            </div>
          </div>
          <div className="edit-ad-input-row">
            <div className="edit-ad-input-col edit-ad-input-col-lg">
              <label htmlFor="editAdAddress">
                Adress*
                {invalidFields.includes("address") && (
                  <span className="edit-ad-error-msg">
                    Ange en giltig adress{" "}
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="2rem"
                      {...props}
                    >
                      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                    </svg>
                  </span>
                )}
              </label>
              <input
                type="text"
                id="editAdAddress"
                name="address"
                placeholder="Adress"
                value={formData?.address}
                onChange={onChangeHandler}
                onBlur={validateField}
              />
            </div>
            <div className="edit-ad-input-col edit-ad-input-col-md">
              <label htmlFor="editAdCity">
                Stad*
                {invalidFields.includes("city") && (
                  <span className="edit-ad-error-msg">
                    Ange stad <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="2rem"
                      {...props}
                    >
                      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                    </svg>
                  </span>
                )}
              </label>
              <input
                type="text"
                id="editAdCity"
                name="city"
                placeholder="Stad"
                value={formData?.city}
                onChange={onChangeHandler}
                onBlur={validateField}
              />
            </div>
            <div className="edit-ad-input-col edit-ad-input-col-md">
              <label htmlFor="editAdMunicipality">
                Kommun*
                {invalidFields.includes("municipality") && (
                  <span className="edit-ad-error-msg">
                    Ange kommun <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="2rem"
                      {...props}
                    >
                      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                    </svg>
                  </span>
                )}
              </label>
              <div className="edit-ad-select-wrapper">
                <select
                  id="editAdMunicipality"
                  name="municipality"
                  // defaultValue="Välj kommun"
                  value={formData?.municipality}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                >
                  <option hidden>Välj kommun</option>
                  <option>Stockholm </option>
                  <option>Göteborg </option>
                  <option>Malmö </option>
                  <option>Uppsala</option>
                  <optgroup label="Alla kommuner">
                    {municipalities.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </optgroup>
                </select>
                <img src="/images/icons/arrow-down.svg" alt="" />
              </div>
            </div>
            <div className="edit-ad-input-col edit-ad-input-col-sm">
              <label htmlFor="editAdZip">
                Postkod*
                {invalidFields.includes("zip_code") && (
                  <span className="edit-ad-error-msg">
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="2rem"
                      {...props}
                    >
                      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                    </svg>
                  </span>
                )}
              </label>
              <input
                type="text"
                id="editAdZip"
                name="zip_code"
                placeholder="Postkod"
                value={formData?.zip_code}
                onChange={onChangeHandler}
                onBlur={validateField}
              />
            </div>
          </div>
          <div className="edit-ad-input-row">
            <div className="edit-ad-input-col edit-ad-input-col-md">
              <label htmlFor="editAdType">
                Lokal*
                {invalidFields.includes("type_of_room") && (
                  <span className="edit-ad-error-msg">
                    Ange lokaltyp <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="2rem"
                      {...props}
                    >
                      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                    </svg>
                  </span>
                )}
              </label>
              <div className="edit-ad-select-wrapper edit-ad-input-col edit-ad-input-col-md">
                <select
                  id="editAdType"
                  name="type_of_room"
                  value={formData?.type_of_room}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                >
                  <option hidden>Typ av lokal</option>
                  {roomTypes.map((roomType, i) => (
                    <option key={i}>{roomType}</option>
                  ))}
                </select>
                <img src="/images/icons/arrow-down.svg" alt="" />
              </div>
            </div>
            <div className="edit-ad-input-col edit-ad-input-col-sm">
              <label htmlFor="editAdArea">
                Yta*
                {invalidFields.includes("area") && (
                  <span className="edit-ad-error-msg">
                    Ange yta <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="2rem"
                      {...props}
                    >
                      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                    </svg>
                  </span>
                )}
              </label>
              <input
                id="editAdArea"
                name="area"
                type="text"
                inputMode="numeric"
                placeholder="kvm"
                value={formData?.area}
                onChange={onChangeHandler}
                onBlur={validateField}
              />
            </div>
            <div className="edit-ad-input-col edit-ad-input-col-md">
              <label htmlFor="editAdMaxPeople">
                Lämpligt för antal personer
              </label>
              <input
                type="text"
                inputMode="numeric"
                id="editAdMaxPeople"
                name="capacity"
                placeholder="st"
                value={formData?.capacity}
                onChange={onChangeHandler}
              />
            </div>
            <div className="edit-ad-input-col edit-ad-input-col-md">
              <label htmlFor="editAdWorkspaces">
                Antal sittplatser
              </label>
              <input
                type="text"
                inputMode="numeric"
                id="editAdWorkspaces"
                name="workspaces"
                placeholder="st"
                value={formData?.workspaces}
                onChange={onChangeHandler}
              />
            </div>
            <div className="edit-ad-input-col edit-ad-input-col-md">
              <div className="edit-ad-vat-tax-wrapper">

                <div className="radio-group">
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="true"
                      className="shit-radio-button"
                      checked={formData?.vat_tax_required === true}
                      onChange={() =>
                        setFormData((prev) => ({
                          ...prev,
                          vat_tax_required: true,
                        }))
                      }
                    />
                    <span className="custom-radio-button" />
                    &nbsp;
                    Momsad
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      value="false"
                      className="shit-radio-button"
                      checked={formData?.vat_tax_required === false}
                      onChange={() =>
                        setFormData((prev) => ({
                          ...prev,
                          vat_tax_required: false,
                        }))
                      }
                    />
                    <span className="custom-radio-button" />
                    &nbsp;
                    Icke momsad
                  </label>
                </div>



              </div>
            </div>
          </div>
          <FacilitiesForm formData={formData} setFormData={setFormData} />
          <div className="edit-ad-input-row">
            <div className="edit-ad-input-col edit-ad-input-col-xxl">
              <h5 htmlFor="editAdDescription">
                Beskrivning av lokalen*
                {invalidFields.includes("description") && (
                  <span className="edit-ad-error-msg">
                    Ange beskrivning <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="2rem"
                      {...props}
                    >
                      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                    </svg>
                  </span>
                )}
              </h5>
              <p>
                Lyft här information som inte inkluderas här ovan, tex hur man tar sig till lokalen, vad som gör den unik och vilka aktiviteter som inte kan göras här.
              </p>
              <p>
                OBS!: Det är inte tillåtet att uppge kontaktuppgifter eller
                andra ledande uppgifter i beskrivningstexten som skulle göra
                att ni kringgår Vakansas tjänster och våra användarvillkor
              </p>
              <textarea
                id="editAdDescription"
                name="description"
                className="form-control"
                placeholder="Skriv här..."
                value={formData?.description}
                onChange={onChangeHandler}
                onBlur={validateField}
              />
            </div>
          </div>
          <div>
            <h5>
              Lägg till bilder
            </h5>
            <p>
              Tänk på att ladda upp minst 4 bilder (max 12). <b>Det är också
                rekommenderat att du laddar upp en planritning av lokalen.</b>
              (accepterade format är jpg, png, webp, gif)
            </p>
            {ImageTooBig !== null && <h3 style={{ color: 'red', fontWeight: '500' }}>{ImageTooBig}</h3>}
            <ImageContainer>
              {/* Images fetched from Cloudinary */}
              {formData?.images &&
                formData?.images.map((image, i) => (
                  <div key={i} className="edit-ad-image">
                    <Image
                      key={i}
                      cloudName="vakansa"
                      publicId={`listings/${formData?._id}/${image}`}
                      dpr="auto"
                      className="edit-ad-fetched-image"
                    >
                      <Transformation crop="fill" gravity="center" />
                    </Image>
                    <button
                      type="button"
                      onClick={() => removeOldImageHandler(image)}
                      className="edit-ad-image-delete-button"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="2rem"
                        {...props}
                      >
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </button>
                  </div>
                ))}
              {/* Local images added by the user */}
              {newImages.map((image, i) => (
                <div key={i} className="edit-ad-image">
                  <img
                    src={image.url}
                    alt=""
                    className="edit-ad-added-image"
                  />
                  <button
                    type="button"
                    onClick={() => removeNewImageHandler(image.id)}
                    className="edit-ad-image-delete-button"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="2rem"
                      {...props}
                    >
                      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                    </svg>
                  </button>
                </div>
              ))}
              {formData?.images.length + newImages.length < 12 && (
                <>
                  <label
                    className="edit-ad-custom-file-input"
                    htmlFor="edit-ad-image-input"
                  >
                    <img src="/images/icons/plus-circle.svg" alt="" />
                    <span>Välj bild</span>
                  </label>
                  <input
                    type="file"
                    name="images"
                    id="edit-ad-image-input"
                    multiple
                    accept="image/*"
                    onChange={onImagesChange}
                  />
                </>
              )}
            </ImageContainer>
            <br />
            <br />
            <br />
            <p>
              <b>Max storlek för bilder är 10MB</b>
            </p>
          </div>
          <div className="my-5 me-4">
            <h5 className="py-4">
              Ange på ett ungefär vilka dagar och tider lokalen ska hyras ut
              {invalidFields.includes("timeslots") && (
                <span className="edit-ad-error-msg">
                  Välj specifika tider eller alla tider (helt vakant){" "}
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="2rem"
                    {...props}
                  >
                    <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                  </svg>
                </span>
              )}
            </h5>
            <div className="full-vacancy-checkbox-container">
              <label className="full-vacancy-checkbox-wrapper">
                <input
                  id="editAdFullVacancy"
                  className="full-vacancy-checkbox-input"
                  type="checkbox"
                  checked={formData?.fully_vacant}
                  onChange={() =>
                    setFormData((prev) => ({
                      ...prev,
                      fully_vacant: !prev.fully_vacant,
                    }))
                  }
                />
                <span className="full-vacancy-checkbox-label">Lokalen är helt vakant</span>
              </label>
            </div>
            {!formData?.fully_vacant && (
              <Timeslots formData={formData} setFormData={setFormData} />
            )}
          </div>
          <div>
            <h5>Ange pris*</h5>
            <p>
              (fyll i minst 1 fält)
              {invalidFields.includes("rent") && (
                <span className="edit-ad-error-msg">
                  Ange åtminstone 1 pris{" "}
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="2rem"
                    {...props}
                  >
                    <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                  </svg>
                </span>
              )}
            </p>
            <div className="edit-ad-input-price-row">
              <div className="edit-ad-input-col edit-ad-input-col-md">
                <label htmlFor="editAdMinPrice">
                  Timpris (enstaka timmar)
                </label>
                <input
                  type="text"
                  inputMode="numeric"
                  name="hourly_rent"
                  id="editAdMinPrice"
                  placeholder="kr/timme"
                  value={formData?.hourly_rent}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="edit-ad-input-col edit-ad-input-col-md">
                <label htmlFor="editAdMaxPrice">
                  Dagspris (8 timmar i rad)
                </label>
                <input
                  type="text"
                  inputMode="numeric"
                  id="editAdMaxPrice"
                  name="daily_rent"
                  placeholder="kr/dag"
                  value={formData?.daily_rent}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="edit-ad-input-col edit-ad-input-col-md">
                <label htmlFor="editAdMaxPrice">
                  Månadspris (30 dagar i rad)
                </label>
                <input
                  type="text"
                  inputMode="numeric"
                  id="editAdMaxPrice"
                  name="monthly_rent"
                  placeholder="kr/månad"
                  value={formData?.monthly_rent}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="edit-ad-input-col edit-ad-input-col-md">
                <label htmlFor="editAdMaxPrice">
                  Pris per arbetsplats (per månad)
                </label>
                <input
                  type="text"
                  inputMode="numeric"
                  id="editAdMaxPrice"
                  name="workspace_rent"
                  placeholder="kr/arbetsplats"
                  value={formData?.workspace_rent}
                  onChange={onChangeHandler}
                />
              </div>
            </div>
          </div>
            {disableSubmit && (
              <p className="edit-ad-form-buttons-disabled-info">
                *Alla obligatoriska fält måste vara ifyllda för att kunna gå
                vidare
              </p>
            )}
          <div className="edit-ad-button-row">
            <button
              type="button"
              onClick={adPreviewHandler}
              disabled={disableSubmit}
            >
              Förhandsvy
            </button>
            {formData?.active ? (
              <button
                type="button"
                onClick={() => saveAdHandler("inactivate")}
              >
                Inaktivera annons
              </button>
            ) : (
              <button
                type="button"
                onClick={() => saveAdHandler("activate")}
              >
                Aktivera annons
              </button>
            )}
            <button type="button" onClick={saveAdHandler}>
              Spara ändringar
            </button>
          </div>
        </form>
        <ReactTooltip className="tooltip" />
      </EditAdContainer>
      {/* Spinner while ad is being created/updated */}
      {loading && <Loading text="Sparar ändringar..." />}
      {showErrorModal && (
        <ConfirmationModal
          heading="Någonting gick fel..."
          text="Ett problem uppstod med när dina ändringar sparades. Vänligen prova igen senare."
          setShowModal={setShowErrorModal}
          error
        />
      )}
      {showSaveChangesModal && (
        <ConfirmationModal
          heading="Dina ändringar har sparats"
          text='Gå till "mina annonser" under Mina annonser för att fortsätta redigera annonsen.'
          linkTo="/mina-sidor/annonser"
          setShowModal={setShowSaveChangesModal}
          note={errorMessage ? errorMessage : ""}
        />
      )}
      {showWarningModal && (
        <WarningModal
          heading="Lämna utan att spara"
          text="Är du säker att du vill lämna sidan? Dina ändringar kommer ej att sparas."
          confirmBtnText="Lämna"
          onCancel={cancelNavigation}
          onConfirm={() => {
            setAdPreviewInfo({ info: {}, images: [], hasChanged: false });
            confirmNavigation();
          }}
        />
      )}
    </>
  )
}

const EditAdContainer = styled.div`
max-width: 100rem;

.edit-ad-form-buttons-disabled-info {
  color: red;
  white-space: normal;
  margin-top: 3rem;
}

.form-control {
  width: 95%;
}

.radio-group {
  margin-left: -12px;
}

.shit-radio-button {
  visibility: hidden;
  cursor: pointer;
}

.custom-radio-button {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #FFF;
  display: inline-block;
  border: 2px solid var(--green);
}

.edit-ad-input-fastighet {
  width: 43rem !important;
}

.shit-radio-button:checked + span {
background-color: var(--green);
}

  .edit-ad-input-row {
    display: flex;

    .edit-ad-input-col {
      margin-right: 2rem;
      margin-bottom: 2.4rem;

      &-sm {
        flex-basis: 15%;
      }

      &-md {
        flex-basis: 20%;
      }

      &-lg {
        flex-basis: 45%;
      }

      &-xl {
        flex-basis: 55%;
      }

      &-xxl {
        flex-basis: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  h2 {
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 4rem;
  }

  h5 {
    font-size: 2.3rem;
    font-weight: 700;
    margin-top: 8rem;

    span {
      font-size: 2rem;
      font-weight: 400;
    }
  }

  p {
    font-size: 2rem;
    line-height: 24px;
    max-width: 100%;
    margin-bottom: 2rem;
  }

  label {
    display: block;
    font-size: 2rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    white-space: nowrap;
  }

  select,
  input[type="text"] {
    width: 100%;
    height: 4rem;
    font-size: 1.8rem;
    border: 1px solid #c6cbcf;
    border-radius: 100px;
    background: var(--white);
    padding: 0 2.5rem;
  }

  .edit-ad-vat-tax-wrapper {
    height: 100%;
    display: flex;
    align-items: center;

    svg {
      height: 1.75rem;
      width: 1.75rem;
      margin-left: 0.5rem;
    }
  }

.full-vacancy-checkbox-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 3rem auto;
  align-items: center;
}

.full-vacancy-checkbox-input {
  accent-color: var(--green);
  width: 2rem;
  height: 2rem;
}

.full-vacancy-checkbox-label {
  font-size: 2rem;
}

  .edit-ad-select-wrapper {
    position: relative;

    select {
      -webkit-appearance: none;
      appearance: none;
    }

    img {
      position: absolute;
      right: 1.6rem;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  .link {
    color: var(--green);
  }

  textarea {
    width: 100%;
    min-height: 20rem;
    padding: 1rem;
    border-radius: 20px;
    overflow-y: hidden;
    font-size: 2rem;
  }

   .edit-ad-button-row {
    display: flex;
    justify-content: end;
    margin-top: 14rem;

    & > button {
      padding: 1.15rem 4rem;
      margin-left: 2rem;
      font-size: 2.2rem;
      line-height: 25px;
      text-decoration: none;
      white-space: nowrap;
      border-radius: 100px;
      background-color: var(--white);
      border: 2px solid var(--green);
      box-shadow: var(--shadow-light);
    }

    & > button:last-child {
      background-color: var(--green);
      color: var(--white);
      box-shadow: none;
      border: none;
    }

    button:disabled {
      cursor: not-allowed;
    }
  }


  .edit-ad-input-price-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 2rem;
  }

  .edit-ad-hevy-font {
    font-weight: 200;
    font-size: var(--font-size-xx-small);
  font-family: var(--Lato);
  }

  .tooltip {
    padding: 0.8rem 1.6rem;
    font-size: 1.6rem;
    font-weight: 700;
    z-index: var(--z-index-tooltip);
    color: var(--green);
    background-color: var(--white);
    border-radius: 4px;
  }

  .edit-ad-inline-msg,
  .edit-ad-error-msg {
    font-size: 1.8rem;
      
    margin-left: 1rem;
  }

  .edit-ad-form-buttons-disabled-info {
  color: red;
  }

  .edit-ad-error-msg {
    color: var(--red);
  }

  @media only screen and (max-width: 1350px) {
      width: 98%;
    }

    @media only screen and (max-width: 1300px) {
      width: 95%;
    }

    @media only screen and (max-width: 1250px) {
      width: 92%;
    }

    @media only screen and (max-width: 1200px) {
      width: 98%;
    }
  

  @media only screen and (max-width: 1150px) {

      .edit-ad-input-price-row {
    grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr;
  }

    .css-heg063-MuiTabs-flexContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }
    .css-k008qs {
      display: block;
    }
    .css-1anid1y {
      white-space: normal;
    }
    .css-5xfbct-MuiTabs-root {
      border-bottom: none;
    }
    .css-ayo58a-MuiButtonBase-root-MuiTab-root.Mui-selected {
      font-weight: bolder;
    }
    .css-5xfbct-MuiTabs-root .MuiTabs-indicator {
      display: none;
    }
    .edit-ad-inline-msg {
      margin: 0;
    }
    form {
      width: 100%;
    }
    .edit-ad-input-row {
      display: flex;
      flex-direction: column;

      label {
        white-space: normal;
      }
    }
    .edit-ad-input-fastighet {
  width: 100% !important;
}
    .edit-ad-form-buttons,
    .edit-ad-form-buttons-row {
      display: inline-block;

      button {
        width: 100%;
        margin: 10px auto;
      }
    }
    .edit-ad-form-buttons-disabled-info {
      white-space: normal;
    }
    .edit-ad-input-row .edit-ad-input-col {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 980px) {

  .edit-ad-button-row {
      margin-top: 6rem;
      flex-direction:column;
      >* {
        margin: 10px 0;
      }
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  #edit-ad-image-input {
    display: none;
  }

  .edit-ad-custom-file-input {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    box-shadow: var(--shadow-light);
    width: 16.5rem;
    height: 18rem;
    margin: 0;
    border-radius: 8px;
    color: var(--text-light);
    cursor: pointer;

    img {
      width: 12rem;
      height: auto;
    }

    span {
      display: block;
      font-size: 1.3rem;
      font-weight: 700;
      transform: translateY(-15%);
    }
  }

  .edit-ad-image {
    display: inline-block;
    position: relative;
    margin-right: 2rem;
    margin-bottom: 2rem;

    & > img {
      height: 18rem;
      width: auto;
      border-radius: 8px;
    }

    .edit-ad-image-delete-button {
      position: absolute;
      top: 0.6rem;
      right: 0.6rem;

      width: 3.75rem;
      height: 3.75rem;
      font-size: 1.6rem;
      line-height: 1.5;
      color: var(--text-dark);
      text-decoration: none;
      background-color: var(--white);
      border: 1px solid transparent;
      border-radius: 50%;
      cursor: pointer;
    }

    .edit-ad-image-delete-button-new {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;

      color: var(--white);
      text-decoration: none;
      padding: 0.6rem 1.6rem;
      border-radius: 50%;
    }
  }
    @media only screen and (max-width:980px) {
    .edit-ad-custom-file-input {
      width:100%;
    }
    .edit-ad-image img {
    width:100%;
    height:auto;  
    }
`;

export default EditAd;
