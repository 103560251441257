import React, { useEffect } from "react";
import roomTypes from "../../../../../assets/roomTypes.json";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import SquareButton from "../../../../../components/Buttons/SquareButton/SquareButton";
import TextButton from "../../../../../components/Buttons/TextButton/TextButton";
import "./roomTypesModal.css";

export default function RoomTypesModal({ selectedRoomTypes, setSelectedRoomTypes, onRequestClose, props }) {
  const handleTypeChange = (type) => {
    if (selectedRoomTypes.includes(type)) {
      setSelectedRoomTypes(selectedRoomTypes.filter(item => item !== type));
    } else {
      setSelectedRoomTypes([...selectedRoomTypes, type]);
    }
  };

  const handleClearButtonClick = () => {
    setSelectedRoomTypes([]);
    sessionStorage.removeItem('selectedRoomTypes');
  };

  const handleSaveButtonClick = () => {
    sessionStorage.setItem('selectedRoomTypes', JSON.stringify(selectedRoomTypes));
    onRequestClose();
  };

  useEffect(() => {
    const savedSelectedRoomTypes = JSON.parse(sessionStorage.getItem('selectedRoomTypes'));
    if (savedSelectedRoomTypes) {
      setSelectedRoomTypes(savedSelectedRoomTypes);
    }
  }, [setSelectedRoomTypes]);

  useEffect(() => {
    // Disable scrolling when modal is open
    document.body.classList.add("modal-open");

    // Remove class when modal is closed
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <div className="room-types-modal-container">
        <div className="room-types-modal-checkboxes">
          {roomTypes.roomTypes.map((roomType, index) => (
            <Checkbox
              key={index}
              label={roomType}
              isChecked={selectedRoomTypes.includes(roomType)}
              onChange={() => handleTypeChange(roomType)}
            />
          ))}
        </div>
        <div id="rooms-types-modal-buttons">
          <TextButton label='Rensa' onClick={handleClearButtonClick} />
          <SquareButton size='sm' label='Spara' onClick={handleSaveButtonClick} />
        </div>

        <svg
          viewBox="0 0 1024 1024"
          fill="currentColor"
          height="1em"
          width="1em"
          {...props}
          onClick={onRequestClose}
          id="rooms-types-modal-close-btn"
        >
          <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
          <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
        </svg>
      </div>
      <div className="room-types-modal-backdrop" onClick={onRequestClose} />
    </>
  );
}