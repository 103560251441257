import React from "react";
import "./description.css";

function Description({ ad }) {
  return (
    <div className="description">
      <p>Beskrivning av lokalen</p>
      <p>{ad.description}</p>
    </div>
  );
}

export default Description;
