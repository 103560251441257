import React, { useEffect, useState } from 'react';
import './momsDropdown.css';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import TextButton from '../../../../components/Buttons/TextButton/TextButton';

export default function MomsDropdown({ selectedMoms, setSelectedMoms, closeMomsDropdown }) {
  const [, setIsSaveButtonPressed] = useState(false);

  const handleCheckboxChange = (value) => {
    if (selectedMoms.includes(value)) {
      setSelectedMoms(selectedMoms.filter(item => item !== value));
    } else {
      setSelectedMoms([...selectedMoms, value]);
    }
  };

  const handleClearButtonClick = () => {
    setSelectedMoms([]);
    sessionStorage.removeItem('selectedMoms');
    setIsSaveButtonPressed(false);
  };

  useEffect(() => {
    const savedSelectedMoms = JSON.parse(sessionStorage.getItem('selectedMoms'));
    if (savedSelectedMoms) {
      setSelectedMoms(savedSelectedMoms);
      if (savedSelectedMoms.length > 0) {
        setIsSaveButtonPressed(true);
      }
    }
  }, [setSelectedMoms]);

  useEffect(() => {
    sessionStorage.setItem('selectedMoms', JSON.stringify(selectedMoms));
  }, [selectedMoms]);

  return (
    <div className={`moms-dropdown`}>
      <p>Momsad lokal?</p>
      <div className='moms-content-wrapper'>
        <div className='moms-checkbox-wrapper'>
          <Checkbox label='Momsad' isChecked={selectedMoms.includes(true)} onChange={() => handleCheckboxChange(true)} />
          <Checkbox label='Ej momsad' isChecked={selectedMoms.includes(false)} onChange={() => handleCheckboxChange(false)} />
        </div>
      </div>
      <hr />
      <div className='moms-button-wrapper'>
        <TextButton label='Rensa' onClick={handleClearButtonClick} />
      </div>
    </div>
  );
}
