import React from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

function Sidebar(props) {
  const { page } = useParams();

  /**
   * Simple check as to whether tab is active or not
   * @param {(string|string[])} tab - What route(s) it counts as active
   */
  const isActive = (tab) => {
    // If tab is only a string, check it against page
    // else (it's an array) check if it includes page
    return typeof tab === "string" ? tab === page : tab.includes(page);
  };

  return (
    <SidebarComponent>
      <nav aria-label="my pages nav">
        <Link
          aria-label="link-to-my-ads"
          to="/mina-sidor/annonser"
          className={`dashboard-sidebar-tab ${
            isActive(["annonser", "skapa-annons", "redigera-annons"])
              ? "dashboard-sidebar-tab-active"
              : ""
          }`}
        >
          <div>
            <svg
              viewBox="0 0 1024 1024"
              fill="currentColor"
              height="1.3em"
              width="1.3em"
              {...props}
            >
              <path d="M946.5 505L534.6 93.4a31.93 31.93 0 00-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
            </svg>
          </div>
          <p>Mina annonser</p>
        </Link>
        <Link
          aria-label="link-to-support"
          to="/mina-sidor/support"
          className={`dashboard-sidebar-tab ${
            isActive("support") ? "dashboard-sidebar-tab-active" : ""
          }`}
        >
          <div>
            <svg
              viewBox="0 0 640 512"
              fill="currentColor"
              height="1.3em"
              width="1.3em"
              {...props}
            >
              <path d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0 0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4-3.5 9.4-8.7 17.7-14.2 24.7-4.8 6.2-9.7 11-13.3 14.3-1.8 1.6-3.3 2.9-4.3 3.7-.5.4-.9.7-1.1.8l-.2.2C1 327.2-1.4 334.4.8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5 9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zm240-176c0 112.3-99.1 196.9-216.5 207 24.3 74.4 104.9 129 200.5 129 38.2 0 73.9-8.7 104.7-23.9 7.5 4 16 7.9 25.2 11.4 18.3 6.9 40.3 12.5 62.1 12.5 6.9 0 13.1-4.5 15.2-11.1 2.1-6.6-.2-13.8-5.8-17.9l-.2-.2c-.2-.2-.6-.4-1.1-.8-1-.8-2.5-2-4.3-3.7-3.6-3.3-8.5-8.1-13.3-14.3-5.5-7-10.7-15.4-14.2-24.7 24.9-29 39.6-64.7 39.6-103.4 0-92.8-84.9-168.9-192.6-175.5.4 5.1.6 10.3.6 15.5z" />
            </svg>
          </div>
          <p>Support</p>
        </Link>
        <Link
          to="/mina-sidor/installningar"
          aria-label="link-to-account-settings"
          className={`dashboard-sidebar-tab ${
            isActive("installningar") ? "dashboard-sidebar-tab-active" : ""
          }`}
        >
          <div>
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="1.4em"
              width="1.4em"
              {...props}
            >
              <path d="M12 15.5A3.5 3.5 0 018.5 12 3.5 3.5 0 0112 8.5a3.5 3.5 0 013.5 3.5 3.5 3.5 0 01-3.5 3.5m7.43-2.53c.04-.32.07-.64.07-.97 0-.33-.03-.66-.07-1l2.11-1.63c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.31-.61-.22l-2.49 1c-.52-.39-1.06-.73-1.69-.98l-.37-2.65A.506.506 0 0014 2h-4c-.25 0-.46.18-.5.42l-.37 2.65c-.63.25-1.17.59-1.69.98l-2.49-1c-.22-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64L4.57 11c-.04.34-.07.67-.07 1 0 .33.03.65.07.97l-2.11 1.66c-.19.15-.25.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1.01c.52.4 1.06.74 1.69.99l.37 2.65c.04.24.25.42.5.42h4c.25 0 .46-.18.5-.42l.37-2.65c.63-.26 1.17-.59 1.69-.99l2.49 1.01c.22.08.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.66z" />
            </svg>
          </div>
          <p>Inställningar</p>
        </Link>
      </nav>
    </SidebarComponent>
  );
}

const SidebarComponent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--gray-light);
  padding-bottom: var(--spacing-lg);

  nav {
    position: fixed;
    top: 25rem;

    display: flex;
    flex-direction: column;

    @supports (position: sticky) {
      position: sticky;
    }
  }

  .dashboard-sidebar-tab {
    display: inline-flex;
    align-items: center;
    font-size: var(--font-size-x-small);

    color: var(--text-black);
    text-decoration: none;
    white-space: nowrap;
    padding: 0.2rem 1rem;
    margin-bottom: 2rem;

    transition: color 0.15s ease-in;

    & > div {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 4.6rem;
      height: 4.6rem;
      border-radius: 100px;
      background-color: transparent;
      font-size: 2.4rem;
    }

    & > p {
      display: inline-block;
      margin: auto 0;
      padding-left: 2.2rem;
    }

    &:hover {
      color: var(--green);
    }

    &-active {
      div {
        background-color: var(--green);
      }

      svg {
        color: var(--white);
      }
      & > p {
        color: var(--green);
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .dashboard-sidebar-tab p {
      display: none;
    }
  }

  @media only screen and (max-width: 680px) {
    margin-bottom: 0rem;
    padding-bottom: 0rem !important;
    background-color: var(--white);
    nav {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: var(--white);
      margin-top: 1rem;
    }
    .dashboard-sidebar-tab {
      margin: 0;
    }
  }
`;

export default Sidebar;
