import React, { useState } from "react";
import { ForgotPasswordPage } from "./styles";
import EmailForm from "./sections/EmailForm/EmailForm";
import NewPasswordForm from "./sections/NewPasswordForm/NewPasswordForm";
import SEOTags from "../../utils/SEOTags";

function ForgotPassword({ emailStep }) {
  const [serverError, setServerError] = useState("");
  return (
    <ForgotPasswordPage>
      <SEOTags
        title={"Glömt lösenord - Vakansa"}
        description={"Hur kan vi hjälpa dig?"}
        canonicalUrl={"/glomt-losenord/e-post"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      {emailStep ? (
        <EmailForm serverError={serverError} setServerError={setServerError} />
      ) : (
        <NewPasswordForm setServerError={setServerError} />
      )}
    </ForgotPasswordPage>
  );
}

export default ForgotPassword;
