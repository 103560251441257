import React, { useEffect, useState, useContext } from "react";
import FacilitiesForm from "./FacilitiesForm";
import Timeslots from "./Timeslots";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import importedMunicipalities from "../../../assets/municipalities.json";
import axios from "axios";
import AdPreviewContext from "../../../context/AdPreviewContext";
import { v4 as uuidv4 } from "uuid";
import ConfirmationModal from "../../../components/ConfirmationModal";
import importedRoomTypes from "../../../assets/roomTypes.json";
import { requiredFieldsCheck, authenticateForm } from "../utilities/formAuth";
import Loading from "./Loading";
import { backend } from "../../../backend";
import { logAxiosError } from "../../../logging/error";
import WarningModal from "../../../components/WarningModal";
import { useCallbackPrompt } from "../../../context/useCallbackPrompt";
import { useAuth } from "../../../context/AuthContext";
import { Tooltip as ReactTooltip } from "react-tooltip";

const { municipalities } = importedMunicipalities;
const { roomTypes } = importedRoomTypes;
const defaultFormData = {
  name: "",
  owner_name: "",
  address: "",
  city: "",
  municipality: "",
  zip_code: "",
  type_of_room: "",
  area: "",
  capacity: "",
  workspaces: "",
  facilities: [],
  description: "",
  images: [],
  timeslots: {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  },
  fully_vacant: false,
  hourly_rent: "",
  daily_rent: "",
  monthly_rent: "",
  workspace_rent: "",
  vat_tax_required: false,
};

function CreateAd(props) {
  const [formData, setFormData] = useState(defaultFormData);
  const [newImages, setNewImages] = useState([]);
  const [newAdId, setNewAdId] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [viewPreview, setViewPreview] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const [showSaveAdModal, setShowSaveAdModal] = useState(false);
  const [showCreateAdModal, setShowCreateAdModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const { adPreviewInfo, setAdPreviewInfo } = useContext(AdPreviewContext);
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ImageTooBig, setImageTooBig] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const {
    showPrompt: showWarningModal,
    confirmNavigation,
    cancelNavigation,
  } = useCallbackPrompt(showPrompt);

  useEffect(() => {
    const { info, images, hasChanged } = adPreviewInfo;
    if (hasChanged) {
      setShowPrompt(true);
    }
    if (location.state?.from === "preview") {
      setFormData(info);
      setNewImages(images);
      setAdPreviewInfo({ info: {}, images: [], hasChanged: false });
    }
    setShowPage(true);

    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    setDisableSubmit(!requiredFieldsCheck(formData, newImages));
  }, [formData, newImages]);

  const createAdHandler = () => {
    if (authenticateForm(formData, newImages, setInvalidFields)) {
      setLoading(true);
      createAdRequest();
    }
  };

  const createAdRequest = async () => {
    try {
      const { data } = await axios.post(backend("/api/listings/create"), {
        token: localStorage.getItem("token"),
        data: {
          ...formData,
          images: newImages,
        },
      });

      if (data.status) {
        setNewAdId(data.ad._id);
        uploadImages(data.ad._id, setShowCreateAdModal);

      }
    } catch (error) {
      logAxiosError(error);
      setLoading(false);
      setShowErrorModal(true);
      if (error.response?.status === 401) {
        auth.logout();
      }
    }
  };

  const saveAdHandler = () => {
    if (authenticateForm(formData, newImages, setInvalidFields)) {
      setLoading(true);
      saveAdRequest();
    }
  };

  const saveAdRequest = async () => {
    try {
      const { data } = await axios.post(backend("/api/listings/create"), {
        token: localStorage.getItem("token"),
        data: {
          ...formData,
          images: newImages,
          active: false,
        },
      });

      if (data.status) {
        uploadImages(data.ad._id, setShowSaveAdModal);

      }
    } catch (error) {
      logAxiosError(error);
      setLoading(false);
      setShowErrorModal(true);
      if (error.response?.status === 401) {
        auth.logout();
      }
    }
  };

  function autoExpand(e) {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  }

  const uploadImages = async (ad_id, setShowModal) => {
    const imageData = new FormData();
    let errorOccured = false;

    for (let image of newImages) {
      imageData.append("file", image.file);
      imageData.append("upload_preset", "oi2aoqjs");
      imageData.append("public_id", `/${ad_id}/${image.id}`);

      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/vakansa/image/upload",
        imageData
      );
      if (response.status !== 200) {
        errorOccured = true;
      }
    }
    setLoading(false);
    setErrorMessage(
      errorOccured
        ? "Ett problem uppstod under bild uppladdningen. Det kan ha hänt att inte alla bilder blev uppladdade."
        : ""
    );
    if (!errorOccured) {
      setShowPrompt(false);
      setShowModal(true);
      setAdPreviewInfo({ info: {}, images: [], hasChanged: false });
    } else {
      setShowPrompt(true);
      setShowErrorModal(true);
    }
  };

  const formHasChanged = () => {
    setAdPreviewInfo((prev) => ({ ...prev, hasChanged: true }));
    setShowPrompt(true);
  };

  const onChangeHandler = (e) => {
    const isNumericInput = e.target.inputMode === "numeric";
    const isNumericValue = new RegExp(/^(\/?[0-9])*\/?$/).test(e.target.value);
    if (isNumericInput && !isNumericValue) return;
    formHasChanged();
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const onImagesChange = (e) => {
    const files = e.target.files;
    if (files.length < 1 || files.length > 12) return;
    if (newImages.length + files.length > 12) {
      setImageTooBig(
        "Du kan inte ladda upp fler än 12 bilder. Du har nått maximum"
      );
      return;
    }

    const newImageObjects = [...files]
      .filter((file) => file.size <= 10 * 1024 * 1024)
      .map((file) => {
        const existingImage = newImages.find(
          (image) => image.file.name === file.name
        );
        if (existingImage) {
          console.warn(`Image "${file.name}" already exists in the list`);
          setImageTooBig(`Image "${file.name}" already exists in the list`);
          return null; // return null if image already exists
        }
        return {
          url: URL.createObjectURL(file),
          id: uuidv4(),
          file,
        };
      })
      .filter((image) => image !== null);
    const rejectedImages = [...files].filter(
      (file) => file.size > 10 * 1024 * 1024
    );
    if (rejectedImages.length > 0) {
      setImageTooBig(
        `Bilden du försökte ladda upp var större än 10MB. Försök med en mindre bild`
      );
    } else {
      setImageTooBig(null);
    }
    formHasChanged();

    setNewImages((prevData) => [...prevData, ...newImageObjects]);
  };

  const removeImageHandler = (image) => {
    formHasChanged();
    setNewImages((prevData) =>
      prevData.filter((currentImage) => currentImage.id !== image)
    );
  };

  useEffect(() => {
    if (!viewPreview && !showPrompt) return;
    navigate("/mina-sidor/skapa-annons/forhandsvy", {
      state: { prevRoute: location.pathname },
    });
    // eslint-disable-next-line
  }, [viewPreview]);

  const adPreviewHandler = () => {
    setShowPrompt(false);
    setAdPreviewInfo((prev) => ({
      ...prev,
      info: formData,
      images: newImages,
    }));
    setViewPreview(true);
  };

  const validateField = (e) => {
    const { value, name } = e.target;

    const defaultDropdownValues = ["Välj kommun", "Typ av lokal"];
    const invalidDropdownValue = defaultDropdownValues.includes(value);

    if (!value || invalidDropdownValue) {
      setInvalidFields([...invalidFields, name]);
    } else {
      setInvalidFields(invalidFields.filter((field) => field !== name));
    }
  };

  return (
    showPage && (
      <>
        <CreateAdContainer>
          <h2>Skapa annons</h2>
          <span className="create-ad-inline-msg">
            (Fält markerade med * är obligatoriska och måste fyllas i)
          </span>
          <br />
          <br />
          <br />
          <form>
            <div className="create-ad-input-row">
              <div className="create-ad-input-col create-ad-input-col-lg">
                <label htmlFor="createAdOwner">
                  Fastighetsägare*
                  {invalidFields.includes("owner_name") && (
                    <span className="create-ad-error-msg">
                      Ange en giltig fastighetsägare{" "}
                      <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="2rem"
                        {...props}
                      >
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <input
                  data-testid="input-create-ad-owner-name"
                  className="create-ad-input-fastighet"
                  type="text"
                  id="createAdAddress"
                  name="owner_name"
                  placeholder="Fastighetsägare"
                  value={formData?.owner_name}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                />
              </div>
            </div>
            <div className="create-ad-input-row">
              <div className="create-ad-input-col create-ad-input-col-lg">
                <label htmlFor="createAdAddress">
                  Adress*
                  {invalidFields.includes("address") && (
                    <span className="create-ad-error-msg">
                      Ange en giltig adress{" "}
                      <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="2rem"
                        {...props}
                      >
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <input
                  data-testid="input-create-ad-address"
                  type="text"
                  id="createAdAddress"
                  name="address"
                  placeholder="Adress"
                  value={formData?.address}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                />
              </div>
              <div className="create-ad-input-col create-ad-input-col-md">
                <label htmlFor="createAdCity">
                  Stad*
                  {invalidFields.includes("city") && (
                    <span className="create-ad-error-msg">
                      Ange stad{" "}
                      <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="2rem"
                        {...props}
                      >
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <input
                  data-testid="input-create-ad-city"
                  type="text"
                  id="createAdCity"
                  name="city"
                  placeholder="Stad"
                  value={formData?.city}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                />
              </div>
              <div className="create-ad-input-col create-ad-input-col-md">
                <label htmlFor="createAdMunicipality">
                  Kommun*
                  {invalidFields.includes("municipality") && (
                    <span className="create-ad-error-msg">
                      Ange kommun{" "}
                      <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="2rem"
                        {...props}
                      >
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <div className="create-ad-select-wrapper">
                  <select
                    data-testid="input-create-ad-municipality"
                    id="createAdMunicipality"
                    name="municipality"
                    defaultValue="Välj kommun"
                    onChange={onChangeHandler}
                    onBlur={validateField}
                  >
                    <option hidden>Välj kommun</option>
                    <option>Stockholm </option>
                    <option>Göteborg </option>
                    <option>Malmö </option>
                    <option>Uppsala</option>
                    <optgroup label="Alla kommuner">
                      {municipalities.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </optgroup>
                  </select>
                  <img src="/images/icons/arrow-down.svg" alt="" />
                </div>
              </div>
              <div className="create-ad-input-col create-ad-input-col-sm">
                <label htmlFor="createAdZip">
                  Postkod*
                  {invalidFields.includes("zip_code") && (
                    <span className="create-ad-error-msg">
                      <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="2rem"
                        {...props}
                      >
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <input
                  data-testid="input-create-ad-zipcode"
                  type="text"
                  id="createAdZip"
                  name="zip_code"
                  placeholder="Postkod"
                  value={formData?.zip_code}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                />
              </div>
            </div>
            <div className="create-ad-input-row">
              <div className="create-ad-input-col create-ad-input-col-md">
                <label htmlFor="createAdType">
                  Lokal*
                  {invalidFields.includes("type_of_room") && (
                    <span className="create-ad-error-msg">
                      Ange lokaltyp{" "}
                      <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="2rem"
                        {...props}
                      >
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <div className="create-ad-select-wrapper create-ad-input-col create-ad-input-col-md">
                  <select
                    data-testid="input-create-ad-roomtype"
                    id="createAdType"
                    name="type_of_room"
                    defaultValue="Typ av lokal"
                    onChange={onChangeHandler}
                    onBlur={validateField}
                  >
                    <option hidden>Typ av lokal</option>
                    {roomTypes.map((roomType, i) => (
                      <option key={i}>{roomType}</option>
                    ))}
                  </select>
                  <img src="/images/icons/arrow-down.svg" alt="" />
                </div>
              </div>
              <div className="create-ad-input-col create-ad-input-col-sm">
                <label htmlFor="createAdArea">
                  Yta*
                  {invalidFields.includes("area") && (
                    <span className="create-ad-error-msg">
                      Ange yta{" "}
                      <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="2rem"
                        {...props}
                      >
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <input
                  data-testid="input-create-ad-area"
                  id="createAdArea"
                  name="area"
                  type="text"
                  inputMode="numeric"
                  placeholder="kvm"
                  value={formData?.area}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                />
              </div>
              <div className="create-ad-input-col create-ad-input-col-md">
                <label htmlFor="createAdMaxPeople">
                  Lämpligt för antal personer
                </label>
                <input
                  type="text"
                  inputMode="numeric"
                  id="createAdMaxPeople"
                  name="capacity"
                  placeholder="st"
                  value={formData?.capacity}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="create-ad-input-col create-ad-input-col-md">
                <label htmlFor="createAdWorkspaces">Antal sittplatser</label>
                <input
                  type="text"
                  inputMode="numeric"
                  id="createAdWorkspaces"
                  name="workspaces"
                  placeholder="st"
                  value={formData?.workspaces}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="create-ad-input-col create-ad-input-col-md">
                <div className="create-ad-vat-tax-wrapper">
                  <div className="radio-group">
                    <label className="radio-label">
                      <input
                        type="radio"
                        value="true"
                        className="shit-radio-button"
                        checked={formData?.vat_tax_required === true}
                        onChange={() =>
                          setFormData((prev) => ({
                            ...prev,
                            vat_tax_required: true,
                          }))
                        }
                      />
                      <span className="custom-radio-button" />
                      &nbsp; Momsad
                    </label>
                    <label className="radio-label">
                      <input
                        type="radio"
                        value="false"
                        className="shit-radio-button"
                        checked={formData?.vat_tax_required === false}
                        onChange={() =>
                          setFormData((prev) => ({
                            ...prev,
                            vat_tax_required: false,
                          }))
                        }
                      />
                      <span className="custom-radio-button" />
                      &nbsp; Icke momsad
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <FacilitiesForm formData={formData} setFormData={setFormData} />
            <div className="create-ad-input-row">
              <div className="create-ad-input-col create-ad-input-col-xxl">
                <h5 htmlFor="createAdDescription">
                  Beskrivning av lokalen*
                  {invalidFields.includes("description") && (
                    <span className="create-ad-error-msg">
                      Ange beskrivning{" "}
                      <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="2rem"
                        {...props}
                      >
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </h5>
                <p>
                  Lyft här information som inte inkluderas här ovan, tex hur man
                  tar sig till lokalen, vad som gör den unik och vilka
                  aktiviteter som inte kan göras här.
                </p>
                <p>
                  OBS!: Det är inte tillåtet att uppge kontaktuppgifter eller
                  andra ledande uppgifter i beskrivningstexten som skulle göra
                  att ni kringgår Vakansas tjänster och våra användarvillkor
                </p>
                <textarea
                  data-testid="input-create-ad-description"
                  id="createAdDescription"
                  name="description"
                  className="form-control"
                  placeholder="Skriv här..."
                  value={formData?.description}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                  onInput={autoExpand}
                />
              </div>
            </div>
            <div>
              <h5>Lägg till bilder</h5>
              <p>
                Tänk på att ladda upp minst 4 bilder (max 12).{" "}
                <b>
                  Det är också rekommenderat att du laddar upp en planritning av
                  lokalen.
                </b>
                <br />
                (accepterade format är jpg, png, webp, gif)
              </p>
              {ImageTooBig !== null && (
                <h3 style={{ color: "red", fontWeight: "500" }}>
                  {ImageTooBig}
                </h3>
              )}
              <ImageContainer>
                {newImages &&
                  newImages.map((image) => (
                    <div key={image.id} className="create-ad-image">
                      <img src={image.url} alt="" />
                      <button
                        type="button"
                        onClick={() => removeImageHandler(image.id)}
                        className="create-ad-image-delete-button"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          height="2.2em"
                          width="1.6em"
                          {...props}
                        >
                          <path d="M13.41 12l4.3-4.29a1 1 0 10-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 00-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 000 1.42 1 1 0 001.42 0l4.29-4.3 4.29 4.3a1 1 0 001.42 0 1 1 0 000-1.42z" />
                        </svg>
                      </button>
                    </div>
                  ))}
                <label
                  className="create-ad-custom-file-input"
                  htmlFor="create-ad-image-input"
                >
                  <img src="/images/icons/plus-circle.svg" alt="" />
                  <span>Välj bild</span>
                </label>
                <input
                  data-testid="input-create-ad-image"
                  type="file"
                  name="images"
                  id="create-ad-image-input"
                  multiple
                  accept="image/*"
                  onChange={onImagesChange}
                />
              </ImageContainer>
              <br />
              <br />
              <br />
              <p>
                <b>Max storlek för bilder är 10MB</b>
              </p>
            </div>
            <div className="my-5 me-4">
              <h5 className="py-4">
                Ange på ett ungefär vilka dagar och tider lokalen ska hyras ut*
                {invalidFields.includes("timeslots") && (
                  <span className="create-ad-error-msg">
                    Välj specifika tider eller alla tider (helt vakant){" "}
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="2rem"
                      {...props}
                    >
                      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                    </svg>
                  </span>
                )}
              </h5>
              <div className="full-vacancy-checkbox-container">
                <label className="full-vacancy-checkbox-wrapper">
                  <input
                    id="createAdFullVacancy"
                    className="full-vacancy-checkbox-input"
                    type="checkbox"
                    checked={formData?.fully_vacant}
                    onChange={() =>
                      setFormData((prev) => ({
                        ...prev,
                        fully_vacant: !prev.fully_vacant,
                      }))
                    }
                  />
                  <span className="full-vacancy-checkbox-label">
                    Lokalen är helt vakant
                  </span>
                </label>
              </div>
              {!formData?.fully_vacant && (
                <Timeslots formData={formData} setFormData={setFormData} />
              )}
            </div>
            <div>
              <h5>Ange pris*</h5>
              <p>
                (fyll i minst 1 fält)
                {invalidFields.includes("rent") && (
                  <span className="create-ad-error-msg">
                    Ange åtminstone 1 pris{" "}
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="2rem"
                      {...props}
                    >
                      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                    </svg>
                  </span>
                )}
              </p>
              <div className="create-ad-input-price-row">
                <div className="create-ad-input-col create-ad-input-col-md">
                  <label htmlFor="createAdMinPrice">
                    Timpris (enstaka timmar)
                  </label>
                  <input
                    data-testid="input-create-ad-timePerHour"
                    type="text"
                    inputMode="numeric"
                    name="hourly_rent"
                    id="createAdMinPrice"
                    placeholder="kr/timme"
                    value={formData?.hourly_rent}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="create-ad-input-col create-ad-input-col-md">
                  <label htmlFor="createAdMaxPrice">
                    Dagspris (8 timmar i rad)
                  </label>
                  <input
                    type="text"
                    inputMode="numeric"
                    id="createAdMaxPrice"
                    name="daily_rent"
                    placeholder="kr/dag"
                    value={formData?.daily_rent}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="create-ad-input-col create-ad-input-col-md">
                  <label htmlFor="createAdMaxPrice">
                    Månadspris (30 dagar i rad)
                  </label>
                  <input
                    type="text"
                    inputMode="numeric"
                    id="createAdMaxPrice"
                    name="monthly_rent"
                    placeholder="kr/månad"
                    value={formData?.monthly_rent}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="create-ad-input-col create-ad-input-col-md">
                  <label htmlFor="createAdMaxPrice">
                    Pris per arbetsplats (per månad)
                  </label>
                  <input
                    type="text"
                    inputMode="numeric"
                    id="createAdMaxPrice"
                    name="workspace_rent"
                    placeholder="kr/arbetsplats"
                    value={formData?.workspace_rent}
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="create-ad-form-buttons">
              {disableSubmit && (
                <p className="create-ad-form-buttons-disabled-info">
                  *Alla obligatoriska fält måste vara ifyllda för att kunna gå
                  vidare
                </p>
              )}
              <div className="create-ad-form-buttons-row">
                <button
                  type="button"
                  onClick={adPreviewHandler}
                  disabled={disableSubmit}
                >
                  Förhandsvy
                </button>
                <button
                  type="button"
                  onClick={saveAdHandler}
                  // disabled={disableSubmit}
                >
                  Spara som utkast
                </button>
                <button
                  data-testid="input-create-ad-create-btn"
                  type="button"
                  onClick={createAdHandler}
                  // disabled={disableSubmit}
                >
                  Skapa och publicera
                </button>
              </div>
            </div>
          </form>
          <ReactTooltip className="tooltip" />
        </CreateAdContainer>
        {/* Spinner while ad is being created/updated */}
        {loading && <Loading text="Skapar annons..." />}
        {showErrorModal && (
          <ConfirmationModal
            heading="Någonting gick fel..."
            text="Ett problem uppstod med skapandet av din annons. Vänligen prova igen senare."
            setShowModal={setShowErrorModal}
            error
          />
        )}
        {showSaveAdModal && (
          <ConfirmationModal
            heading="Din annons är sparad som inaktiv"
            text="Gå till inaktiva annoner under Mina annonser för att aktivera din annons eller för att fortsätta redigera den."
            linkTo="/mina-sidor/annonser"
            setShowModal={setShowSaveAdModal}
            note={errorMessage ? errorMessage : ""}
          />
        )}
        {showCreateAdModal && (
          <ConfirmationModal
            heading="Din annons är nu skapad!"
            text="Grattis! Din annons är nu aktiv och vi kontakter dig så fort någon är intresserad av att hyra din lokal. För att hanterar dina annonser så gå du till Mina annonser."
            linkTo="/mina-sidor/annonser"
            linkToAd={newAdId ? `/lediga-lokaler/${newAdId}` : ""}
            setShowModal={setShowCreateAdModal}
            note={errorMessage ? errorMessage : ""}
          />
        )}
        {showWarningModal && (
          <WarningModal
            heading="Lämna utan att spara"
            text="Är du säker att du vill lämna sidan? Din annons är ej sparad."
            confirmBtnText="Lämna"
            onCancel={cancelNavigation}
            onConfirm={() => {
              setAdPreviewInfo({ info: {}, images: [], hasChanged: false });
              confirmNavigation();
            }}
          />
        )}
      </>
    )
  );
}

const CreateAdContainer = styled.div`
  max-width: 100rem;

  .form-control {
    width: 95%;
  }

  .radio-group {
    margin-left: -12px;
  }

  .shit-radio-button {
    visibility: hidden;
    cursor: pointer;
  }

  .custom-radio-button {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #fff;
    display: inline-block;
    border: 2px solid var(--green);
  }

  .create-ad-input-fastighet {
    width: 43rem !important;
  }

  .shit-radio-button:checked + span {
    background-color: var(--green);
  }

  .create-ad-input-row {
    display: flex;

    .create-ad-input-col {
      margin-right: 2rem;
      margin-bottom: 2.4rem;

      &-sm {
        flex-basis: 15%;
      }

      &-md {
        flex-basis: 20%;
      }

      &-lg {
        flex-basis: 45%;
      }

      &-xl {
        flex-basis: 55%;
      }

      &-xxl {
        flex-basis: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  h2 {
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 4rem;
  }

  h5 {
    font-size: 2.3rem;
    font-weight: 700;
    margin-top: 8rem;

    span {
      font-size: 2rem;
      font-weight: 400;
    }
  }

  p {
    font-size: 2rem;
    line-height: 24px;
    max-width: 100%;
    margin-bottom: 2rem;
  }

  label {
    display: block;
    font-size: 2rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    white-space: nowrap;
  }

  select,
  input[type="text"] {
    width: 100%;
    height: 4rem;
    font-size: 1.8rem;
    border: 1px solid #c6cbcf;
    border-radius: 100px;
    background: var(--white);
    padding: 0 2.5rem;
  }

  .create-ad-vat-tax-wrapper {
    height: 100%;
    display: flex;
    align-items: center;

    svg {
      height: 1.75rem;
      width: 1.75rem;
      margin-left: 0.5rem;
    }
  }

  .full-vacancy-checkbox-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 3rem auto;
    align-items: center;
  }

  .full-vacancy-checkbox-input {
    accent-color: var(--green);
    width: 2rem;
    height: 2rem;
  }

  .full-vacancy-checkbox-label {
    font-size: 2rem;
  }

  .create-ad-select-wrapper {
    position: relative;

    select {
      -webkit-appearance: none;
      appearance: none;
    }

    img {
      position: absolute;
      right: 1.6rem;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  .link {
    color: var(--green);
  }

  textarea {
    width: 100%;
    min-height: 20rem;
    padding: 1rem;
    border-radius: 20px;
    overflow-y: hidden;
    font-size: 2rem;
  }

  .create-ad-form-buttons {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 3.7rem auto;
    margin-top: 14rem;

    &-disabled-info {
      grid-column: 2 / 3;
      font-size: 1.8rem;

      color: var(--text-light);
      white-space: nowrap;
      margin-left: 3rem;
      margin-bottom: 1.5rem;
    }

    &-row {
      grid-column: 2 / 3;
      grid-row: 2 / 3;

      & > button {
        padding: 1.15rem 4rem;
        margin-left: 2rem;
        font-size: 2.2rem;
        line-height: 25px;
        text-decoration: none;
        white-space: nowrap;
        border-radius: 100px;
        background-color: var(--white);
        border: 2px solid var(--green);
        box-shadow: var(--shadow-light);
        cursor: pointer;
      }

      & > button:last-child {
        background-color: var(--green);
        color: var(--white);
        box-shadow: none;
        border: none;
      }

      button:disabled {
        cursor: not-allowed;
      }
    }
  }

  .create-ad-input-price-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 2rem;
  }

  .create-ad-hevy-font {
    font-weight: 200;
    font-size: var(--font-size-xx-small);
    font-family: var(--Lato);
  }

  .tooltip {
    padding: 0.8rem 1.6rem;
    font-size: 1.6rem;
    font-weight: 700;
    z-index: var(--z-index-tooltip);
    color: var(--green);
    background-color: var(--white);
    border-radius: 4px;
  }

  .create-ad-inline-msg,
  .create-ad-error-msg {
    font-size: 1.8rem;

    margin-left: 1rem;
  }

  .create-ad-form-buttons-disabled-info {
    color: red;
  }

  .create-ad-error-msg {
    color: var(--red);
  }

  @media only screen and (max-width: 1350px) {
    width: 98%;
  }

  @media only screen and (max-width: 1300px) {
    width: 95%;
  }

  @media only screen and (max-width: 1250px) {
    width: 92%;
  }

  @media only screen and (max-width: 1200px) {
    width: 98%;
  }

  @media only screen and (max-width: 1150px) {
    .create-ad-input-price-row {
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr;
    }

    .css-heg063-MuiTabs-flexContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }
    .css-k008qs {
      display: block;
    }
    .css-1anid1y {
      white-space: normal;
    }
    .css-5xfbct-MuiTabs-root {
      border-bottom: none;
    }
    .css-ayo58a-MuiButtonBase-root-MuiTab-root.Mui-selected {
      font-weight: bolder;
    }
    .css-5xfbct-MuiTabs-root .MuiTabs-indicator {
      display: none;
    }
    .create-ad-inline-msg {
      margin: 0;
    }
    form {
      width: 100%;
    }
    .create-ad-input-row {
      display: flex;
      flex-direction: column;

      label {
        white-space: normal;
      }
    }
    .create-ad-input-fastighet {
      width: 100% !important;
    }
    .create-ad-form-buttons,
    .create-ad-form-buttons-row {
      display: inline-block;

      button {
        width: 100%;
        margin: 10px auto;
        cursor: pointer;
      }
    }
    .create-ad-form-buttons-disabled-info {
      white-space: normal;
    }
    .create-ad-input-row .create-ad-input-col {
      margin-right: 0;
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  #create-ad-image-input {
    display: none;
  }

  .create-ad-custom-file-input {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    box-shadow: var(--shadow-light);
    width: 16.5rem;
    height: 18rem;
    margin: 0;
    border-radius: 8px;
    color: var(--text-light);
    cursor: pointer;

    img {
      width: 12rem;
      height: auto;
    }

    span {
      display: block;
      font-size: 1.3rem;
      font-weight: 700;
      transform: translateY(-15%);
    }
  }

  .create-ad-image {
    display: inline-block;
    position: relative;
    margin-right: 2rem;
    margin-bottom: 2rem;

    & > img {
      height: 18rem;
      width: auto;
      border-radius: 8px;
    }

    .create-ad-image-delete-button {
      position: absolute;
      top: 0.6rem;
      right: 0.6rem;

      width: 3.75rem;
      height: 3.75rem;
      font-size: 1.6rem;
      line-height: 1.5;
      color: var(--text-dark);
      text-decoration: none;
      background-color: var(--white);
      border: 1px solid transparent;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 980px) {
    .create-ad-custom-file-input {
      width: 100%;
    }
    .create-ad-image img {
      width: 100%;
      height: auto;
    }
  }
`;

export default CreateAd;
