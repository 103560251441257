import React from "react";
import Slider from "@mui/material/Slider";
import { styled as muiStyled } from "@mui/material/styles";
import styled from "styled-components";

const StyledSlider = muiStyled(Slider)({
  color: "var(--green)",
  margin: "4rem 0 0",
  "& .MuiSlider-thumb": {
    height: "2.2rem",
    width: "2.2rem",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: "1.6rem",
    fontWeight: 700,
    fontFamily: "var(--PT-sans)",
    backgroundColor: "var(--green)",
    width: "6.2rem",
    height: "2.8rem",
    borderRadius: "4px",
  },
});

function TimeslotInput({ day, timeslots, setTimeslots, removeTimeslots, props }) {
  const valueText = (number) => {
    return `${String(number).padStart(2, "0")}:00`;
  };

  const setNewTimeHandler = (e, newTime, activeThumb, index) => {
    const timeslotsCopy = [...timeslots];
    timeslotsCopy[index] = newTime;
    setTimeslots(day, timeslotsCopy);
  };

  const addTimeRangeHandler = () => {
    if (timeslots.length < 4) {
      setTimeslots(day, [...timeslots, [6, 22]]);
    }
  };

  return (
    <>
      {timeslots.map((timeslot, index) => (
        <TimeslotInputComponent key={index}>
          <p className="timeslot-number" onClick={() => removeTimeslots(day, index)}>
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="2rem"
              width="2rem"
              {...props}
            >
              <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
            </svg>
            Tillgänglig tid {index + 1}
          </p>
          <div className="timeslot-input">
            <StyledSlider
              value={timeslot}
              valueLabelDisplay="on"
              valueLabelFormat={valueText}
              onChange={(e, val, thumb) =>
                // e, val, and thumb are args passed from the
                // slider component, check MUI docs for more info
                setNewTimeHandler(e, val, thumb, index)
              }
              disableSwap
              marks
              step={1}
              min={6}
              max={22}
              sx={{
                fontSize: "1.6rem !important",
              }}
            />
          </div>
        </TimeslotInputComponent>
      ))}
      <NewTimeslotButton className="timeslot-add-new" onClick={addTimeRangeHandler}>
        {timeslots.length ? "+ Lägg till yttligare en tid" : "+ Lägg till tid"}
      </NewTimeslotButton>
    </>
  );
}

const TimeslotInputComponent = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr;
  align-items: center;
  margin: 2rem 2rem 0;

  .timeslot-number {
    font-size: 1.8rem;
    margin-bottom: 0;
    cursor: pointer;
  }

  .timeslot-input {
    padding-bottom: 2.6rem;
  }
  @media only screen and (max-width:980px){
    display:block;
    .timeslot-number {
      text-align:center;
    }
  }
`
const NewTimeslotButton = styled.span`
  display: block;
  text-align: center;
  font-size: 1.6rem;
  padding: 0.4rem;
  cursor: pointer;
`
export default TimeslotInput;
