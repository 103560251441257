import React from "react";
import './checkbox.css'

export default function Checkbox({ label, isChecked, onChange }) {

  const handleCheckboxChange = () => {
    onChange(!isChecked);
  }

  return (
    <div className="checkbox-container">
      <label className="checkbox-wrapper">
        <input
          className="checkbox-input"
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span className="checkbox-label">{label}</span>
      </label>
    </div>
  )
}
