/* eslint-disable default-case */
import React from "react";
import "./teamCard.css";
import cameronImg from "../../../assets/team/cameron.png";
import markusImg from "../../../assets/team/markus.jpg";
import klasImg from "../../../assets/team/klas.png";

export default function TeamCard({
  memberImg,
  firstname,
  lastname,
  role,
  description,
}) {
  let teamImage;

  switch (memberImg) {
    case "cameron":
      teamImage = cameronImg;
      break;

    case "markus":
      teamImage = markusImg;
      break;

    case "klas":
      teamImage = klasImg;
      break;
  }

  return (
    <div className={`team-container`}>
      <div className="about-us-team-member">
        <img src={teamImage} alt={`Porträtt av ${firstname} ${lastname}`} />
        <h3>
          {firstname} {lastname}
        </h3>
        <h4>{role}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
}
