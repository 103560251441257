import React from "react";
import styled from "styled-components";
import { ReactComponent as EyeSlashIcon } from "../assets/icons/eye-slash.svg";

function ImageNotFoundSmall({ height, width, borderRadius, size = "default" }) {
  const iconSize =
    size === "large" ? "12rem" : size === "small" ? "6rem" : "8rem"; // 8rem is default
  const fontSize =
    size === "large" ? "2.8rem" : size === "small" ? "1.5rem" : "2rem"; // 2rem is default

  return (
    <ImageNotFoundSmallComponent
      height={height}
      width={width}
      borderRadius={borderRadius}
      $iconSize={iconSize}
      $fontSize={fontSize}
    >
      <EyeSlashIcon alt="Icon-of-a-unslashed-eye" />
      <span>Bild inte tillgänglig</span>
    </ImageNotFoundSmallComponent>
  );
}

const ImageNotFoundSmallComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(p) => (p.width ? p.width : "100%")};
  height: ${(p) => (p.height ? p.height : "75%")};
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : "0")};
  background-color: var(--blue-light);
  color: var(--text-light);
  cursor: pointer;

  svg {
    width: ${(p) => p.$iconSize};
    height: auto;
  }

  span {
    font-size: ${(p) => p.$fontSize};
  }
`;

export default ImageNotFoundSmall;
