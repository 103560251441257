import React, { useState } from "react";
import axios from "axios";
import { backend } from "../../../backend";
import { logAxiosError } from "../../../logging/error";
import { useNavigate } from "react-router-dom";
import Checkbox from "../../../components/Checkbox/Checkbox";
import "./bookingPanel.css";
import { createSEOUrl } from "../../../utils/createSEOUrl";

const defaultFormData = {
  first_name: "",
  last_name: "",
  email: "",
  organization: "",
  mobile: "",
  message: "",
};

function BookingPanel({ ad, setShowModal }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(defaultFormData);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [serverError, setServerError] = useState("");
  const [invalidFields, setInvalidFields] = useState({
    message: false,
    email: false,
    mobile: false,
    organization: false,
    terms: false,
    first_name: false,
    last_name: false,
  });

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setInvalidFields({});
    setServerError("");

    if (
      !formData.message ||
      !emailValidator(formData.email) ||
      !phoneValidator(formData.mobile) ||
      !orgValidator(formData.organization) ||
      !formData.first_name ||
      !formData.last_name ||
      !acceptedTerms
    ) {
      setInvalidFields({
        message: !formData.message,
        email: !emailValidator(formData.email),
        mobile: !phoneValidator(formData.mobile),
        organization: !orgValidator(formData.organization),
        first_name: !formData.first_name,
        last_name: !formData.last_name,
        terms: !acceptedTerms,
      });

      return;
    }

    try {
      const listingLink = `${
        window.location.origin
      }/lediga-lokaler/${createSEOUrl(ad.municipality)}/${createSEOUrl(
        ad.type_of_room
      )}/${createSEOUrl(ad.address)}/${ad._id}`;

      const { data } = await axios.post(
        backend("/api/listings/show-interest"),
        {
          ad,
          listingLink,
          ...formData,
        }
      );

      if (data.status) {
        navigate("/intresseanmalan");
      }
    } catch (error) {
      logAxiosError(error);
      setServerError(error.response?.data?.msg);
    }
  };

  const emailValidator = (email) =>
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const orgValidator = (organization) => {
  return /^[0-9]{6}-?[0-9]{4}$/.test(organization);
};

  const phoneValidator = (phone) =>
    /^(070|072|073|076|079)[0-9]{7}$/.test(phone);

  return (
    <div className="booking-panel">
      <h3 data-testid="booking-modal-header">Är du intresserad av lokalen?</h3>
      <h4>Skicka in en förfrågan!</h4>
      <form id="booking-modal-form" onSubmit={onSubmitHandler}>
        <div className="booking-panel-input-row">
          <input
            type="text"
            name="email"
            id="booking-email"
            data-testid="booking-email"
            onChange={onChangeHandler}
            placeholder="E-postadress"
            style={{ border: invalidFields.email ? "2px solid red" : "" }}
          />

          <input
            type="text"
            name="organization"
            id="booking-organisation"
            data-testid="booking-organisation"
            onChange={onChangeHandler}
            placeholder="Org.nr (000000-0000)"
            style={{
              border: invalidFields.organization ? "2px solid red" : "",
            }}
          />
          <div className="booking-panel-name">
            <input
              type="text"
              name="first_name"
              id="booking-firstname"
              data-testid="booking-firstname"
              onChange={onChangeHandler}
              placeholder="Förnamn"
              style={{
                border: invalidFields.first_name ? "2px solid red" : "",
              }}
            />

            <input
              type="text"
              name="last_name"
              id="booking-lastname"
              data-testid="booking-lastname"
              onChange={onChangeHandler}
              placeholder="Efternamn"
              style={{ border: invalidFields.last_name ? "2px solid red" : "" }}
            />
          </div>
          <input
            type="number"
            name="mobile"
            id="booking-phone"
            data-testid="booking-phone"
            onChange={onChangeHandler}
            placeholder="Telefonnummer"
            style={{ border: invalidFields.mobile ? "2px solid red" : "" }}
          />

          <div className="booking-modal-message">
            <textarea
              value={formData.message}
              onChange={onChangeHandler}
              name="message"
              id="booking-modal-add-message-input"
              data-testid="booking-msg-input"
              cols="30"
              rows="8"
              maxLength="2000"
              placeholder="Beskriv ditt behov så utförligt som möjligt."
              style={{ border: invalidFields.message ? "2px solid red" : "" }}
            />
          </div>
        </div>
        {invalidFields.terms && (
          <p className="booking-modal-error">Du måste acceptera villkoren</p>
        )}
        {serverError && <p className="booking-modal-error">{serverError}</p>}
        <div className="checkbox-speciffic">
          <Checkbox
            data-testid="booking-terms-checkbox"
            name="terms"
            checked={acceptedTerms}
            onChange={() => setAcceptedTerms((prevState) => !prevState)}
            label={
              <>
                Jag har läst och accepterar{" "}
                <a
                  href="/anvandarvillkor"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  användarvillkoren
                </a>
              </>
            }
          />
        </div>
        <div className="booking-modal-buttons">
          <button type="submit" data-testid="booking-send-btn">
            Skicka förfrågan
          </button>
        </div>
      </form>
    </div>
  );
}

export default BookingPanel;
