import React from "react";
import "./activeButton.css";

export default function ActiveButton({ size, label, onClick, isActive, href }) {
  const handleClick = () => {
    if (onClick) {
      onClick(label);
    }
  };

  return (
    <button
      className={`active-button ${isActive ? "active" : ""} ${size}`}
      onClick={handleClick}
      href={href}
    >
      {label}
    </button>
  );
}
