import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./info.css";

function Info({ ad, props }) {
  const tooltipText = {
    moms: "Nej: Lokalen är momsbefriad.<br/>Ja: Lokalen hyrs exkl moms.",
    hourly_rent: "Pris för enstaka timmar.",
    daily_rent: "Pris vid bokning av 8<br/>eller mer timmar.",
    monthly_rent: "Pris vid bokning av 30<br/>eller mer dagar.",
    workspace_rent: "Pris per arbetsplats (per månad).",
  };

  const renderTooltipIcon = (tooltipHtml) => (
    <svg
      data-tooltip-id="my-tooltip"
      data-tooltip-html={tooltipHtml}
      viewBox="0 0 512 512"
      fill="currentColor"
      height="1em"
      width="1em"

      {...props}
    >
      <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0 0 114.6 0 256s114.6 256 256 256zm-86.2-346.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1 0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6-13.3 0-24-10.7-24-24v-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1 0-8.4-6.8-15.1-15.1-15.1h-58.3c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32 14.3-32 32-32 32 14.3 32 32z" />
    </svg>
  );

  return (
    <div className="info-section">
      <table className="info-table">
        <tbody>
          <tr className="info-row">
            <th scope="row" className="info-item">
              Lokalyta
            </th>
            <td className="info-item-span">{ad?.area} kvm</td>
          </tr>
          <tr className="info-row">
            <th scope="row" className="info-item">
              Momsad lokal {renderTooltipIcon(tooltipText.moms)}
            </th>
            <td className="info-item-span">
              {ad?.vat_tax_required ? "Ja" : "Nej"}
            </td>
          </tr>
          {ad?.workspaces && (
            <tr className="info-row">
              <th scope="row" className="info-item">
                Antal platser
              </th>
              <td className="info-item-span">{ad?.workspaces}st</td>
            </tr>
          )}
        </tbody>
      </table>
      <hr />
      <br />
      <table className="info-table">
        <tbody>
          {ad?.hourly_rent ? (
            <tr className="info-row">
              <th scope="row" className="info-item">
                Timpris {renderTooltipIcon(tooltipText.hourly_rent)}
              </th>
              <td className="info-item-span">{ad?.hourly_rent} kr/timme</td>
            </tr>
          ): null}
          {ad?.daily_rent ? (
            <tr className="info-row">
              <th scope="row" className="info-item">
                Dagspris{renderTooltipIcon(tooltipText.daily_rent)}
              </th>
              <td className="info-item-span">{ad?.daily_rent} kr/dag</td>
            </tr>
          ): null}
          {ad?.monthly_rent ? (
            <tr className="info-row">
              <th scope="row" className="info-item">
                Månadspris {renderTooltipIcon(tooltipText.monthly_rent)}
              </th>
              <td className="info-item-span">{ad?.monthly_rent} kr/månad</td>
            </tr>
          ): null}
          {ad?.workspace_rent ? (
            <tr className="info-row">
              <th scope="row" className="info-item">
                Per arbetsplats {renderTooltipIcon(tooltipText.workspace_rent)}
              </th>
              <td className="info-item-span">{ad?.workspace_rent} kr/månad</td>
            </tr>
          ): null}
        </tbody>
      </table>
      <ReactTooltip id="my-tooltip" className="tooltip" />
    </div>
  );
}  

export default Info;
