import React from "react";
import "./collaborationsSection.css";
import fastighetsmassan from "../../../assets/partners/fastighetmassan.png";
import vinnova from "../../../assets/partners/vinnova.png";
import uic from "../../../assets/partners/uic.png";
import hyperisland from "../../../assets/partners/hyperisland.png";
import rekomo from "../../../assets/partners/rekomo.png";
import portally from "../../../assets/partners/portally.png";
import sizzle from "../../../assets/partners/sizzle.png";
import welistenin from "../../../assets/partners/welistenin.webp";
import inrego from "../../../assets/partners/inrego.png";
import viablecities from "../../../assets/partners/viablecities.png";
import sevena from "../../../assets/partners/7a.png";
import ebie from "../../../assets/partners/ebie.png";

export default function CollaborationsSection() {
  return (
    <div className={`collaborations-section`}>
      <h5>Samarbetspartners och finansiärer</h5>
      <div>
        <a
          className={`single-collaborator`}
          href={"https://www.fastighetsmassansthlm.se/advisory-board/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={`single-collaborator`} alt={"Fastighets mässan"} src={fastighetsmassan} />
        </a>

        <a
          className={`single-collaborator lg`}
          href={"https://www.vinnova.se/p/vakansa---identifierar-vakanser-for-hallbara-stader/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={`single-collaborator`} alt={"Vinnova"} src={vinnova} />
        </a>
        <a
          className={`single-collaborator`}
          href={"https://uic.se/bolag/vakansa/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={`single-collaborator`} alt={"UIC"} src={uic} />
        </a>

        <a
          className={`single-collaborator lg`}
          href={"https://www.hyperisland.com/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={`single-collaborator`} alt={"Hyper island"} src={hyperisland} />
        </a>
        <a
          className={`single-collaborator md`}
          href={"https://www.rekomo.se/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={`single-collaborator`} alt={"Rekomo"} src={rekomo} />
        </a>
        <a
          className={`single-collaborator sm`}
          href={"https://www.portally.com/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={`single-collaborator`} alt={"Portally"} src={portally} />
        </a>
        <a
          className={`single-collaborator`}
          href={"https://www.sizzle.se/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={`single-collaborator`} alt={"Sizzle"} src={sizzle} />
        </a>
        <a
          className={`single-collaborator`}
          href={"https://www.welistenin.com/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={`single-collaborator`} alt={"We listen in"} src={welistenin} />
        </a>
        <a
          className={`single-collaborator sm`}
          href={"https://inrego.se/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={`single-collaborator`} alt={"Inrego"} src={inrego} />
        </a>
        <a
          className={`single-collaborator sm`}
          href={"https://viablecities.se/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={`single-collaborator`} alt={"Viable Cities"} src={viablecities} />
        </a>
        <a
          className={`single-collaborator seven`}
          href={"https://7a.se/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={`single-collaborator`} alt={"7a"} src={sevena} />
        </a>
        <a
          className={`single-collaborator`}
          href={"https://ebie.se/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={`single-collaborator`} alt={"Ebie"} src={ebie} />
        </a>
      </div>
    </div>
  );
}
