import React from "react";
import styled from "styled-components";
import Spinner from "../../../components/Spinner";

function Loading({ text }) {
  return (
    <>
      <LoadingComponent>
        <Spinner />
        {text && <p>{text}</p>}
      </LoadingComponent>
      <LoadingBackdrop />
    </>
  );
}

const LoadingComponent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-modal);
  text-align: center;

  p {
    margin-top: 4rem;
    font-size: 2.25rem;
      
    color: var(--white);
  }
`

const LoadingBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-backdrop);

  background-color: var(--gray-black);
  opacity: 0.5;
`;

export default Loading;
