import React, { useEffect, useState } from "react";
import AdCard from "./AdCard";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { backend } from "../../../backend";
import { logAxiosError } from "../../../logging/error";
import { useAuth } from "../../../context/AuthContext";
import useCheckIfMobile from "../../../customHooks/useCheckIfMobile";
function MyAds() {
  const [ads, setAds] = useState([]);
  const [adCount, setAdCount] = useState({});
  const [adFilter, setAdFilter] = useState("all");
  const navigate = useNavigate();
  const auth = useAuth();
  const isMobile = useCheckIfMobile();

  useEffect(() => {
    const fetchMyAds = async () => {
      try {
        const { data } = await axios.get(
          backend("/api/listings/user/all-listings"),
          {
            params: {
              token: localStorage.getItem("token"),
            },
          }
        );

        if (data.status) {
          setAds(data.ads);
        } else {
          // no ads
          setAds([]);
        }
      } catch (error) {
        logAxiosError(error);
        setAds([]);
        if (error.response?.status === 401) {
          // invalid JWT token -> log out and return to landing page
          auth.logout();
        }
      }
    };

    fetchMyAds();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateAdCount(ads);
  }, [ads]);

  const updateAdCount = (ads = []) => {
    const initialValue = {
      all: 0,
      active: 0,
      inactive: 0,
    };

    // If ads array is not empty then set the
    // ad count for all, active and inactive ads
    if (ads) {
      let numberOfAds = ads.reduce(
        (total, ad) =>
          ad.active
            ? { ...total, active: total.active + 1 }
            : { ...total, inactive: total.inactive + 1 },
        initialValue
      );

      numberOfAds.all = numberOfAds.active + numberOfAds.inactive;
      setAdCount(numberOfAds);
    } else {
      // set ad count to all 0's
      setAdCount(initialValue);
    }
  };

  /**
   * Get the name of the user, or the organizations name
   */
  const getUserName = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const firstname = user?.first_name;
    const organization = user?.organization;

    if (firstname) {
      return firstname;
    } else {
      return organization;
    }
  };

  /**
   * Get number of total, active and inactive ads
   * @param {Object[]} [ads] - An array of ad objects
   */

  const filterAdsHandler = (ads) => {
    if (adFilter === "active") {
      return ads.filter((ad) => ad.active);
    } else if (adFilter === "inactive") {
      return ads.filter((ad) => !ad.active);
    } else {
      return ads;
    }
  };

  return (
    <>
      <MyAdsHeaderSection>
        <h1 data-testid="greeting-header">Hej {getUserName()}</h1>
        {isMobile ? <div className="separation-line" /> : null}
        <div className="card-filter">
          <button
            aria-label="all-ads"
            onClick={() => setAdFilter("all")}
            className={adFilter === "all" ? "filter-button-active" : undefined}
          >
            Alla {adCount.all}
          </button>
          <button
            aria-label="active-ads"
            onClick={() => setAdFilter("active")}
            className={
              adFilter === "active" ? "filter-button-active" : undefined
            }
          >
            Aktiva {adCount.active}
          </button>
          <button
            aria-label="inactive-ads"
            onClick={() => setAdFilter("inactive")}
            className={
              adFilter === "inactive" ? "filter-button-active" : undefined
            }
          >
            Inaktiva {adCount.inactive}
          </button>
        </div>
        {isMobile ? <div className="separation-line" /> : null}
      </MyAdsHeaderSection>
      <MyAdsCardSection data-testid="ad-section">
        <CreateAdCard
          data-testid="create-ad-card"
          onClick={() => navigate("/mina-sidor/skapa-annons")}
        >
          <img src="/images/icons/plus-circle.svg" alt="" />
          <p>Skapa annons</p>
        </CreateAdCard>
        {filterAdsHandler(ads).map((ad) => (
          <AdCard key={ad._id} ad={ad} setAds={setAds} />
        ))}
      </MyAdsCardSection>
    </>
  );
}

const MyAdsHeaderSection = styled.section`
  width: 34rem;
  padding-bottom: 2rem;

  h1 {
    white-space: nowrap;
  }

  h1,
  h2 {
    font-size: var(--font-size-medium);
    font-weight: 900;
    font-family: var(--montserrat);
    margin-bottom: 0;
  }

  & > h4 {
    font-size: 2.3rem;
    white-space: nowrap;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    margin-top: 0.75rem;
    width: 100%;
  }

  p {
    font-size: 1.5rem;
      
  }

  & > div > span {
    border: 1px solid var(--text-dark);
    border-radius: 2px;
    margin: 10px 0;
  }
  .separation-line {
    width: 100%;
    height: 3px;
    background: #DEDAD7;
    margin: 15px 0;
  }
  
.card-filter {
    grid-column: 1 / span 3;
    grid-row: 1 / 2;
    justify-self: center;

    button {
      flex-basis: auto;
      font-size: 1.8rem;
      font-weight: 700;
      border: none;
      background-color: transparent;
      color: var(--text-black);
      padding: 0.5rem 2rem;
      border-radius: 24px;
      white-space: nowrap;
      cursor: pointer;
    }

    .filter-button-active {
      color: var(--white);
      background-color: var(--green);
      box-shadow: var(--shadow-light);
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 980px) {
    width:100%;
    padding-bottom:0;
    span {
      display: none;
    }
    & > div {
          justify-content: space-around;
        
    & > div {
      display:flex;
      align-items: center;
      gap: 10px;
    }
    h2 {
      font-size:4rem;
    }
    p {
      margin: 0;
      font-size: 2rem;
    }

    }
  }
  @media only screen and (max-width:300px) {
    & > div {
    h2 {
      font-size:3rem;
    }
    p {
      font-size:1.5rem;
    }
  }
`;

const MyAdsCardSection = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22.5%, 33rem));
  gap: 3.5rem max(2rem, 3%);

  h4 {
    font-size: 2.8rem;
    font-weight: 700;
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }

  .card-filter {
    grid-column: 1 / span 3;
    grid-row: 1 / 2;
    justify-self: center;

    button {
      flex-basis: auto;
      font-size: 1.8rem;
      font-weight: 700;
      border: none;
      background-color: transparent;
      color: var(--text-black);
      padding: 0.5rem 2rem;
      border-radius: 24px;
      white-space: nowrap;
      cursor: pointer;
    }

    .filter-button-active {
      color: var(--white);
      background-color: var(--green);
      box-shadow: var(--shadow-light);
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 1350px) {
    gap: 3rem max(1rem);
   
    .card-filter {
    grid-column: 3 / 3;
    grid-row: 1 / 2;
    justify-self: center;
    
  }

  @media only screen and (max-width: 1300px) {
    grid-template-columns: repeat(auto-fit, minmax(10.5%, 30rem));
  }

  @media only screen and (max-width: 980px) {
    display:flex;
    flex-direction: column;
    align-items: center;
      & > *, button {
        width:100%;
        text-align: center;
      } 
      .card-filter {
        display: flex;
    }
  }
`;

const CreateAdCard = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 33rem;
  height: 34.5rem;
  background-color: var(--white);
  box-shadow: var(--shadow-medium);
  border-radius: 4px;
  border: none;
  cursor: pointer;

  img {
    width: 16rem;
  }

  p,
  svg {
    font-size: var(--font-size-small);
    font-weight: 700;

    color: var(--text-light);
    text-decoration: none;
  }
  @media only screen and (max-width: 1310px) {
    width: 30rem;
    height: 32rem;
  }
  @media only screen and (max-width: 980px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    height: 100%;
    box-shadow: none;
    & img {
      width: 12rem;
      box-shadow: var(--shadow-medium);
      border-radius: 7px;
    }
  }
`;

export default MyAds;
