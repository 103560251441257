import React from "react";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";
import SEOTags from "../../../utils/SEOTags";

const RenterFAQ = () => {
  return (
    <>
      <SEOTags
        title={"För Hyresgästen - Vakansa"}
        description={"Kostar det något att registrera sig på plattformen?"}
        canonicalUrl={"/fragor-och-svar/hyresgast"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main>
        <div className="faq-info-wrapper">
          <h1>För Hyresgästen</h1>
          <h2>Kostar det något att registrera sig på plattformen?</h2>
          <p>
            Det kostar ingenting att registrera sig, intresseanmäla eller att
            annonsera en lokal. Se <a href="/hyr-ut-lokal">Hyr ut lokal</a> för
            mer info om våra priser.
          </p>
          <h2>Hur hyr jag en lokal via Vakansa?</h2>
          <p>
            {" "}
            När du hittat en lokal du vill hyra tidsvakanser i klickar du på
            “Skicka förfrågan” knappen på annonsen där du kan meddela önskad
            tid, dag och andra önskemål till hyresvärden. Därefter återkommer vi
            med eventuella frågor och ordnar med en visning av lokalen om
            lokalen är tillgänglig när ni önskar använda den. Om allt ser bra ut
            upprättas ett kontrakt mellan er och hyresvärden och du får
            tillträde till lokalen.
          </p>
          <h2>Vad är en tidsvakans?</h2>
          <p>
            En tidsvakans är en tidslucka i en lokal som du kan hyra. Exempelvis
            kl 17-22 varje måndag, kl 06-15 varje fredag eller kl 8-20 lördag
            och söndag. För enstaka tillfällen eller i flera månader. Vilka
            timmar och hur länge bestämmer du. Vill du veta mer om varför vi
            använder ordet “tidsvakanser?” - Se vår grundare Robin Rushdi
            Al-Sálehi göra sin TEDx från 2016{" "}
            <a href="https://www.youtube.com/embed/HpAaSOgpi50">här</a>.{" "}
          </p>{" "}
          <h2>Kan ni hjälpa till med att skriva kontrakt parterna emellan?</h2>
          <p>
            Vakansa har två prismodeller, “Vakansa Bas” och “Vakansa Mest”,
            varav “Vakansa Mest” omfattar juridisk hjälp med att upprätta
            kontrakt parterna emellan oavsett om lokalen är en momsad lokal
            eller icke momsad lokal.
          </p>
          <h2>Vad förbinder jag mig till när jag använder Vakansa?</h2>
          <p>
            Du förbinder dig att följa våra{" "}
            <a href="/anvandarvillkor">Användarvillkor</a>.
          </p>
          <br />
          <br />
          <p>
            Kontakta oss gärna på e-postadressen:{" "}
            <a href="mailto:info@vakansa.se">info@vakansa.se</a>,{" "}
            <a href="tel:+46767065662">Telefon: 076-706 56 62</a>
            <br />
            <br />
          </p>
          <br />
          <RoundedLinkButton
            label="Tillbaka"
            size="md"
            color="white"
            href="/fragor-och-svar"
          />
        </div>
      </main>
    </>
  );
};

export default RenterFAQ;
