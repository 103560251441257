import React from "react";
import "./articleCard.css";

export default function ArticleCard({ link, img, title, quote }) {
  return (
    <article>
      <a
        className={`article-card`}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={img} alt="article" />
        <h3>{title}</h3>
        <p>"{quote}"</p>
      </a>
    </article>
  );
}
