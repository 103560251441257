import React, { useState, useEffect } from "react";
import "./unitTypeSection.css";
import UnitTypeCard from "./UnitTypeCard/UnitTypeCard";
import office from "../../../assets/spaces/office.webp";
import meeting from "../../../assets/spaces/meeting.webp";
import dance from "../../../assets/spaces/dance-studio.webp";
import event from "../../../assets/spaces/festlokal.webp";

export default function UnitTypeSection({ listings }) {
  const [totalAreas, setTotalAreas] = useState({
    kontor: 0,
    moteslokal: 0,
    idrottslokal: 0,
    konferenslokal: 0,
  });

  useEffect(() => {
    if (Array.isArray(listings)) {
      const areas = {
        kontor: 0,
        moteslokal: 0,
        idrottslokal: 0,
        eventlokal: 0,
      };

      listings.forEach((ad) => {
        switch (ad.type_of_room) {
          case "Kontorslokal":
          case "Kontorshotell/Co-working":
            areas.kontor += ad.area;
            break;
          case "Möteslokal":
          case "Klassrum":
            areas.moteslokal += ad.area;
            break;
          case "Idrott/danslokal":
            areas.idrottslokal += ad.area;
            break;
          case "Eventlokal":
          case "Teaterlokal":
          case "Matsal/Kök":
          case "Biograf":
          case "Kyrka":
          case "Pop-up":
          case "Musiksal":
          case "Studio":
            areas.eventlokal += ad.area;
            break;
          default:
            break;
        }
      });

      setTotalAreas(areas);
    }
  }, [listings]);

  return (
    <section className={`unit-type-section`}>
      <div className="unit-outer">
        <h3>Populära lokaltyper</h3>
        <UnitTypeCard
          title={"Kontorslokaler"}
          imgSrc={office}
          imgAlt={"Bild på ett kontor"}
          totalArea={totalAreas.kontor}
          listings={
            listings.filter((ad) =>
              ["Kontorslokal", "Kontorshotell/Co-working"].includes(
                ad.type_of_room
              )
            ).length
          }
          href="/lediga-lokaler?sida=1&lokaltyp=Kontorslokal%2CKontorshotell%2FCo-working"
        />
        <UnitTypeCard
          title={"Möteslokaler"}
          imgSrc={meeting}
          imgAlt={"Bild på en samlingslokal"}
          totalArea={totalAreas.moteslokal}
          listings={
            listings.filter((ad) =>
              ["Möteslokal", "Matsal/Kök", "Biograf", "Teaterlokal"].includes(
                ad.type_of_room
              )
            ).length
          }
          href="/lediga-lokaler?sida=1&lokaltyp=Möteslokal%2CMatsal%2FKök%2CBiograf%2CTeaterlokal"
        />
        <UnitTypeCard
          title={"Idrotts/danslokaler"}
          imgSrc={dance}
          imgAlt={"Bild på en dansstudio"}
          totalArea={totalAreas.idrottslokal}
          listings={
            listings.filter((ad) =>
              ["Idrott/danslokal"].includes(ad.type_of_room)
            ).length
          }
          href="/lediga-lokaler?sida=1&lokaltyp=Idrott%2Fdanslokal"
        />
        <UnitTypeCard
          title={"Eventlokaler"}
          imgSrc={event}
          imgAlt={"Bild på en eventlokal"}
          totalArea={totalAreas.eventlokal}
          listings={
            listings.filter((ad) => ["Eventlokal"].includes(ad.type_of_room))
              .length
          }
          href="/lediga-lokaler?sida=1&lokaltyp=Eventlokal"
        />
      </div>
    </section>
  );
}
