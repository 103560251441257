import React, { useEffect, useState } from "react";
import { TermsPage } from "./styles";
import SEOTags from "../../utils/SEOTags";
import mammoth from "mammoth";
import termsDoc from "../../assets/terms.docx";

function Terms() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" }); // scrolls to top on first render
  }, []);

  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(termsDoc)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        return mammoth.convertToHtml({ arrayBuffer });
      })
      .then((result) => {
        setContent(result.value);
      })
      .catch((error) => {
        console.error("Error loading DOCX file:", error);
      });
  }, []);

  return (
    <TermsPage>
      <SEOTags
        title={"Användarvillkor"}
        description={
          "Dessa användarvillkor reglerar användningen av Tjänsterna (“Tjänsterna”, se vidare p.3) som tillhandahålls av Vakansa AB."
        }
        canonicalUrl={"/anvandarvillkor"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <div
        className="docx-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </TermsPage>
  );
}

export default Terms;
