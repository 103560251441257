import React from "react";
import "./breadcrumbs.css";

function Breadcrumbs({ ad, props }) {
  return (
    <div className="breadcrumbs-container ">
      <div className="breadcrumbs-inner">
        <a aria-label="go-to-homepage" href="/">
          <svg
            fill="currentColor"
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            {...props}
          >
            <path
              fillRule="evenodd"
              d="M8 3.293l6 6V13.5a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z"
            />
            <path
              fillRule="evenodd"
              d="M7.293 1.5a1 1 0 011.414 0l6.647 6.646a.5.5 0 01-.708.708L8 2.207 1.354 8.854a.5.5 0 11-.708-.708L7.293 1.5z"
            />
          </svg>
        </a>
        &middot;
        <a href="/lediga-lokaler">Lediga lokaler</a>
        &middot;
        <a href={`/lediga-lokaler?sida=1&kommun=${ad.city}`}>{ad.city}</a>
        &middot;
        <a
          href={`/lediga-lokaler?sida=1&lokaltyp=${ad.type_of_room}&kommun=${ad.city}`}
        >
          {ad.type_of_room}
        </a>
        &middot;
        <u>{ad.address}</u>
      </div>
    </div>
  );
}

export default Breadcrumbs;
