import React from "react";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";
import SEOTags from "../../../utils/SEOTags";

const LandlordFAQ = () => {
  return (
    <>
      <SEOTags
        title={"För Hyresvärden - Vakansa"}
        description={"Kostar det något att annonsera sin lokal?"}
        canonicalUrl={"/fragor-och-svar/hyresvard"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main className="faq-info-wrapper">
        <h1>För Hyresvärden</h1>
        <h2>Kostar det något att annonsera sin lokal?</h2>{" "}
        <p>
          Det kostar ingenting att registrera sig, intresseanmäla eller att
          annonsera en lokal. Se <a href="/hyr-ut-lokal">Hyr ut lokal</a> för
          mer info om hur vi tar betalt när vi hittat en hyresgäst åt er som
          börjar hyra av er!
        </p>{" "}
        <h2>Vad kostar det att använda Vakansa?</h2>
        <p>
          {" "}
          Du förbinder dig inte till någon kostnad genom att använda Vakansa
          utan betalar först när du tecknat ett nytt hyresavtal. Vår
          förmedlingsavgift är 15% av årsbashyran, dvs årshyra exkl. eventuella
          rabatter samt hyrestillägg som fastighetsskatt och drift. På
          uthyrningar per timme, dag, vecka och månad tar vi 15% av den
          sammanlagda hyresintäkten ni fått in från en hyresgäst förmedlad av
          Vakansa.  Fakturering av arvodet sker efter det att Användaren tecknat
          hyresavtal med hyresgästen. Betalningsvillkor är 30 dagar netto.
          Mervärdesskatt tillkommer på samtliga belopp enligt dessa villkor.
          Fullständiga användarvillkor. Dessa finner du här -{" "}
          <a href="/anvandarvillkor">Användarvillkor</a>
        </p>{" "}
        <h2>
          Vilken information om min lokal behöver jag för att hyra ut via
          Vakansa?
        </h2>
        <p>
          {" "}
          För varje lokal som läggs upp på Vakansa behövs ett namn på lokalen,
          gällande hyresnivå för varje tidsperiod som ni önskar hyra ut
          lokalerna. Vilken typ av lokal det är, hur stort utrymmet är i
          kvadratmeter, antal personer som lokalen är lämplig för, en kort
          beskrivning av lokalen, vilka bekvämligheter som ingår samt minst en
          bild på lokalen. Önskar ni hjälp med detta är ni välkomna att kontakta
          oss på{" "}<a href="mailto:info@vakansa.se">info@vakansa.se</a>
        </p>{" "}
        <h2>Hur hyr jag ut min lokal via Vakansa?</h2>
        <p>
          {" "}
          För att kunna hyra ut din lokal behöver du först registrera dig för
          att skapa ett konto i Vakansa. Efter att du aktiverat ditt konto via
          aktiveringsmailet som skickats till den mail du angett när du skapat
          kontot, ska du logga in på din egna “mina sidor” sida på vakansa.se
          där du kan “skapa annons” i din portal, eller som vi kallar det för,
          dashboard. När du klickat dig vidare så svarar du, fyller i och laddar
          upp lämplig information och bilder över lokalen för att sedan klicka
          på “publicera annons”. En väldigt smidig och lätt process enligt våra
          befintliga användare.
        </p>{" "}
        <h2>Vad är en tidsvakans?</h2>
        <p>
          {" "}
          En tidsvakans är en tidslucka i en lokal som du kan hyra. Exempelvis
          kl 17-22 varje måndag, kl 06-15 varje fredag eller kl 8-20 lördag och
          söndag. För enstaka tillfällen eller i flera månader. Vilka timmar och
          hur länge bestämmer du.
        </p>{" "}
        <h2>Hur går uthyrningsprocessen till?</h2>
        <p>
          {" "}
          När du publicerat en annons för din lokal kan besökare anmäla intresse
          på er/era annonser. Dessa skickas till oss på Vakansa för att göra en
          första screening där vi kontaktar den potentiella hyresgästen för att
          validera deras intresse och för att undersöka organisationen. Vidare
          sätter vi ihop ett möte mellan hyresgästen och er där de får möjlighet
          att gå på visning för att se lokalen. Om alla känner sig nöjda så
          skriver ni ihop ett hyresavtal och vi på Vakansa fakturerar er för vår
          förmedlingstjänst. (Se priser på{" "}
          <a href="/hyr-ut-lokal">Hyr ut lokal</a>).
        </p>{" "}
        <h2>Kan man tacka ja eller nej till hyresgäster ni skickar?</h2>
        <p>
          {" "}
          Ni tar emot hur många förfrågningar från potentiella hyresgäster som
          ni önskar. Ni har alltid slutordet och vi hjälper er gärna med att
          skriva avtal när ni väl bestämmer er för att ta emot en hyresgäst från
          oss.
        </p>{" "}
        <h2>Hur marknadsför Vakansa min yta?</h2>
        <p>
          {" "}
          I dagens digitala värld så är det svårt att tränga igenom bruset.
          Detta får du hjälp med helt kostnadsfritt på Vakansa genom att ladda
          upp dina lokaler för uthyrning. Vi jobbar jämt och ständigt med
          exponeringen av annonser via sociala medier, Google och
          samarbetspartner. Du får bokningsförfrågningar och väljer själv vilka
          du vill ta emot eller neka. Vi vill göra det så enkelt som möjligt för
          fler att välja andrahands lokaler i första hand!
        </p>{" "}
        <h2>Kan ni hjälpa till med att skriva kontrakt parterna emellan?</h2>
        <p>
          {" "}
          Vakansa har två prismodeller, Vakansa Bas och Vakansa Mest, varav
          Vakansa Mest omfattar juridisk hjälp med att upprätta kontrakt
          parterna emellan oavsett om lokalen är en momsad lokal eller icke
          momsad lokal.
        </p>{" "}
        <h2>Får jag annonsera på andra ställen samtidigt?</h2>
        <p>
          {" "}
          Vi på Vakansa kräver ingen ensamrätt och vill inte låsa upp företag.
          Vill ni använda er av andras digitala tjänster är det fritt fram.
        </p>{" "}
        <h2>Vad ska jag ta betalt för min yta?</h2>
        <p>
          {" "}
          Vill ni ha hjälp med prissättning så kan ni med fördel höra av er till
          oss på <a href="mailto:info@vakansa.se">info@vakansa.se</a> med
          bilder, adress, kvadratmeter ni önskar hyra ut och annan information
          så hjälper vi er.
        </p>{" "}
        <h2>Vad förbinder jag mig till när jag använder Vakansa?</h2>
        <p>
          {" "}
          Du förbinder dig att följa våra användarvillkor. Dessa finner du här -{" "}
          <a href="/anvandarvillkor">Användarvillkor</a>
        </p>
        <br />
        <br />
        <p>
          Kontakta oss gärna på e-postadressen:{" "}
          <a href="mailto:info@vakansa.se">info@vakansa.se</a>,{" "}
          <a href="tel:+46767065662">Telefon: 076-706 56 62</a>
          <br />
          <br />
        </p>
        <br />
        <RoundedLinkButton
          label="Tillbaka"
          size="md"
          color="white"
          href="/fragor-och-svar"
        />
      </main>
    </>
  );
};

export default LandlordFAQ;
