import React from "react"
import "./scalingButton.css"

export default function ScalingButton({ color, size, label, customUrl, onClick }) {

  const handleClick = () => {
    if (onClick) {
      onClick(label)
    }
  }

  return (
    <button className={`scaling-button ${color} ${size}`} src={customUrl} onClick={handleClick} >
      {label}
    </button>
  )
}
