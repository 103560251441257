import React from "react";
import "./simplifiedBooking.css";

function SimplifiedBooking({ ad, setShowBookingModal }) {
  /**
   * Get all available price types for the ad. If the ad
   * does not have any prices. Say that price can be discussed
   * @param {object} ad - Ad object fetched from database
   */
  const getPriceInfo = (ad) => {
    const availablePrices = [];

    if (ad?.hourly_rent) {
      availablePrices.push(
        <p key="1">
          <b>Timpris: {ad?.hourly_rent} kr</b>/timme
        </p>
      );
    }

    if (ad?.daily_rent) {
      availablePrices.push(
        <p key="2">
          <b>Dagspris: {ad?.daily_rent} kr</b>/dag
        </p>
      );
    }

    if (ad?.monthly_rent) {
      availablePrices.push(
        <p key="4">
          <b>Månadspris: {ad?.monthly_rent} kr</b>/månad
        </p>
      );
    }

    if (ad?.workspace_rent) {
      availablePrices.push(
        <p key="3">
          <b>Arbetsplats/månad: {ad?.workspace_rent} kr</b>/månad
        </p>
      );
    }

    // If no prices are available. Say that price is to be discussed
    if (availablePrices.length === 0) {
      return <span>Pris på förfrågan</span>;
    } else {
      return availablePrices;
    }
  };

  return (
    <div id="ad-page-booking">
      <h1>{ad.address}</h1>
      <h2>
        {ad.type_of_room}, {ad.municipality}
      </h2>
      <div id="ad-page-booking-price">
        <div id="ad-page-booking-price-info">
          <div>{getPriceInfo(ad)}</div>
        </div>
      </div>
      <p id="ad-page-booking-summary">
        Genom att skicka in din intresseanmälan nedan kan du få direkt kontakt
        med lokalägaren! Se till att beskriva vilka ni är, vad syftet är med att
        hyra lokalen och när ni önskar hyra lokalen.
        <br />
        <br />
        Välkomna!
      </p>
      <button
        data-testid="book-viewing-btn"
        id="ad-page-booking-button"
        onClick={() => setShowBookingModal(true)}
      >
        Skicka förfrågan
      </button>
    </div>
  );
}

export default SimplifiedBooking;
