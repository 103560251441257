import React from "react";
import "./unitTypeCard.css";

export default function UnitTypeCard({
  title,
  imgSrc,
  imgAlt,
  totalArea,
  listings,
  onClick,
  href,
}) {
  return (
    <a href={href} className={`unit-type-card`} onClick={onClick}>
      <img className="unit-grid-item" src={imgSrc} alt={imgAlt} />
      <h2>{title}</h2>
      <p>Totalt: {totalArea}m2</p>
      <p>Antal annonser: {listings}</p>
    </a>
  );
}
