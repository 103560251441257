/**
 * logs axios errors caught by try catch clause
 * @param error axios error that was caught
 */
 export function logAxiosError(error) {
  if (error.response) {
    // Request made but the server responded with an error
    console.error(`Server error (${error.response.status}), msg: ${error.response.data.msg}`);
  } else if (error.request) {
    // Request made but no response is received from the server.
    console.error('No respone from server', error.request);
  } else {
    // Error occured while setting up the request
    console.error('Error sending request', error.message);
  }
}
