import React, { useEffect, useState } from "react";
import "./timeDropdown.css";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import TextButton from "../../../../components/Buttons/TextButton/TextButton";

export default function TimeDropdown({
  selectedTime,
  setSelectedTime,
  closeTimeDropdown,
}) {
  const [, setIsSaveButtonPressed] = useState(false);
  const handleTimeChange = (time) => {
    if (selectedTime.includes(time)) {
      setSelectedTime(selectedTime.filter((item) => item !== time));
    } else {
      setSelectedTime([...selectedTime, time]);
    }
  };

  const handleClearButtonClick = () => {
    setSelectedTime([]);
    sessionStorage.removeItem("selectedTime");
    setIsSaveButtonPressed(false);
  };

  useEffect(() => {
    const savedSelectedTime = JSON.parse(
      sessionStorage.getItem("selectedTime")
    );
    if (savedSelectedTime) {
      setSelectedTime(savedSelectedTime);
      if (savedSelectedTime && savedSelectedTime.length > 0) {
        setIsSaveButtonPressed(true);
      }
    }
  }, [setSelectedTime]);

  useEffect(() => {
    sessionStorage.setItem("selectedTime", JSON.stringify(selectedTime));
  }, [selectedTime]);

  return (
    <div className={`time-dropdown`}>
      <p>Hur vill du hyra?</p>
      <div className="time-content-wrapper">
        <div className="time-checkbox-wrapper">
          <Checkbox
            label="Timvis"
            isChecked={selectedTime.includes("Timvis")}
            onChange={() => handleTimeChange("Timvis")}
          />
          <Checkbox
            label="Dagsvis"
            isChecked={selectedTime.includes("Dagsvis")}
            onChange={() => handleTimeChange("Dagsvis")}
          />
          <Checkbox
            label="Månadsvis"
            isChecked={selectedTime.includes("Månadsvis")}
            onChange={() => handleTimeChange("Månadsvis")}
          />
          <Checkbox
            label="Arbetsplats/månad"
            isChecked={selectedTime.includes("Per arbetsplats")}
            onChange={() => handleTimeChange("Per arbetsplats")}
          />
        </div>
      </div>
      <hr />
      <div className="time-button-wrapper">
        <TextButton label="Rensa" onClick={handleClearButtonClick} />
      </div>
    </div>
  );
}
