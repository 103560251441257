import React, { useEffect, useState } from "react";
import './spaceDropdown.css'
import Checkbox from "../../../../components/Checkbox/Checkbox"
import TextButton from "../../../../components/Buttons/TextButton/TextButton";
import RoomTypesModal from './RoomTypesModal/RoomTypesModal';

export default function SpaceDropdown({ selectedRoomTypes, setSelectedRoomTypes }) {
  const [, setIsSaveButtonPressed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleTypeChange = (type) => {
    if (selectedRoomTypes.includes(type)) {
      setSelectedRoomTypes(selectedRoomTypes.filter(item => item !== type));
    } else {
      setSelectedRoomTypes([...selectedRoomTypes, type]);
    }
  }

  const handleClearButtonClick = () => {
    setSelectedRoomTypes([])
    sessionStorage.removeItem('selectedRoomTypes');
    setIsSaveButtonPressed(false);
  }

  useEffect(() => {
    const savedSelectedRoomTypes = JSON.parse(sessionStorage.getItem('selectedRoomTypes'));
    if (savedSelectedRoomTypes) {
      setSelectedRoomTypes(savedSelectedRoomTypes);
      if (savedSelectedRoomTypes && savedSelectedRoomTypes.length > 0) {
        setIsSaveButtonPressed(true);
      }
    }
  }, [setSelectedRoomTypes]);

  useEffect(() => {
    sessionStorage.setItem('selectedRoomTypes', JSON.stringify(selectedRoomTypes));
  }, [selectedRoomTypes]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };


  return (
    <div className={`space-dropdown`}>
      <p className="space-dropdown-top-text">Typ av lokal</p>
      <div className='space-checkbox-wrapper'>
        <div className="space-checkbox-inner-wrapper">
          <Checkbox label='Kontorslokal' isChecked={selectedRoomTypes.includes('Kontorslokal')} onChange={() => handleTypeChange('Kontorslokal')} />
          <Checkbox label='Kontorshotell/Co-working' isChecked={selectedRoomTypes.includes('Kontorshotell/Co-working')} onChange={() => handleTypeChange('Kontorshotell/Co-working')} />
          <Checkbox label='Möteslokal' isChecked={selectedRoomTypes.includes('Möteslokal')} onChange={() => handleTypeChange('Möteslokal')} />
          <Checkbox label='Klassrum' isChecked={selectedRoomTypes.includes('Klassrum')} onChange={() => handleTypeChange('Klassrum')} />
          <Checkbox label='Eventlokal' isChecked={selectedRoomTypes.includes('Eventlokal')} onChange={() => handleTypeChange('Eventlokal')} />
        </div>
        <div className="show-more-wrapper">
          <p className="space-dropdown-show-more" onClick={handleOpenModal}>Visa mer lokaler</p>
        </div>
        {isModalOpen && (
          <RoomTypesModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            selectedRoomTypes={selectedRoomTypes} setSelectedRoomTypes={setSelectedRoomTypes}
          />
        )}
      </div>
      <hr />
      <div className='space-button-wrapper'>
        <TextButton label='Rensa' onClick={handleClearButtonClick} />
      </div>
    </div>
  )
}
