import React, { useEffect, useState } from 'react';
import './municipalityDropdown.css';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import TextButton from '../../../../components/Buttons/TextButton/TextButton';
import MunicipalityModal from "./MunicipalityModal/MunicipalityModal"

export default function MunicipalityDropdown({ selectedMunicipality, setSelectedMunicipality, closeMunicipalityDropdown }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, setIsSaveButtonPressed] = useState(false);

  const handleMunicipalityChange = (municipality) => {
    if (selectedMunicipality.includes(municipality)) {
      setSelectedMunicipality(selectedMunicipality.filter(item => item !== municipality));
    } else {
      setSelectedMunicipality([...selectedMunicipality, municipality]);
    }
  };

  const handleClearButtonClick = () => {
    setSelectedMunicipality([]);
    sessionStorage.removeItem('selectedMunicipality');
    setIsSaveButtonPressed(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const savedSelectedMunicipality = JSON.parse(sessionStorage.getItem('selectedMunicipality'));
    if (savedSelectedMunicipality) {
      setSelectedMunicipality(savedSelectedMunicipality);
      if (savedSelectedMunicipality && savedSelectedMunicipality.length > 0) {
        setIsSaveButtonPressed(true);
      }
    }
  }, [setSelectedMunicipality]);

  useEffect(() => {
    sessionStorage.setItem('selectedMunicipality', JSON.stringify(selectedMunicipality));
  }, [selectedMunicipality]);

  return (
    <div className={`municipality-dropdown`}>
      <p className='municipality-top-text'>Var?</p>
      <div className='municipality-content-wrapper'>
        <div className='municipality-checkbox-wrapper'>
          <Checkbox label='Stockholm' isChecked={selectedMunicipality.includes('Stockholm')} onChange={() => handleMunicipalityChange('Stockholm')} />
          <Checkbox label='Uppsala' isChecked={selectedMunicipality.includes('Uppsala')} onChange={() => handleMunicipalityChange('Uppsala')} />
          <Checkbox label='Göteborg' isChecked={selectedMunicipality.includes('Göteborg')} onChange={() => handleMunicipalityChange('Göteborg')} />
          <Checkbox label='Malmö' isChecked={selectedMunicipality.includes('Malmö')} onChange={() => handleMunicipalityChange('Malmö')} />

        </div>
        <p className="municipality-dropdown-show-more" onClick={handleOpenModal}>Visa fler kommuner</p>
        {isModalOpen && (
          <MunicipalityModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            selectedMunicipality={selectedMunicipality}  // Make sure you pass the correct prop
            setSelectedMunicipality={setSelectedMunicipality}  // Make sure you pass the correct prop
          />
        )}
      </div>
      <hr />
      <div className='municipalitys-button-wrapper'>
        <TextButton label='Rensa' size="sm" onClick={handleClearButtonClick} />
      </div>
    </div>
  );
}

