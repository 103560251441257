import React, { useRef } from "react";
import "./steps.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Steps(props) {
  const isMobile = window.innerWidth < 580;

  const sliderRef = useRef(null);

  const settings = {
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: false,
  };

  return (
    <div className="steps">
    <h2>3 enkla steg till din drömlokal</h2>
      {isMobile === true ? (
        <div className="steps-container-carousel-outer">
          <div
            className="steps-container-carousel"
            style={{ margin: "0 auto" }}
          >
            <Slider ref={sliderRef} {...settings}>
              <div className="steps-outer-carousel">
                <div className="steps-circle">
                  <span>1</span>
                </div>
                <b className="description-heading">Hitta lokal</b>
                <p className="steps-text">
                  Utforska lediga lokaler på Vakansas plattform och skicka in
                  din intresseanmälan direkt till hyresvärden.
                </p>
              </div>

              <div className="steps-outer-carousel">
                <div className="steps-circle">
                  <span>2</span>
                </div>
                <b>Boka visning</b>
                <p className="steps-text">
                  När du och hyresvärden har fått kontakt kan ni boka tid för en
                  visning av lokalen och ställa eventuella frågor om hur
                  uthyrningen skulle gå till.
                </p>
              </div>

              <div className="steps-outer-carousel">
                <div className="steps-circle">
                  <span>3</span>
                </div>
                <b>Skriv avtal</b>
                <p className="steps-text">
                  Om allt ser bra ut och ni kommer överens, skrivs ett kontrakt 
                  mellan er som hyresgäst och hyresvärd. Därefter får du 
                  tillgång till lokalen.
                </p>
              </div>
            </Slider>
          </div>
          <div className="steps-arrows">
            <svg
              fill="currentColor"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              {...props}
              className="steps-arrow steps-arrow-prev"
              onClick={() => sliderRef?.current?.slickPrev()}
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z"
              />
            </svg>
            <svg
              fill="currentColor"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              {...props}
              className="steps-arrow steps-arrow-next"
              onClick={() => sliderRef?.current?.slickNext()}
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z"
              />
            </svg>
          </div>
        </div>
      ) : (
        <>
          {isMobile === false ? (
            <div className="steps-container">
              <div className="steps-outer">
                <div className="steps-circle">
                  <span>1</span>
                </div>
                <b className="steps-heading">Hitta lokal</b>
                <p className="steps-text">
                  Utforska lediga lokaler på Vakansas plattform och skicka in
                  din intresseanmälan direkt till hyresvärden.
                </p>
              </div>

              <div className="steps-outer">
                <div className="steps-circle">
                  <span>2</span>
                </div>
                <b className="steps-heading">Boka visning</b>
                <p className="steps-text">
                  När du och hyresvärden har fått kontakt kan ni boka tid för en
                  visning av lokalen och ställa eventuella frågor om hur
                  uthyrningen skulle gå till.
                </p>
              </div>

              <div className="steps-outer">
                <div className="steps-circle">
                  <span>3</span>
                </div>
                <b className="steps-heading">Skriv avtal</b>
                <p className="steps-text">
                      Om allt ser bra ut och ni kommer överens, skrivs ett kontrakt 
                  mellan er som hyresgäst och hyresvärd. Därefter får du 
                  tillgång till lokalen.
                </p>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
