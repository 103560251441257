import React from "react";
import "./carouselCard.css";

export default function CarouselCard({ link, img, title, quote }) {
  return (
    <div className={`carousel-card`}>
      <a
        className={`carousel-card-link`}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="mobile-grid-item" src={img} alt={title} />

        <h3>{title}</h3>
        <p>"{quote}"</p>
      </a>
    </div>
  );
}
