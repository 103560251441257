import React from "react";
import "./videoSection.css";
import vakansa from "../../../assets/videos/vakansa-small.mp4";

export default function VideoSection() {
  return (
    <div className={`home-video-section`}>
      <div className="home-about-video-inner">
        <div id="home-about-text">
          <h2>Hur fungerar Vakansa?</h2>
          <div className="home-about-text-inner">
            <p>
              Vi på Vakansa vet hur utmanande det kan vara att hitta prisvärda,
              passande och flexibla lokaler för sin verksamhet. Därför har vi
              byggt upp denna lokaldelningsplattform som gör det möjligt för
              både dig som hyresvärd och hyresgäst att hyra och hyra ut lokaler
              på ett enkelt sätt.
            </p>
            <br />
            <p>
              Oavsett om ni letar efter en flexibel kontorslokal, en coworking
              yta, en inspirerande ateljé, en idrottshall för barnkalas eller
              ett behandlingsrum så har vi ett stort utbud av lokaler som inte
              finns någon annanstans!
            </p>
            <br />
            <p>
              På Vakansa kan lokaler hyras i antingen förstahand eller andrahand
              per timme, dag, vecka eller månad.
            </p>
          </div>
        </div>
        <div className="home-about-video">
          <div className="video-container">
            <div className="video-card-container">
              <video controls width="100%" height="auto">
                <source src={vakansa} type="video/mp4" />
                your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
