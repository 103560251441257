import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import MyAds from "./components/MyAds";
import CreateAd from "./components/CreateAd";
import Settings from "./components/Settings";
import Favourites from "./components/Favourites";
import styled from "styled-components";
import Support from "./components/Support";
import EditAd from "./components/EditAd";
import { Helmet } from "react-helmet-async";
import FeedbackModal from "./components/FeedbackButton/FeedbackModal";

function Dashboard() {
  const { page } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [page]);

  const loadContentHandlers = () => {
    switch (page) {
      case "annonser":
        return (
          <>
            <Helmet>
              <title>Mina sidor - Vakansa</title>
            </Helmet>
            <div className="breadcrumb-top">
              <p id="dashboard-breadcrumbs"></p>
              <FeedbackModal />
            </div>
            <MyAds />
          </>
        );
      case "skapa-annons":
        return (
          <>
            <Helmet>
              <title>Skapa annons - Vakansa</title>
            </Helmet>
            <p id="dashboard-breadcrumbs">
              <Link to="/mina-sidor/annonser">Mina sidor</Link>&middot;
              <Link to="/mina-sidor/annonser">Annonser</Link>&middot;
              <Link to="/mina-sidor/skapa-annons">Skapa annons</Link>
            </p>
            <CreateAd />
          </>
        );
      case "redigera-annons":
        return (
          <>
            <Helmet>
              <title>Redigera annons - Vakansa</title>
            </Helmet>
            <p id="dashboard-breadcrumbs">
              <Link to="/mina-sidor/annonser">Mina sidor</Link>&middot;
              <Link to="/mina-sidor/annonser">Mina annonser</Link>&middot;
              <Link to="/mina-sidor/annonser">Redigera annons</Link>
            </p>
            <EditAd />
          </>
        );
      case "favoriter":
        return (
          <>
            <p id="dashboard-breadcrumbs">
              <Link to="/mina-sidor/annonser">Mina sidor</Link>&middot;
              <Link to="/mina-sidor/favoriter">Favoriter</Link>
            </p>
            <Favourites />
          </>
        );
      case "support":
        return (
          <>
            <Helmet>
              <title>Support - Vakansa</title>
            </Helmet>
            <p id="dashboard-breadcrumbs">
              <Link to="/mina-sidor/annonser">Mina sidor</Link>&middot;
              <Link to="/mina-sidor/support">Support</Link>
            </p>
            <Support />
          </>
        );
      case "installningar":
        return (
          <>
            <Helmet>
              <title>Inställningar - Vakansa</title>
            </Helmet>
            <p id="dashboard-breadcrumbs">
              <Link to="/mina-sidor/annonser">Mina sidor</Link>&middot;
              <Link to="/mina-sidor/installningar">Inställningar</Link>
            </p>
            <Settings />
          </>
        );
      default:
      // Redirect to 404 page
      // return <Navigate to="/sidan-kunde-hittas" />;
    }
  };

  return (
    <DashboardPage>
      <Sidebar />
      <DashboardContent>{loadContentHandlers()}</DashboardContent>
    </DashboardPage>
  );
}

const DashboardPage = styled.main`
  display: grid;
  grid-template-columns: 1fr 5fr;

  .breadcrumb-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
  }

  #dashboard-breadcrumbs,
  #dashboard-breadcrumbs > a {
    font-size: 1.6rem;
    line-height: 1;
    color: var(--text-dark);
    font-family: var(--montserrat);
    text-decoration: none;
    padding-bottom: var(--spacing-md);
    margin: 0;
  }

  #dashboard-breadcrumbs > a {
    margin: 0.85rem;

    &:first-child {
      margin-left: 0;
    }
  }

  @media only screen and (max-width: 1560px) {
    grid-template-columns: 1fr 8fr;
  }

  @media only screen and (max-width: 1280px) {
    grid-template-columns: auto 5fr;
    /* grid-template-columns: 28rem 1fr; */
  }

  @media only screen and (max-width: 1100px) {
    grid-template-columns: auto 1fr;
  }
  @media only screen and (max-width: 680px) {
    display: block;
  }
`;

const DashboardContent = styled.section`
  min-height: 100vh;
  padding: var(--spacing-xxs) var(--spacing-md) var(--spacing-md);
  grid-column: 2 / -1;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1820px) {
    padding-left: 1rem;
    padding-right: var(--spacing-sm);
  }

  @media only screen and (max-width: 1280px) {
    padding-right: 0;
  }

  @media only screen and (max-width: 980px) {
    padding: var(--spacing-md) var(--spacing-xs);
  }

  @media only screen and (max-width: 680px) {
    margin-left: 0rem;
  }
`;

export default Dashboard;
