// src/utils/urlHelpers.js

export const createSEOUrl = (text) => {
  if (typeof text !== "string") {
    return "";
  }
  return text
    .toLowerCase()
    .replace(/[åä]/g, "a")
    .replace(/ö/g, "o")
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");
};
