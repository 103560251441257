import React from "react";
import "./textButton.css";

export default function TextButton({
  size,
  label,
  customUrl,
  color,
  href,
  onClick
}) {
  return (
    <a
      className={`text-button ${size} ${color}`}
      src={customUrl}
      href={href}
      onClick={onClick}
    >
      {label}
    </a>
  );
}
