import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import RoundedButton from "./Buttons/RoundedButton/RoundedButton";

function ConfirmationModal({
  heading,
  text,
  linkTo = "/",
  linkToAd = "", // /lediga-lokaler/:id
  setShowModal,
  error = false,
  note = "",
}) {
  return error ? (
    <>
      <ConfirmationModalComponent error={error}>
        <h2>{heading}</h2>
        <p>{text}</p>
        <div
          className="confirmation-modal-btn-row"
          onClick={() => setShowModal(false)}
        >
          <RoundedButton 
          label={"Okej"}
          className="confirmation-modal-btn"></RoundedButton>
        </div>
      </ConfirmationModalComponent>
      <ConfirmationModalBackdrop onClick={() => setShowModal(false)} />
    </>
  ) : (
    <>
      <ConfirmationModalComponent>
        <h2>{heading}</h2>
        <p>{text}</p>
        {note && <span>*{note}</span>}
        <div className="confirmation-modal-btn-row">
          {/* Only show "Se annons" link if linkToAd property if passed */}
          {linkToAd && (
            <Link
              to={linkToAd}
              className="confirmation-modal-btn confirmation-modal-btn-white"
            >
              Se annons
            </Link>
          )}
          <Link to={linkTo}
            className="confirmation-modal-btn"
            data-testid="okay-btn">
            {linkToAd ? "Klar" : "Okej"}
          </Link>
        </div>
        <button
          onClick={() => setShowModal(false)}
          className="confirmation-modal-close-btn"

        >
          <img src="/images/icons/close.svg" alt="close-icon-button" />
        </button>
      </ConfirmationModalComponent>
      <ConfirmationModalBackdrop onClick={() => setShowModal(false)} />
    </>
  );
}

const ConfirmationModalComponent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-modal);

  width: min-content;
  padding: var(--spacing-lg);
  background-color: var(--white);
  border-radius: 28px;
  text-align: center;

  h2 {
    font-size: 3.6rem;
    font-weight: 700;
    margin-bottom: 1.75rem;
    white-space: nowrap;
  }

  p {
    font-size: 1.8rem;

    line-height: 25px;
    overflow-wrap: break-word;
    margin-bottom: 1rem;
  }

  span {
    font-size: 1.8rem;
    font-weight: 700;
    overflow-wrap: break-word;
    color: var(--red);
  }

  .confirmation-modal-btn-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }

  .confirmation-modal-btn {
    min-width: 17.5rem;
    padding: 1.75rem 5rem;
    margin: 0 0.7rem;
    font-size: 1.8rem;
    font-weight: 700;
    text-decoration: none;
    border-radius: 29px;
    border: none;
    background-color: var(--green);
    color: var(--white);

    &-white {
      color: var(--text-dark);
      background-color: var(--white);
      box-shadow: var(--shadow-light);
    }
  }

  .confirmation-modal-close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    height: 8rem;
    width: 8rem;
    background: none;
    border: none;
  }

  @media only screen and (max-width:548px) {
    .confirmation-modal-btn {
      padding: 1.60rem 2rem;
  }
}
  @media only screen and (max-width:980px) {
    width:95%;
    padding: var(--spacing-xxl);
    h2 {
      font-size:3rem;
      white-space:normal;
    }
    .confirmation-modal-btn-row {
      display:block;
    }
  }
`;

const ConfirmationModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-backdrop);

  background-color: var(--gray-black);
  opacity: 0.5;
`;

export default ConfirmationModal;
