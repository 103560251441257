import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import AdCard from "./AdCard";
import styled from "styled-components";
import axios from "axios";
import { backend } from "../../../backend";
import { logAxiosError } from "../../../logging/error";
import { useAuth } from "../../../context/AuthContext";

function Favourites() {
  const [ads, setAds] = useState([]);
  const auth = useAuth();

  useEffect(() => {
    const fetchFavourites = async () => {
      try {
        const { data } = await axios.get(backend("/api/favourite/all"), {
          params: {
            token: localStorage.getItem("token"),
          },
        });

        if (data.status) {
          setAds(data.ads);
        }
      } catch (error) {
        logAxiosError(error);
        // TODO: notify user
        if (error.response?.status === 401) {
          // invalid JWT token -> log out and return to landing page
          auth.logout();
        }
      }
    };

    fetchFavourites();
    // eslint-disable-next-line
  }, []);

  return (
    <FavouritesContainer>
      <h2>Favoriter</h2>
      <p>
        På den här sidan listas dina sparade favoriter. Markera favoriter i
        listan för att enkelt anmäla intresse för flera kontor samtidigt.
      </p>
      <div className="dashboard-favourites-list">
        {ads.map((ad, i) => (
          <AdCard key={i} ad={ad} setAds={setAds} favourite />
        ))}
      </div>
    </FavouritesContainer>
  );
}

const FavouritesContainer = styled.div`
  h2 {
    font-size: 4.5rem;
    font-weight: 700;
  }

  p {
    font-size: 2rem;
      
    color: var(--text-light);
  }

  .dashboard-favourites-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, 22.75%);
    grid-auto-rows: 1fr;
    gap: 3.5rem 3%;
    padding-top: 7rem;
 
    @media only screen and (max-width: 1560px) {
     grid-template-columns: repeat(auto-fill, 32%);
       gap: 4rem 2%;
     }

    // @media only screen and (max-width: 980px) {
    //   grid-template-columns: repeat(auto-fill, 48%);
    //   gap: 4rem 4%;
    // }
    @media only screen and (max-width: 1100px) {
      display:flex;
      flex-direction: column;
       & div {
         width: 100%;
       }
       .ad-card-image {
        img {
          min-height:20rem;
          max-height:35rem;
        }
       }
       .ad-card-button-group {
        display: flex;
        align-items: flex-end;
       }
      & > div {
        flex-direction:column;
      }
    }
  }
`;

export default Favourites;
