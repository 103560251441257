import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import axios from "axios";
import { backend } from "../../../../backend";
import { logAxiosError } from "../../../../logging/error";
import { ForgotPasswordComponent } from '../../styles';

function NewPasswordForm({ setServerError }) {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showPasswordLengthError, setShowPasswordLengthError] = useState(false);
  const [showPasswordMatchError, setShowPasswordMatchError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { token } = useParams(); // Token passed with the link in the reset email

  const onSubmitPasswordHandler = async (e) => {
    e.preventDefault();
    setShowPasswordLengthError(false);
    setShowPasswordMatchError(false);

    if (!passwordValidator()) return;

    try {
      const { data } = await axios.post(
        backend("/api/users/account/reset/password"),
        {
          token,
          password,
        }
      );

      if (data.status) {
        setShowSuccessMessage(true);
      }
    } catch (error) {
      logAxiosError(error);
      setServerError(error.response?.data?.msg);
    }

  };

  const passwordValidator = () => {
    if (password.length < 8) {
      setShowPasswordLengthError(true);
      return false;
    } else if (password !== repeatPassword) {
      setShowPasswordMatchError(true);
      return false;
    } else {
      return true;
    }
  };
  return (
    <ForgotPasswordComponent>
      <h1>Välj ett nytt lösenord</h1>
      <form onSubmit={onSubmitPasswordHandler}>
        <label htmlFor="forgot-password-input">
          Lösenord

        </label>
        <input
          type="password"
          id="forgot-password-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label htmlFor="forgot-password-repeat" className='forgot-password-input-container'>
          Upprepa lösenord
          {showPasswordMatchError && (
            <span className="forgot-password-error-msg">
              Lösenorden matchar ej
            </span>
          )}
        </label>
        <input
          type="password"
          id="forgot-password-repeat"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
        />
        {showPasswordLengthError && (
          <span className="forgot-password-error-msg">
            Lösenordet ska bestå av minst 8 tecken
          </span>
        )}
        <div>
          {showSuccessMessage && <p id="forgot-password-confirm">Ditt lösenord har sparats</p>}
          <button id="forgot-password-button">Spara lösenord</button>
        </div>
      </form>
      <p>
        Läs om hur vi hanterar personlig information i våra{" "}
        <a href="/anvandarvillkor" target="_blank" rel="noopener noreferrer">Användarvillkor</a>.
      </p>
    </ForgotPasswordComponent>
  )
}

export default NewPasswordForm