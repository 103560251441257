import React from "react";
import './squareButton.css'

export default function SquareButton({ label, size, onClick, showOrangeBorder }) {
  return (
    <div
      className={`square-button ${size} ${showOrangeBorder ? 'orange-border' : ''}`}
      onClick={onClick}
    >
      <div className="square-button-text">
        {label}
      </div>
    </div>
  )
}