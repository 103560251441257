import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { backend } from "../backend";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  let [isAuth, setIsAuth] = useState(false);
  let [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Check if user is in localStorage and has a valid token on load
  useEffect(() => {
    const isUserValidOnMount = async () => {
      if (localStorage.getItem("token") !== null) {
        try {
          const { data } = await axios.post(backend("/api/users/verify/token"), {
            token: localStorage.getItem("token"),
          });

          if (data.status) {
            const { data } = await axios.get(
              backend("/api/users/account-details"),
              {
                params: {
                  token: localStorage.getItem("token"),
                },
              }
            );

            if (data.status) {
              setUser(data.user);
              setIsAuth(true);
              localStorage.setItem("user", JSON.stringify(data.user));
            }
          }
        } catch (error) {
          // token invalid -> log out and remove token from local storage
          console.error('Token/session no longer valid');
          logout();
        }
      }
    };
    isUserValidOnMount();
    // eslint-disable-next-line
  }, []);

  const login = (token, user, callback) => {
    setIsAuth(true);
    setUser(user);
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
    navigate('/mina-sidor/annonser');

    callback && callback();
  };

  const logout = () => {
    setIsAuth(false);
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate('/');
  };

  const value = { isAuth, user, login, logout, setUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const RequireAuth = ({ children }) => {
  const location = useLocation();

  if (!localStorage.getItem("user")) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/hyr-ut-lokal" state={{ from: location }} replace />;
  }

  return children;
};
