import React from "react";
import RoundedLinkButton from "../../components/Buttons/RoundedLinkButton/RoundedLinkButton";
import "./notFound.css";
import SEOTags from "../../utils/SEOTags";

function NotFound() {
  return (
    <div className="not-found">
      <SEOTags
        title={"Hoppsan! - Vakansa"}
        description={"Sidan du försöker nå verkar inte existera."}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <h1>Hoppsan! Något gick fel...</h1>
      <p>Sidan du försöker nå verkar inte existera.</p>
      <div className="not-found-btns">
        <RoundedLinkButton
          label={"Startsidan"}
          color={"green"}
          size={"lg"}
          href={"/"}
        />
        <RoundedLinkButton
          label={"Lediga lokaler"}
          color={"green"}
          size={"lg"}
          href={"/lediga-lokaler"}
        />
        <RoundedLinkButton
          label={"Kostnadsfria förslag"}
          color={"green"}
          size={"lg"}
          href={"/lokalforslag"}
        />
        <RoundedLinkButton
          label={"Support"}
          color={"white"}
          size={"lg"}
          href={"/kontakta-oss"}
        />
      </div>
    </div>
  );
}

export default NotFound;
