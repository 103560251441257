import React, { useRef } from "react";
import Slider from "react-slick";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./testimonialSection.css";
import testimonial2 from "../../../assets/testimonials/2.webp";
import testimonial3 from "../../../assets/testimonials/3.webp";
import testimonial4 from "../../../assets/testimonials/4.webp";
import testimonial5 from "../../../assets/testimonials/5.webp";
import testimonial6 from "../../../assets/testimonials/6.webp";

export default function TestimonialSection(props) {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonial-section">
      <h3>Vad våra kunder tycker om Vakansa</h3>
      <>
        <div className="testimonial-section">
          <div className="testimonial-slider" style={{ margin: "0 auto" }}>
            <Slider ref={sliderRef} {...settings}>
              <div className="testimonial-img-mobile-holder">
                <img
                  src={testimonial2}
                  alt="Uppsala Danscenter logotyp"
                  width="200"
                  height="90"
                  loading="lazy"
                />
                <p>
                  “Hjälpsamma och trevliga genom hela processen. Bästa av allt
                  är att det kostar inget förrän en deal är klar. Win-win för
                  alla parter!”
                </p>
              </div>
              <div className="testimonial-img-mobile-holder">
                <img
                  src={testimonial3}
                  alt="Fältbiologerna logotyp"
                  width="200"
                  height="90"
                  loading="lazy"
                />
                <p>"Jättefin lokal, billig och nära Nacka naturreservat"</p>
              </div>
              <div className="testimonial-img-mobile-holder">
                <img
                  src={testimonial4}
                  alt="Elevernas riksförbund logotyp"
                  width="200"
                  height="90"
                  loading="lazy"
                />
                <p>
                  “Snabb förmedling och Vakansa hittade en trevlig organisation
                  som vi ville sitta med”
                </p>
              </div>
              <div className="testimonial-img-mobile-holder">
                <img
                  src={testimonial5}
                  alt="OCD förbundet logotyp"
                  width="200"
                  height="90"
                  loading="lazy"
                />
                <p>
                  “Vi är väldigt nöjda med vårt samarbete och med den tjänsten
                  Vakansa erbjuder företag och föreningar för att hålla nere
                  kostnaderna”
                </p>
              </div>
              <div className="testimonial-img-mobile-holder">
                <img
                  src={testimonial6}
                  alt="St Peters Kyrka logotyp"
                  width="200"
                  height="90"
                  loading="lazy"
                />
                <p>
                  "För oss har Vakansa inneburit bättre träffsäkerhet och mer
                  stöd i uthyrningsprocessen vilket passat oss väldigt bra då vi
                  inte är professionella uthyrare."
                </p>
              </div>
            </Slider>
          </div>
          <div className="testimonial-arrows">
            <svg
              fill="currentColor"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              {...props}
              className="testimonial-arrow testimonial-arrow-prev"
              onClick={() => sliderRef?.current?.slickPrev()}
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z"
              />
            </svg>
            <svg
              fill="currentColor"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              {...props}
              className="testimonial-arrow testimonial-arrow-next"
              onClick={() => sliderRef?.current?.slickNext()}
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z"
              />
            </svg>
          </div>
        </div>
      </>
      <div className="need-help-section">
        <h3 className="need-help-header">
          Behöver du hjälp med att hitta din drömlokal?
        </h3>
        <p>
          Kontakta oss på Vakansa så hjälper vi dig kostnadsfritt
          <br />
          att hitta en lokal som passar just era behov.
          <br />
          <br />
          Vi finns här för att svara på dina frågor och
          <br />
          guida dig genom processen!
        </p>
        <RoundedLinkButton
          label={"Skicka in lokalförfrågan"}
          color={"green"}
          size={"lg"}
          href={"/lokalforslag"}
        />
      </div>
    </div>
  );
}
