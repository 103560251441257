import { createContext } from "react";

// Initial reducer state
const initialState = {
  adPreviewInfo: {
    info: {},
    images: [],
    hasChanges: false,
  },
  setAdPreviewInfo: () => {},
};

// Create ad preview context
const AdPreviewContext = createContext(initialState);
export default AdPreviewContext;
