import React, { useRef } from "react";
import { Image, Placeholder, Transformation } from "cloudinary-react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carouselAd.css";

export default function Carousel({ ad, props }) {
  const isMobile = window.innerWidth < 600;

  const isTablet = window.innerWidth < 980;

  const hasImages = ad.images && ad.images.length > 0;

  const sliderRef = useRef(null);

  const settings = {
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
  };

  const hideArrow = {
    display: ad.images.length > 1 ? "block" : "none",
  };

  return (
    <div className="ad-page-image-container">
      {hasImages ? (
        <>
          <Slider ref={sliderRef} {...settings}>
            {ad.images.map((image, i) => (
              <div key={i}>
                <Image
                  alt={
                    ad.type_of_room +
                    " finns att hyra på " +
                    ad.address +
                    " i " +
                    ad.municipality
                  }
                  cloudName="vakansa"
                  publicId={`listings/${ad._id}/${image}`}
                  dpr="auto"
                  height={isMobile ? "400" : isTablet ? "480" : "600"}
                  crop="scale"
                  quality="auto"
                  style={{ margin: "0 auto" }}
                  loading="lazy"
                >
                  <Transformation />
                  <Placeholder type="blur" />
                </Image>
              </div>
            ))}
          </Slider>
          <svg
            fill="currentColor"
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            {...props}
            className="carousel-arrow carousel-arrow-prev"
            onClick={() => sliderRef?.current?.slickPrev()}
            style={hideArrow}
          >
            <path
              fillRule="evenodd"
              d="M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z"
            />
          </svg>
          <svg
            fill="currentColor"
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            {...props}
            className="carousel-arrow carousel-arrow-next"
            onClick={() => sliderRef?.current?.slickNext()}
            style={hideArrow}
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z"
            />
          </svg>
        </>
      ) : (
        <div
          className="adcard-no-image-eye"
          onClick={() => window.open(`/annons/${ad._id}`, "_blank")}
        >
          <svg
            className="slash-eye-icon-ad"
            fill="currentColor"
            viewBox="0 0 16 16"
            {...props}
          >
            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 00-2.79.588l.77.771A5.944 5.944 0 018 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0114.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
            <path d="M11.297 9.176a3.5 3.5 0 00-4.474-4.474l.823.823a2.5 2.5 0 012.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 01-4.474-4.474l.823.823a2.5 2.5 0 002.829 2.829z" />
            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 001.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 018 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884l-12-12 .708-.708 12 12-.708.708z" />
          </svg>
          <span>Bild inte tillgänglig</span>
        </div>
      )}
    </div>
  );
}
