import React, { useEffect } from "react";
import "./faqSection.css";
import question from "../../assets/illustrations/questions-2.png";
import questionPerson from "../../assets/illustrations/question-person.png";
import useCheckIfLarge from "../../customHooks/useCheckIfLarge";
import landlord from "../../assets/illustrations/Search2.png";
import renter from "../../assets/illustrations/Search.png";
import SEOTags from "../../utils/SEOTags";

function FAQ() {
  const isLarge = useCheckIfLarge();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const landlordFAQ = () => {
    window.location.href = "/fragor-och-svar/hyresvard";
  };

  const renterFAQ = () => {
    window.location.href = "/fragor-och-svar/hyresgast";
  };

  const vakansaFAQ = () => {
    window.location.href = "/fragor-och-svar/vad-ar-vakansa";
  };

  return (
    <div className="faq-section-outer">
      <SEOTags
        title={"Frågor och svar - Vakansa"}
        description={"Hur kan vi hjälpa dig?"}
        canonicalUrl={"/fragor-och-svar"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main className="faq-section-background">
        <div className="faq-section">
          <div className="faq-section-top-text">
            <h1>Frågor och svar</h1>
            <h2>Hur kan vi hjälpa dig?</h2>
          </div>
          <div className="faq-section-flex">
            <div className="faq-section-content">
              {isLarge ? (
                <>
                  <div className="faq-section-options" onClick={landlordFAQ}>
                    <img src={landlord} alt="Hus med checkbox" />
                    <p>Hyresvärd</p>
                  </div>
                  <div className="faq-section-options" onClick={renterFAQ}>
                    <img src={renter} alt="Hus med förstorningsglas" />
                    <p>Hyresgäst</p>
                  </div>
                  <div className="faq-section-options" onClick={vakansaFAQ}>
                    <img src={question} alt="Bild på frågetecken" />
                    <p>Vad är vakansa?</p>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="faq-section-options"
                    id="faq-item-1"
                    onClick={vakansaFAQ}
                  >
                    <img src={question} alt="Bild på frågetecken" />
                    <p>Vad är Vakansa?</p>
                  </div>
                  <div className="faq-option-flex">
                    <div className="faq-section-options" onClick={landlordFAQ}>
                      <img src={landlord} alt="Hus med checkbox" />
                      <p>Hyresvärd</p>
                    </div>
                    <div className="faq-section-options" onClick={renterFAQ}>
                      <img src={renter} alt="Hus med förstorningsglas" />
                      <p>Hyresgäst</p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="faq-background-img">
              <img src={questionPerson} alt="" />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default FAQ;
