export function pluralizeRoomType(roomType) {
  switch (roomType) {
    case "Ateljé":
      return "Ateljér";
    case "Behandlingsrum":
      return "Behandlingsrum";
    case "Eventlokal":
      return "Eventlokaler";
    case "Idrott/danslokal":
      return "Idrott och danslokaler";
    case "Klassrum":
      return "Klassrum";
    case "Kontorslokal":
      return "Kontorslokaler";
    case "Kontorshotell/Co-working":
      return "Kontorshotell och Co-Working lokaler";
    case "Matsal/Kök":
      return "Matsalar och kök";
    case "Musiksal":
      return "Musiksalar";
    case "Möteslokal":
      return "Möteslokaler";
    case "Pop-up":
      return "Pop-up lokaler";
    case "Studio":
      return "Studios";
    case "Teaterlokal":
      return "Teaterlokaler";
    case "Övrigt":
      return "Övriga lokaler";
    case "Biograf":
      return "Biografer";
    case "Kyrka":
      return "Kyrkor";
    case "Lager/Logistik":
      return "Lager och Logistik lokaler"; 
    default:
      return roomType;
  }
}
