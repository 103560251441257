import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../../context/AuthContext';
import axios from 'axios';
import { backend } from '../../../backend';
import { logAxiosError } from '../../../logging/error';
import { Link } from 'react-router-dom';

function Support(props) {
  const [message, setMessage] = useState('');
  const [responseObject, setResponseObject] = useState({
    message: '',
    error: false,
  });

  const { user } = useAuth();

  let btnRef = useRef();

  const submitFeedbackHandler = async (e) => {
    e.preventDefault();

    // Disable button when submitting
    btnRef.current.setAttribute('disabled', 'disabled');

    try {
      const { data } = await axios.post(
        backend('/api/contact/general-feedback'),
        {
          name: user?.first_name ? user?.first_name : user?.organization,
          email: user?.email,
          message,
        }
      );

      setMessage(''); // clear the feedback message area upon succesful submit
      setResponseObject({
        message: data.msg,
        error: !data.status, // set error to true, if request fails
      });
    } catch (error) {
      logAxiosError(error);
      setResponseObject({
        message: error.response?.data?.msg,
        error: true,
      });
    }
  };

  const onChangeHandler = (e) => {
    setMessage(e.target.value);
    setResponseObject({});
  };

  return (
    <SupportContainer>
      <h2>Support</h2>
      <p>
        Om du har några problem med tjänsten eller har frågor kan du nå vår
        kundtjänst via:
      </p>
      <div className='contact-info'>
        <p>
          <svg
            viewBox="0 0 512 512"
            fill="currentColor"
            height="1em"
            width="1em"
            {...props}
          >
            <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4l217.6 163.2c11.4 8.5 27 8.5 38.4 0l217.6-163.2c12.1-9.1 19.2-23.3 19.2-38.4 0-26.5-21.5-48-48-48H48zM0 176v208c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64V176L294.4 339.2a63.9 63.9 0 01-76.8 0L0 176z" />
          </svg>

          <a href='mailto:it-support@vakansa.se'>it-support@vakansa.se</a>
        </p>
      </div>
      <div className='support-questions'>
        <h3>Vanliga Frågor</h3>
        <p className='text-muted'>Svaret på vanliga frågor kan du hitta här:</p>
        <div className='support-links'>
          <Link to='/fragor-och-svar/hyresvarden'>Hyresvärden</Link>
          <Link to='/fragor-och-svar/hyresgasten'>Hyresgästen</Link>
        </div>
      </div>
      <div className='support-feedback'>
        <h3>Feedback</h3>
        <p className='text-muted'>
          Varmt välkommen att lämna feedback eller förbättringsförslag nedan.
          Din åsikt är betydelsefull.
        </p>
        <form id='supportFeedbackForm' onSubmit={submitFeedbackHandler}>
          <textarea
            id='supportFeedbackTextarea'
            className='form-control'
            placeholder='Skriv ditt meddelande här'
            rows='6'
            value={message}
            onChange={onChangeHandler}
          />
          <div className='support-response'>
            {responseObject.error ? (
              <p className='support-response-error'>
                {responseObject.message}
                test
              </p>
            ) : responseObject.message ? (
              <p className='support-response-success'>
                {responseObject.message}
              </p>
            ) : (
              <p>&nbsp;</p> // Placeholder for spacing
            )}
          </div>
          <button ref={btnRef} disabled={!message}>
            Skicka
          </button>
        </form>
      </div>
    </SupportContainer>
  );
}

const SupportContainer = styled.div`
  h2 {
    font-size: 4.5rem;
    font-weight: 700;
  }

  h3 {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  p {
    font-size: 2rem;
      
  }

  & > p {
    color: var(--text-light);
  }

  .contact-info {
    margin: 7rem 2.25rem 10rem;

    & p {
      display: flex;
      align-items: center;
      margin-bottom: 2.4rem;
    }

    & svg {
      font-size: 2.5rem;
      margin-right: 1.5rem;
    }
  }

  #supportFeedbackTextarea {
    width: 100vh;
  }

  .support-questions {
    margin-bottom: 15rem;

    .support-links {
      margin-top: 2.8rem;
    }

    a {
      padding: 1rem 2rem;
      margin-right: 2.4rem;
      font-size: 2.2rem;
      text-decoration: none;
      color: var(--text-black);
      border-radius: 50px;
      border: none;
      background-color: white;
      box-shadow: 0px 10px 24px #0000002e;
    }

    a:hover {
      background-color: var(--green);
      color: var(--white);
    }
  }

  .support-feedback {
    label {
      font-size: 1.8rem;
        
      margin: 3rem 1rem 1rem;
    }

    textarea {
      font-size: 1.6rem;
      border-radius: 23px;
      max-width: 82.25rem;
      padding: 1.5rem 2.3rem;
    }

    .support-response {
      p {
        font-size: 1.8rem;
          
        margin: 1.5rem 0.5rem;
      }

      &-success {
        color: var(--green);
      }

      &-error {
        color: var(--red);
      }
    }

    button {
      width: 16.25rem;
      height: 5rem;
      font-size: 2.2rem;
      border-radius: 50px;
      border: 1px solid var(--green);
      background-color: white;
      box-shadow: 0px 10px 24px #0000002e;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 980px) {
    .support-links {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 10px;
      & a {
        border-radius: 12px;
        padding: 2rem;
        width: 100%;
        border: 1px solid #707070;
      }
    }
    #supportFeedbackTextarea {
      width: 100%;
    }
    .support-feedback {
      & button {
        width: 100%;
        border-radius: 12px;
        padding: 2rem;
        height: auto;
        background-color: var(--green);
        color: white;
        border: none;
      }
    }
  }
`;

export default Support;
