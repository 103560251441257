import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import TimeslotInput from "./TimeslotInput";

const StyledTabs = styled(Tabs)({
  display: "flex",
  width: "100%",
  flexWrap: "wrap",
  marginTop: "1rem",
  borderBottom: "1px solid #bbb",
  "& .MuiTabs-indicator": {
    backgroundColor: "var(--green)",

  },
});

const StyledTab = styled(Tab)({
  textTransform: "none",
  fontSize: "1.6rem",
  color: "var(--text-dark) !important",
});

const TabPanel = ({ children, value, panel }) => {
  return (
    <div role="tabpanel" hidden={value !== panel}>
      {children}
    </div>
  );
};

function Timeslots({ formData: { timeslots }, setFormData }) {
  const [value, setValue] = useState("monday");

  const setTimeslots = (day, newTimeslots) => {
    setFormData((prevData) => ({
      ...prevData,
      timeslots: {
        ...prevData.timeslots,
        [day]: newTimeslots,
      },
    }));
  };

  const removeTimeslots = (day, slotIndex) => {
    setFormData((prevData) => {
      const updatedSlots = prevData.timeslots[day].filter(
        (slot, index) => index !== slotIndex
      );

      return {
        ...prevData,
        timeslots: {
          ...prevData.timeslots,
          [day]: updatedSlots,
        },
      };
    });
  };

  return (
    <>
      <StyledTabs value={value} onChange={(e, newValue) => setValue(newValue)}>
        <StyledTab value="monday" label="Måndag" disableRipple />
        <StyledTab value="tuesday" label="Tisdag" disableRipple />
        <StyledTab value="wednesday" label="Onsdag" disableRipple />
        <StyledTab value="thursday" label="Torsdag" disableRipple />
        <StyledTab value="friday" label="Fredag" disableRipple />
        <StyledTab value="saturday" label="Lördag" disableRipple />
        <StyledTab value="sunday" label="Söndag" disableRipple />
      </StyledTabs>
      <TabPanel value={value} panel="monday">
        <TimeslotInput
          day="monday"
          timeslots={timeslots.monday}
          setTimeslots={setTimeslots}
          removeTimeslots={removeTimeslots}
        />
      </TabPanel>
      <TabPanel value={value} panel="tuesday">
        <TimeslotInput
          day="tuesday"
          timeslots={timeslots.tuesday}
          setTimeslots={setTimeslots}
          removeTimeslots={removeTimeslots}
        />
      </TabPanel>
      <TabPanel value={value} panel="wednesday">
        <TimeslotInput
          day="wednesday"
          timeslots={timeslots.wednesday}
          setTimeslots={setTimeslots}
          removeTimeslots={removeTimeslots}
        />
      </TabPanel>
      <TabPanel value={value} panel="thursday">
        <TimeslotInput
          day="thursday"
          timeslots={timeslots.thursday}
          setTimeslots={setTimeslots}
          removeTimeslots={removeTimeslots}
        />
      </TabPanel>
      <TabPanel value={value} panel="friday">
        <TimeslotInput
          day="friday"
          timeslots={timeslots.friday}
          setTimeslots={setTimeslots}
          removeTimeslots={removeTimeslots}
        />
      </TabPanel>
      <TabPanel value={value} panel="saturday">
        <TimeslotInput
          day="saturday"
          timeslots={timeslots.saturday}
          setTimeslots={setTimeslots}
          removeTimeslots={removeTimeslots}
        />
      </TabPanel>
      <TabPanel value={value} panel="sunday">
        <TimeslotInput
          day="sunday"
          timeslots={timeslots.sunday}
          setTimeslots={setTimeslots}
          removeTimeslots={removeTimeslots}
        />
      </TabPanel>
    </>
  );
}

export default Timeslots;
