import React, { useState, useEffect } from 'react';
import RoundedButton from '../../../../components/Buttons/RoundedButton/RoundedButton';
import { useForm } from "react-hook-form";
import axios from "axios";
import { backend } from "../../../../backend";
import { useAuth } from "../../../../context/AuthContext";
import { logAxiosError } from "../../../../logging/error";
import "./feedbackModal.css";

export default function FeedbackModal(props) {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [formError, setFormError] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [ads, setAds] = useState([]);
  const { register, handleSubmit, reset } = useForm();
  const auth = useAuth();
  const userEmail = auth.user?.email;
  const firstname = auth.user?.first_name;
  const lastname = auth.user?.last_name;

  useEffect(() => {
    const fetchMyAds = async () => {
      try {
        const { data } = await axios.get(
          backend("/api/listings/user/all-listings"),
          {
            params: {
              token: localStorage.getItem("token"),
            },
          }
        );
        if (data.status) {
          setAds(data.ads);
        } else {
          setAds([]);
        }
      } catch (error) {
        logAxiosError(error);
        setAds([]);
        if (error.response?.status === 401) {
          auth.logout();
        }
      }
    };
    fetchMyAds();
    //eslint-disable-next-line
  }, []);


  const onSubmit = async (formData) => {
    const hasCheckedCheckbox = Object.values(formData).some((value) => value);

    if (hasCheckedCheckbox) {
      try {
        await handleFormSubmit(formData);
        setSubmissionStatus("success");
        reset(); // Reset the form fields
      } catch (error) {
        console.error(error);
        setSubmissionStatus("error");
      }
    } else {
      setFormError("* Vänligen fyll i åtminstone ett alternativ.");
    }
  };

  const handleFormSubmit = async (formData) => {
    await axios.post(backend("/api/contact/rent/feedback"), formData);
  };

  return (
    <div>
      <button
        className='feedback-modal-button'
        onClick={() => setShowFeedbackModal(true)}
      >Skicka uthyrningsinfo</button>
      {showFeedbackModal && (
        <>
          <div className='feedback-modal-outer'>
            <div>
              {submissionStatus === "success" ? (
                <>
                  <h1>Tack för din feedback!</h1>
                  <br />
                  <div className="warning-modal-btn-row">
                    <RoundedButton
                      label={"Stäng"}
                      color={"green"}
                      size={"sm"}
                      onClick={() => {
                        setShowFeedbackModal(false);
                        setSubmissionStatus("");
                      }}
                    />
                  </div>
                </>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='feedback-select-wrapper'>
                    <select {...register("address")}>
                      <option value="Ingen annons">Ingen annons</option>
                      {ads.map((ad) => (
                        <option key={ad._id} value={`${ad._id},${ad.address}`}>{ad.address}</option>
                      ))}
                    </select>
                    <img src="/images/icons/arrow-down.svg" alt="" />
                  </div>
                  <br />
                  <br />
                  <input
                    className="email-form-send"
                    value={userEmail}
                    {...register("userEmail")}
                  />
                  <input
                    className="email-form-send"
                    value={firstname + " " + lastname}
                    {...register("fullName")}
                  />
                  <div className="sorry-form-checkbox-group">
                    <label className="sorry-form-checkbox-button">
                      <input
                        type="checkbox"
                        value="Vi har lyckats hyra ut lokalen"
                        {...register("reasonsArray")}
                      />
                      <span className="checkmark"></span>
                      <p>Vi har lyckats hyra ut lokalen</p>
                    </label>

                    <label className="sorry-form-checkbox-button">
                      <input
                        type="checkbox"
                        value="Vi har sagt upp kontraktet för lokalen"
                        {...register("reasonsArray")}
                      />
                      <span className="checkmark"></span>
                      <p>Vi har sagt upp kontraktet för lokalen</p>
                    </label>

                    <label className="sorry-form-checkbox-button">
                      <input
                        type="checkbox"
                        value="Vi söker större lokaler"
                        {...register("reasonsArray")}
                      />
                      <span className="checkmark"></span>
                      <p>Vi söker större lokaler</p>
                    </label>

                    <label className="sorry-form-checkbox-button">
                      <input
                        type="checkbox"
                        value="Vi söker mindre lokaler"
                        {...register("reasonsArray")}
                      />
                      <span className="checkmark"></span>
                      <p>Vi söker mindre lokaler</p>
                    </label>

                    <label className="sorry-form-checkbox-button">
                      <input
                        type="checkbox"
                        value="Vi har gått i konkurs"
                        {...register("reasonsArray")}
                      />
                      <span className="checkmark"></span>
                      <p>Vi har gått i konkurs</p>
                    </label>

                    <label className="sorry-form-checkbox-button">
                      <input
                        type="checkbox"
                        value="Andrahandsuthyrning är inte aktuellt längre"
                        {...register("reasonsArray")}
                      />
                      <span className="checkmark"></span>
                      <p>Andrahandsuthyrning är inte aktuellt</p>
                    </label>

                    <label className="sorry-form-checkbox-button">
                      <input
                        type="checkbox"
                        value="Andra skäl"
                        {...register("reasonsMessage")}
                      />
                      <span className="checkmark"></span>
                      <p>Andra skäl - beskriv här under</p>
                    </label>
                  </div>
                  {formError && (
                    <span className="error-message">{formError}</span>
                  )}
                  <div className="sorry-form-custom-message">
                    <label>
                      <textarea
                        name="description"
                        className="form-control"
                        placeholder="Skriv ditt meddelande här..."
                        {...register("message")}
                      />
                    </label>
                  </div>
                  <div className='sorry-form-button'>
                    <RoundedButton
                      type="submit"
                      className="sorry-form-send-button"
                      color="green"
                      label="Skicka"
                      size="lg"
                    />
                  </div>
                  <br />
                  <div className="warning-modal-btn-row">
                    <RoundedButton
                      label={"Avbryt"}
                      color={"red"}
                      size={"lg"}
                      onClick={() => setShowFeedbackModal(false)}
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
          <div className='feedback-modal-backdrop' onClick={() => setShowFeedbackModal(false)} />
        </>
      )}
    </div>
  );
}
