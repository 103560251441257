import React, { useEffect } from "react";
import "./landlord.css";
import { useAuth } from "../../../context/AuthContext";
import visionImg from "../../../assets/illustrations/VISION.png";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";

export default function Landlord() {
  const auth = useAuth();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div className="rent-out-space-landlord-section">
      <div className="rent-out-space-landlord-info">
        <h1 id="rent-out-space-page-title">Hyr ut lokal</h1>
        <h2>Marknadsplatsen för uthyrning i andrahand och förstahand</h2>
        <p>
          Söker du en enkel och flexibel lösning för att hyra ut dina lediga
          lokaler i andrahand? Då har du kommit rätt!
        </p>
        <br />
        <p>
          På Vakansa erbjuder vi en mångsidig plattform där du kan anpassa din
          uthyrning efter dina önskemål, oavsett om du föredrar korttids- eller
          långtidsuthyrning.
        </p>
        <br />
        <p>
          Du kan helt enkelt bestämma om du vill hyra ut några timmar, dagar,
          eller mer långvariga så som veckor, månader eller flera år!
        </p>
        <br />
        <br />
        <RoundedLinkButton
          data-testid="landlord-register-btn"
          label={!auth.user ? "Registrera ett konto här" : "Skapa annons"}
          href={!auth.user ? "/registrera-konto" : "/mina-sidor/skapa-annons"}
        ></RoundedLinkButton>
      </div>
      <img
        id="how-it-works-landlord-image"
        src={visionImg}
        alt="Neighbourhood with tall buildings, people and trees"
      />
      <div className="rent-out-space-landlord-steps">
        <div>
          <div>
            <span>1</span>
          </div>
          <b>Du skapar en annons</b>
          <p>Skapa ett konto och lägg upp en annons på din lokal</p>
        </div>
        <div>
          <div>
            <span>2</span>
          </div>
          <b>Vi matchar dig</b>
          <p>Specifiera vilka tider lokalerna kan hyras ut</p>
        </div>
        <div>
          <div>
            <span>3</span>
          </div>
          <b>Avtalsskrivning</b>
          <p>Träffa hyresgäster och skriv på hyreskontrakt</p>
        </div>
      </div>
    </div>
  );
}
