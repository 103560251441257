import React from "react";
import "./heading.css";

function Heading({ ad }) {
  return (
    <div className="heading-section">
      <h1>
        {ad.type_of_room} finns att hyra på {ad.address} i {ad.municipality}
      </h1>
    </div>
  );
}

export default Heading;
