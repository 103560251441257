import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { useParams } from "react-router-dom";
import { backend } from "../backend";
import { logAxiosError } from "../logging/error";
import RoundedButton from "./Buttons/RoundedButton/RoundedButton";

function VerifyUserModal({ setShowModal }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("Verifierar...");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { userId } = useParams();
  const auth = useAuth();

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const { data } = await axios.get(backend("/api/users/account/activate"), {
          params: { user_id: userId }, // only time user_id is sent in request (else embedded in JWT token)
        });

        if (data.status) {
          setSuccessMessage(
            "Ditt konto är nu aktiverat, du kan nu logga in och börja använda tjänsten!"
          );
        }
      } catch (error) {
        logAxiosError(error);
        setSuccessMessage("");
        setErrorMessage(
          "Kunde inte verifiera din e-postadress, försök klicka länken i aktiveringssmejlet en gång till."
        );
      }
    };

    setTimeout(verifyUser, 500);
  }, [userId]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const { data } = await axios.post(backend("/api/users/login"), {
        email: email.toLowerCase(),
        password,
      });

      if (data.status) {
        setSuccessMessage(data.msg);
        auth.login(data.token, data.user);
        setTimeout(onCloseHandler, 500);
      }
    } catch (error) {
      logAxiosError(error);
      setErrorMessage(error.response?.data?.msg);
    }
  };

  const onCloseHandler = () => {
    setEmail("");
    setPassword("");
    setSuccessMessage("");
    setErrorMessage("");
    setShowModal(false);
  };

  return (
    <>
      <VerifyUserModalComponent>
        <h1>Välkommen till Vakansa</h1>
        {successMessage && <p id="verify-modal-success">{successMessage}</p>}
        {errorMessage && <p id="verify-modal-error">{errorMessage}</p>}
        <form onSubmit={onSubmitHandler}>
          <label htmlFor="verify-modal-email">E-postadress</label>
          <input
            type="text"
            id="verify-modal-email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="verify-modal-password">Lösenord</label>
          <div className="verify-form-show-password-div">
            <input
              type={showPassword ? "text" : "password"}
              id="verify-modal-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img className="login-form-show-password" onClick={() => setShowPassword(!showPassword)} src={`/images/icons/eye_${showPassword ? "visible" : "hidden"}.svg`} alt="Show password" />
          </div>
          <div id="verify-modal-forgot-password">
            <a href="/glomt-losenord">Glömt ditt lösenord?</a>
          </div>
          <div id="verify-modal-form-button">
            <RoundedButton label={"Logga in"}></RoundedButton>
          </div>
        </form>
        <p>
          Läs om hur vi hanterar personlig information i våra{" "}
          <a href="/anvandarvillkor" target="_blank" rel="noopener noreferrer">Användarvillkor</a>.
        </p>
        <button id="verify-modal-close-button" onClick={onCloseHandler}>
          <img src="/images/icons/close.svg" alt="close-icon-button" />
        </button>
      </VerifyUserModalComponent>
      <VerifyUserModalBackdrop onClick={onCloseHandler} />
    </>
  );
}

const VerifyUserModalComponent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-modal);
 
  max-width: 72.5rem;
  /* padding: 11.25rem 16.5rem 8.25rem; */
  padding: var(--spacing-lg) var(--spacing-xl) var(--spacing-md);
  background-color: var(--white);
  border-radius: 28px;

  h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: center;
  }

  & > p {
    font-size: 1.7rem;
    margin-bottom: 3.2rem;

    a {
      color: var(--green);
    }
  }

  #verify-modal-success {
    font-size: 2rem;
    text-align: center;
    color: var(--green);
  }

  #verify-modal-error {
    font-size: 2rem;
    text-align: center;
    color: var(--red);
  }
  .verify-form-password-div {
  position: relative;
  }
  .verify-form-show-password {
    position: absolute;
    top:50%;
    right:10%;
  }
.verify-form-show-password-div {
  position: relative;
}
 .login-form-show-password {
  position: absolute;
  width: 30px;
  top: 50%;
  right: 10px; /* Change the value as per your requirement */
  transform: translateY(-50%);
  cursor: pointer;
}

  form {
    label {
      font-size: 2rem;
      margin-bottom: 0.5rem;
      margin-left: 0.5rem;
    }

    input {
      display: block;
      width: 100%;
      height: 5rem;
      font-size: 1.6rem;
      padding: 0 2.5rem;
      border: 1px solid #c6cbcf;
      border-radius: 50px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    #verify-modal-forgot-password {
      width: 100%;
      margin: 1rem 0 4rem;
      text-align: right;

      a {
        font-size: 1.5rem;
        color: var(--text-black);
        margin-right: 1rem;
      }
    }
  }

  #verify-modal-form-button {
    margin-bottom: 4rem;

    button {
      display: block;
      margin: 0 auto;
      width: 19rem;
      height: 5rem;
      font-size: 2.2rem;
      font-weight: 700;
      color: var(--white);
      border-radius: 50px;
      background-color: var(--green);
      box-shadow: var(--shadow-light);
      border: none;
    }
  }

  #verify-modal-close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    height: 8rem;
    width: 8rem;
    background: none;
    border: none;
  }

`;

const VerifyUserModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-backdrop);

  background-color: var(--gray-black);
  opacity: 0.5;
`;

export default VerifyUserModal;
