import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { logAxiosError } from "../../logging/error";
import { backend } from "../../backend/index";
import TextButton from "../../components/Buttons/TextButton/TextButton";
import SearchInput from "./SearchInput/SearchInput";
import FilterDropdownButton from "../../components/Buttons/FilterDropdownButton/FilterDropdownButton";
import AdCard from "./AdCard/AdCard";
import InterestCard from "./InterestCard/InterestCard";
import SpaceDropdown from "./Dropdowns/SpaceDropdown/SpaceDropdown";
import MunicipalityDropdown from "./Dropdowns/MunicipalityDropdown/MunicipalityDropdown";
import TimeDropdown from "./Dropdowns/TimeDropdown/TimeDropdown";
import PriceDropdown from "./Dropdowns/PriceDropdown/PriceDropdown";
import AreaDropdown from "./Dropdowns/AreaDropdown/AreaDropdown";
import MomsDropdown from "./Dropdowns/MomsDropdown/MomsDropdown";
import "./adPage.css";
import NoResults from "./NoAdResults/NoAdResults";
import MapSection from "./MapSection/MapSection";
import InterestModal from "../../components/InterestModal/InterestModal";
import { pluralizeRoomType } from "../../utils/RoomtypeHelper";
import SEOTags from "../../utils/SEOTags";

const AdPage = ({ props }) => {
  const { page } = useParams();
  const [showMap, setShowMap] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(parseInt(page) || 1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [id, setId] = useState([]);
  const [isSpaceDropdownVisible, setIsSpaceDropdownVisible] = useState(false);
  const [isMunicipalityDropdownVisible, setIsMunicipalityDropdownVisible] =
    useState(false);
  const [isTimeDropdownVisible, setIsTimeDropdownVisible] = useState(false);
  const [isPriceDropdownVisible, setIsPriceDropdownVisible] = useState(false);
  const [isAreaDropdownVisible, setIsAreaDropdownVisible] = useState(false);
  const [isMomsDropdownVisible, setIsMomsDropdownVisible] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("natural");
  const location = useLocation();

  const closeDropdowns = () => {
    setIsSpaceDropdownVisible(false);
    setIsMunicipalityDropdownVisible(false);
    setIsTimeDropdownVisible(false);
    setIsPriceDropdownVisible(false);
    setIsAreaDropdownVisible(false);
    setIsMomsDropdownVisible(false);
  };

  const toggleSpaceDropdown = () => {
    if (!isSpaceDropdownVisible) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    closeDropdowns();
    setIsSpaceDropdownVisible(!isSpaceDropdownVisible);
  };

  const toggleMunicipalityDropdown = () => {
    if (!isMunicipalityDropdownVisible) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    closeDropdowns();
    setIsMunicipalityDropdownVisible(!isMunicipalityDropdownVisible);
  };
  const closeMunicipalityDropdown = () => {
    setIsMunicipalityDropdownVisible(false);
  };

  const closeSpaceDropdown = () => {
    setIsSpaceDropdownVisible(false);
  };

  const toggleTimeDropdown = () => {
    if (!isTimeDropdownVisible) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    closeDropdowns();
    setIsTimeDropdownVisible(!isTimeDropdownVisible);
  };

  const closeTimeDropdown = () => {
    setIsTimeDropdownVisible(false);
  };

  const togglePriceDropdown = () => {
    if (!isPriceDropdownVisible) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    closeDropdowns();
    setIsPriceDropdownVisible(!isPriceDropdownVisible);
  };
  const closePriceDropdown = () => {
    setIsPriceDropdownVisible(false);
  };

  const toggleAreaDropdown = () => {
    if (!isAreaDropdownVisible) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    closeDropdowns();
    setIsAreaDropdownVisible(!isAreaDropdownVisible);
  };
  const closeAreaDropdown = () => {
    setIsAreaDropdownVisible(false);
  };

  const toggleMomsDropdown = () => {
    if (!isMomsDropdownVisible) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    closeDropdowns();
    setIsMomsDropdownVisible(!isMomsDropdownVisible);
  };

  const closeMomsDropdown = () => {
    setIsMomsDropdownVisible(false);
  };

  const handleOutsideClick = (event) => {
    const clickedOutsideDropdown = dropdowns.every((dropdown) => {
      return (
        !event.target.closest(dropdown.className) &&
        !event.target.closest(".filter-dropdown-button")
      );
    });

    if (clickedOutsideDropdown) {
      dropdowns.forEach((dropdown) => {
        dropdown.closeFunction();
      });
    }
  };

  const dropdowns = [
    {
      className: ".space-dropdown",
      state: isSpaceDropdownVisible,
      closeFunction: closeSpaceDropdown,
    },
    {
      className: ".municipality-dropdown",
      state: isMunicipalityDropdownVisible,
      closeFunction: closeMunicipalityDropdown,
    },
    {
      className: ".time-dropdown",
      state: isTimeDropdownVisible,
      closeFunction: closeTimeDropdown,
    },
    {
      className: ".price-dropdown",
      state: isPriceDropdownVisible,
      closeFunction: closePriceDropdown,
    },
    {
      className: ".area-dropdown",
      state: isAreaDropdownVisible,
      closeFunction: closeAreaDropdown,
    },
    {
      className: ".moms-dropdown",
      state: isMomsDropdownVisible,
      closeFunction: closeMomsDropdown,
    },
  ];

  const adsPerPage = 40;

  const getStoredFilters = (filterType) => {
    const storedFilters = sessionStorage.getItem(filterType);
    return storedFilters ? JSON.parse(storedFilters) : [];
  };

  const [selectedTime, setSelectedTime] = useState(
    getStoredFilters("selectedTime")
  );
  const [selectedRoomTypes, setSelectedRoomTypes] = useState(
    getStoredFilters("selectedRoomTypes")
  );
  const [selectedMunicipality, setSelectedMunicipality] = useState(
    getStoredFilters("selectedMunicipality")
  );
  const [selectedMoms, setSelectedMoms] = useState(
    getStoredFilters("selectedMoms")
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPriceRange, setSelectedPriceRange] = useState({
    min: 0,
    max: 50000,
  });
  const [selectedAreaRange, setSelectedAreaRange] = useState({
    min: 0,
    max: 2000,
  });
  // eslint-disable-next-line no-unused-vars
  const [displayedPageRange, setDisplayedPageRange] = useState([1, 5]);
  // eslint-disable-next-line no-unused-vars
  const [activeFilters, setActiveFilters] = useState({
    lokaler: false,
    kommuner: false,
    tider: false,
    pris: false,
    yta: false,
  });

  const startIndex = (currentPage - 1) * adsPerPage;
  const endIndex = startIndex + adsPerPage;

  const filteredData = id
    .filter(
      (ad) =>
        ad.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ad.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ad.type_of_room.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ad.municipality.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(
      (ad) =>
        selectedRoomTypes.length === 0 ||
        selectedRoomTypes.includes(ad.type_of_room)
    )
    .filter(
      (ad) =>
        selectedMunicipality.length === 0 ||
        selectedMunicipality.includes(ad.municipality)
    )
    .filter((ad) => {
      if (selectedTime.length === 0) {
        return true;
      } else {
        return (
          (selectedTime.includes("Timvis") && ad.hourly_rent > 0) ||
          (selectedTime.includes("Dagsvis") && ad.daily_rent > 0) ||
          (selectedTime.includes("Månadsvis") && ad.monthly_rent > 0) ||
          (selectedTime.includes("Per arbetsplats") && ad.workspace_rent > 0)
        );
      }
    })
    .filter((ad) => {
      const withinPriceRange = (price) =>
        price >= selectedPriceRange.min &&
        (selectedPriceRange.max === 50000 || price <= selectedPriceRange.max);

      return (
        withinPriceRange(ad.hourly_rent) ||
        ad.hourly_rent === 0 ||
        withinPriceRange(ad.daily_rent) ||
        ad.daily_rent === 0 ||
        withinPriceRange(ad.workspace_rent) ||
        ad.workspace_rent === 0 ||
        withinPriceRange(ad.monthly_rent) ||
        ad.monthly_rent === 0
      );
    })

    .filter(
      (ad) =>
        ad.area >= selectedAreaRange.min &&
        (selectedAreaRange.max === 2000 || ad.area <= selectedAreaRange.max)
    )
    .filter(
      (ad) =>
        selectedMoms.length === 0 || selectedMoms.includes(ad.vat_tax_required)
    );

  const sortDataByArea = (data, order) => {
    const sortedData = [...data];
    if (order === "smallToBig") {
      sortedData.sort((a, b) => a.area - b.area);
    } else if (order === "bigToSmall") {
      sortedData.sort((a, b) => b.area - a.area);
    }
    return sortedData;
  };

  const sortDataByPrice = (data, order) => {
    const sortedData = [...data];
    if (order === "priceSmallToBig") {
      sortedData.sort((a, b) => a.monthly_rent - b.monthly_rent);
    } else if (order === "priceBigToSmall") {
      sortedData.sort((a, b) => b.monthly_rent - a.monthly_rent);
    }
    return sortedData;
  };

  const handleSortingChange = (event) => {
    setSortingOrder(event.target.value);
  };

  let sortedData;
  if (sortingOrder.includes("price")) {
    sortedData = sortDataByPrice(filteredData, sortingOrder);
  } else {
    sortedData = sortDataByArea(filteredData, sortingOrder);
  }

  const totalFilteredAds = sortedData.length;

  const totalPages = Math.ceil(totalFilteredAds / adsPerPage);

  const adsForCurrentPage = sortedData.slice(startIndex, endIndex);

  const renderedAds = adsForCurrentPage.flatMap((item, index) => [
    <li key={item._id}>
      <AdCard id={item} />
    </li>,
    (index + 1) % 20 === 0 && index + 1 !== adsForCurrentPage.length ? (
      <li key={`interestCard${index}`}>
        <InterestCard />
      </li>
    ) : null,
  ]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      window.scrollTo(0, 0);
      setCurrentPage(newPage);
      const params = new URLSearchParams(window.location.search);
      params.set("page", newPage);
      window.history.pushState({}, "", `${window.location.pathname}?${params}`);
    }
  };

  useEffect(() => {
    setCurrentPage(parseInt(page) || 1);
    setDisplayedPageRange(calculatePageRange());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const calculatePageRange = () => {
    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let start = Math.max(currentPage - halfMaxPagesToShow, 1);
    let end = Math.min(start + maxPagesToShow - 1, totalPages);

    if (end - start < maxPagesToShow - 1) {
      start = Math.max(end - maxPagesToShow + 1, 1);
    }

    return [start, end];
  };

  useEffect(() => {
    setDisplayedPageRange(calculatePageRange());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, totalPages]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  // Update the `useEffect` hooks for the filters
  useEffect(() => {
    if (selectedRoomTypes.length > 0) {
      setCurrentPage(1);
    }
    setActiveFilters((prevState) => ({
      ...prevState,
      lokaler: selectedRoomTypes.length > 0,
    }));
  }, [selectedRoomTypes]);

  useEffect(() => {
    if (selectedMunicipality > 0) {
      setCurrentPage(1);
    }
    setActiveFilters((prevState) => ({
      ...prevState,
      kommuner: selectedMunicipality.length < 0,
    }));
  }, [selectedMunicipality]);

  useEffect(() => {
    if (selectedTime.length > 0) {
      setCurrentPage(1);
    }
    setActiveFilters((prevState) => ({
      ...prevState,
      tider: selectedTime.length > 0,
    }));
  }, [selectedTime]);

  useEffect(() => {
    if (selectedPriceRange.length > 0) {
      setCurrentPage(1);
    }
    setActiveFilters((prevState) => ({
      ...prevState,
      pris: selectedPriceRange.min !== 0 || selectedPriceRange.max !== 50000,
    }));
  }, [selectedPriceRange]);

  useEffect(() => {
    if (selectedAreaRange.length > 0) {
      setCurrentPage(1);
    }
    setActiveFilters((prevState) => ({
      ...prevState,
      yta: selectedAreaRange.min !== 0 || selectedAreaRange.max !== 2000,
    }));
  }, [selectedAreaRange]);

  useEffect(() => {
    if (selectedMoms.length > 0) {
      setCurrentPage(1);
    }
    setActiveFilters((prevState) => ({
      ...prevState,
      moms: selectedMoms.length > 0,
    }));
  }, [selectedMoms]);

  const clearSessionStorage = () => {
    sessionStorage.clear();
    setSelectedPriceRange({ min: 0, max: 50000 });
    setSelectedAreaRange({ min: 0, max: 2000 });
    setSelectedTime(getStoredFilters("selectedTime"));
    setSelectedRoomTypes(getStoredFilters("selectedRoomTypes"));
    setSelectedMunicipality(getStoredFilters("selectedMunicipality"));
    setSelectedMoms(getStoredFilters("selectedMoms"));
    setSearchTerm("");
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setShowMap(windowWidth < 800 ? false : true);
  }, [windowWidth]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" }); // scrolls to top on first render
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const page = parseInt(params.get("sida")) || 1;
    setCurrentPage(page);

    const roomTypes = params.get("lokaltyp");
    if (roomTypes) {
      setSelectedRoomTypes(roomTypes.split(","));
    } else {
      setSelectedRoomTypes([]);
    }

    const municipalities = params.get("kommun");
    if (municipalities) {
      setSelectedMunicipality(municipalities.split(","));
    } else {
      setSelectedMunicipality([]);
    }
  }, [location.search]);

  useEffect(() => {
    setPageNumber(parseInt(page) || 1);
  }, [page]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set("sida", currentPage);
    if (selectedRoomTypes.length > 0) {
      params.set("lokaltyp", selectedRoomTypes.join(","));
    } else {
      params.delete("lokaltyp");
    }
    if (selectedMunicipality.length > 0) {
      params.set("kommun", selectedMunicipality.join(","));
    } else {
      params.delete("kommun");
    }
    window.history.pushState({}, "", `${window.location.pathname}?${params}`);
  }, [currentPage, selectedRoomTypes, selectedMunicipality, location.search]);

  const fetchFilteredAds = async (filterParams) => {
    try {
      const { data } = await axios.get(backend("/api/listings/filter"), {
        params: filterParams,
      });
      return { ads: data.ads };
    } catch (error) {
      logAxiosError(error);
      throw error;
    }
  };

  useEffect(() => {
    const filterParams = {};
    fetchFilteredAds(filterParams)
      .then((result) => {
        setId(result.ads);
      })
      .catch((error) => {
        console.error("Error fetching ads:", error);
      });
  }, []);

  const title = `(${filteredData.length}) Lediga ${
    selectedRoomTypes.length > 1
      ? "lokaler"
      : selectedRoomTypes.length === 1
      ? pluralizeRoomType(selectedRoomTypes[0])
      : "lokaler"
  } ${
    selectedMunicipality.length > 1
      ? ""
      : selectedMunicipality.length === 1
      ? `i ${selectedMunicipality[0]}`
      : ""
  }`;

  const url = backend(`/lediga-lokaler`);

  return (
    <>
      <SEOTags
        title={title}
        description={
          "Vakansa har ett brett utbud av alla möjliga lokaler som kan hyras i andrahand per timme, dag, vecka, månad och helst i flera år."
        }
        canonicalUrl={url}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main
        className={`outer-wrapper-ad-section ${
          showMap ? "two-column" : "single-column"
        }`}
      >
        <div className="ad-section-container">
          <div className="ad-section-top-wrapper">
            <div className="ad-section-head-text">
              <h1>
                Lediga{" "}
                {selectedRoomTypes.length > 1
                  ? "lokaler"
                  : selectedRoomTypes.length === 1
                  ? pluralizeRoomType(selectedRoomTypes[0])
                  : "lokaler"}
                {selectedMunicipality.length > 1
                  ? ""
                  : selectedMunicipality.length === 1
                  ? ` i ${selectedMunicipality[0]}`
                  : ""}
              </h1>
              <p>
                Vakansa har ett brett utbud av alla möjliga lokaler som kan
                hyras i andrahand per timme, dag, vecka, månad och helst i flera
                år.
              </p>
              <div className="ad-section-head-inner">
                <b className="ad-section-head-inner">
                  Hittar du inte det du söker? &nbsp;
                  <div>
                    <TextButton
                      label="Klicka här"
                      color="green"
                      size="xxs"
                      href="/lokalforslag"
                    />
                    &nbsp; så hjälper vi dig!
                  </div>
                </b>
              </div>
            </div>

            <div className="ad-section-search">
              <SearchInput value={searchTerm} onChange={setSearchTerm} />
              <label className="toggle">
                <div className="ad-setion-toggle-container">
                  <input
                    className="toggle-checkbox"
                    type="checkbox"
                    checked={showMap}
                    onChange={() => setShowMap((prevState) => !prevState)}
                  />
                  <div className="toggle-switch"></div>
                  <p className="toggle-label">Visa karta</p>
                </div>
              </label>
            </div>

            <section className="ad-section-filters" aria-label="filter-section">
              <FilterDropdownButton
                label="Lokaler"
                dropdownContent={
                  <SpaceDropdown
                    selectedRoomTypes={selectedRoomTypes}
                    setSelectedRoomTypes={setSelectedRoomTypes}
                    closeSpaceDropdown={closeSpaceDropdown}
                  />
                }
                isDropdownVisible={isSpaceDropdownVisible}
                toggleDropdown={toggleSpaceDropdown}
                isActive={selectedRoomTypes.length > 0}
              />

              <FilterDropdownButton
                label="Kommuner"
                dropdownContent={
                  <MunicipalityDropdown
                    selectedMunicipality={selectedMunicipality}
                    setSelectedMunicipality={setSelectedMunicipality}
                    closeMunicipalityDropdown={closeMunicipalityDropdown}
                  />
                }
                isDropdownVisible={isMunicipalityDropdownVisible}
                toggleDropdown={toggleMunicipalityDropdown}
                isActive={selectedMunicipality.length > 0}
              />
              <div className="ad-section-filters-inner">
                <FilterDropdownButton
                  label="Tider"
                  dropdownContent={
                    <TimeDropdown
                      selectedTime={selectedTime}
                      setSelectedTime={setSelectedTime}
                      closeTimeDropdown={closeTimeDropdown}
                    />
                  }
                  isDropdownVisible={isTimeDropdownVisible}
                  toggleDropdown={toggleTimeDropdown}
                  isActive={selectedTime.length > 0}
                />

                <FilterDropdownButton
                  label="Pris"
                  dropdownContent={
                    <PriceDropdown
                      selectedPriceRange={selectedPriceRange}
                      setSelectedPriceRange={setSelectedPriceRange}
                      closePriceDropdown={closePriceDropdown}
                    />
                  }
                  isDropdownVisible={isPriceDropdownVisible}
                  toggleDropdown={togglePriceDropdown}
                  isActive={
                    selectedPriceRange.min !== 0 ||
                    selectedPriceRange.max !== 50000
                  }
                />

                <FilterDropdownButton
                  label="Yta"
                  dropdownContent={
                    <AreaDropdown
                      selectedAreaRange={selectedAreaRange}
                      setSelectedAreaRange={setSelectedAreaRange}
                      closeAreaDropdown={closeAreaDropdown}
                    />
                  }
                  isDropdownVisible={isAreaDropdownVisible}
                  toggleDropdown={toggleAreaDropdown}
                  isActive={
                    selectedAreaRange.min !== 0 ||
                    selectedAreaRange.max !== 2000
                  }
                />

                <FilterDropdownButton
                  label="Moms"
                  dropdownContent={
                    <MomsDropdown
                      selectedMoms={selectedMoms}
                      setSelectedMoms={setSelectedMoms}
                      closeMomsDropdown={closeMomsDropdown}
                    />
                  }
                  isDropdownVisible={isMomsDropdownVisible}
                  toggleDropdown={toggleMomsDropdown}
                  isActive={selectedMoms.length > 0}
                />
              </div>
            </section>

            <div className="ad-section-top">
              <div className="results-counter">
                <p className="ad-page-results">
                  {filteredData.length}&nbsp; resultat
                </p>
              </div>

              <TextButton
                label="Rensa"
                color="green"
                size="xxs"
                onClick={clearSessionStorage}
              />
              <select
                aria-label="filter-sort-area-price"
                className="ad-page-filter-select"
                value={sortingOrder}
                onChange={handleSortingChange}
              >
                <option value="natural">Välj sortering</option>
                <option value="smallToBig">Area minst till störst</option>
                <option value="bigToSmall">Area störst till minst</option>

                <option value="priceSmallToBig">Hyra minst till störst</option>
                <option value="priceBigToSmall">Hyra störst till minst</option>
              </select>
            </div>
          </div>

          {renderedAds.length === 0 && <NoResults id={id} />}

          <div>
            <ul className="ad-cards-container">{renderedAds}</ul>

            {totalPages > 1 && (
              <div className="pagination-container">
                <button
                  aria-label="pagination-arrow-back"
                  className="pagination-arrow"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <svg
                    viewBox="0 0 384 512"
                    fill="currentColor"
                    height="1em"
                    width="0.5em"
                    {...props}
                  >
                    <path d="M380.6 81.7c7.9 15.8 1.5 35-14.3 42.9L103.6 256l262.7 131.4c15.8 7.9 22.2 27.1 14.3 42.9s-27.1 22.2-42.9 14.3l-320-160C6.8 279.2 0 268.1 0 256s6.8-23.2 17.7-28.6l320-160c15.8-7.9 35-1.5 42.9 14.3z" />
                  </svg>
                </button>

                <div>
                  {Array.from(
                    {
                      length: displayedPageRange[1] - displayedPageRange[0] + 1,
                    },
                    (_, index) => {
                      const pageNumber = displayedPageRange[0] + index;
                      return (
                        <button
                          key={pageNumber}
                          onClick={() => handlePageChange(pageNumber)}
                          className={currentPage === pageNumber ? "active" : ""}
                        >
                          {pageNumber}
                        </button>
                      );
                    }
                  )}
                </div>

                <button
                  aria-label="pagination-arrow-forward"
                  className="pagination-arrow"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <svg
                    viewBox="0 0 384 512"
                    fill="currentColor"
                    height="1em"
                    width="0.5em"
                    {...props}
                  >
                    <path d="M3.4 81.7c-7.9 15.8-1.5 35 14.3 42.9L280.5 256 17.7 387.4c-15.8 7.9-22.2 27.1-14.3 42.9s27.1 22.2 42.9 14.3l320-160c10.8-5.4 17.7-16.5 17.7-28.6s-6.8-23.2-17.7-28.6l-320-160c-15.8-7.9-35-1.5-42.9 14.3z" />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </div>

        {showMap && (
          <div className={`map-placeholder ${showMap ? "map-visible" : ""}`}>
            <div className="map-wrap-time">
              <MapSection ad={filteredData} />
            </div>
          </div>
        )}
      </main>
      <InterestModal />
    </>
  );
};

export default AdPage;
