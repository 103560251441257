import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import RoundedButton from "../../components/Buttons/RoundedButton/RoundedButton";
import axios from "axios";
import { backend } from "../../backend";
import "./request.css";
import SEOTags from "../../utils/SEOTags";
import Team from "../../assets/illustrations/team2.png";

export default function Request() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const selectedKontraktForm = useWatch({
    name: "kontraktForm",
    control,
  });
  const selectedOrganisationsTyp = useWatch({
    name: "organisationsTyp",
    control,
  });
  const selectedPrisTyp = useWatch({
    name: "prisTyp",
    control,
  });
  const selectedmoms = useWatch({
    name: "moms",
    control,
  });

  const onSubmit = async (formData) => {
    try {
      const success = await handleFormSubmit(formData);
      if (success) {
        window.scrollTo(0, 0);
      }
    } catch (error) {
      setErrorMessage("Ett fel uppstod, försök igen senare.");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFormSubmit = async (formData) => {
    try {
      const response = await axios.post(
        backend("/api/contact/feed/feedback"),
        formData
      );
      if (response.status === 201) {
        setFormSubmitted(true);
      } else {
        setErrorMessage(
          response.data.msg || "Ett fel uppstod, försök igen senare."
        );
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(
          error.response.data.msg || "Ett fel uppstod, försök igen senare."
        );
      } else {
        setErrorMessage("Ett fel uppstod, försök igen senare.");
      }
    }
  };

  if (formSubmitted) {
    return (
      <div className="request-form-section">
        <h2>Tack för din lokalförfrågan!</h2>
        <div className="request-form-subheading">
          <p>
            Din förfrågan ses över och vi återkommer inom kort med specifika
            lokalförslag som passar dina behov.
          </p>
          <img
            className="intro-img"
            src={Team}
            alt="Företagets logotyp med illustrerade människor som står tillsammans"
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <SEOTags
        title={"Förfrågan - Vakansa"}
        description={
          "Vakansa hjälper dig att hitta och matchas med de perfekta lokalerna som uppfyller just dina behov!"
        }
        canonicalUrl={"/lokalforslag"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <div className="request-form-section">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>Vakansa matchar dig med lokaler efter dina behov!</h2>
          <p className="request-form-subheading">
            Sluta leta!
            <br />
            Få skräddarsydda lokaler till er inom några dagar, kostnadsfritt!
            <br />
            <b>Spara värdefull tid</b> och få <b>konkreta förslag</b> med{" "}
            <b>tydliga priser</b> som matchar dina behov. Dessutom får du
            tillgång till <b>hundratals dolda objekt</b> som vi på Vakansa har
            tillgång till genom våra databaser.
            <br />
            <br />
            1. Beskriv dina behov här nedan på <b>2 minuter</b>.
            <br />
            2. Få <b>konkreta lokalförslag</b> som matchar dina behov inom några
            dagar, kostnadsfritt.
          </p>
          <div className="request-form-name">
            <p>Ditt namn*</p>
            <input {...register("namn", { required: true })} />

            {errors.namn && <span>Vänligen ange ett namn</span>}
          </div>
          <div className="request-form-email-number">
            <div>
              <p>E-postadress*</p>
              <input type="email" {...register("email", { required: true })} />
              {errors.email && (
                <span>Vänligen ange en giltig e-postadress</span>
              )}
            </div>
            <div>
              <p>Telefon</p>
              <input {...register("telefonnummer")} />
            </div>
          </div>

          <div className="request-form-email-number">
            <div>
              <p>Organisationen du representerar*</p>
              <input {...register("organisation", { required: true })} />
              {errors.organisation && (
                <span>Vänligen ange en organisation</span>
              )}
            </div>
            <div>
              <p>Organisationsnummer*</p>
              <input
                {...register("organisationNumber", {
                  required: true,
                  pattern: {
                    value: /^[0-9]{6}-?[0-9]{4}$/,
                  },
                })}
                placeholder="000000-0000"
              />
              {errors.organisationNumber && (
                <span>Vänligen ange ett organisationsnummer</span>
              )}
            </div>
          </div>

          <div className="request-form-email-number">
            <div>
              <p>I vilken kommun gäller lokalfrågan?</p>
              <input {...register("stad")} />
            </div>
            <div>
              <p>Någon specifik stadsdel?</p>
              <input {...register("part")} />
            </div>
          </div>

          <div className="request-form-checkbox-group">
            <p>Vilken typ av lokal gäller det?</p>
            <label className="request-form-checkbox-button">
              <input type="checkbox" value="Ateljé" {...register("typAvRum")} />
              &nbsp;
              <p>Ateljé</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Biograf"
                {...register("typAvRum")}
              />
              &nbsp;
              <p>Biograf</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Behandlingsrum"
                {...register("typAvRum")}
              />
              &nbsp;
              <p>Behandlingsrum</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Idrottshall"
                {...register("typAvRum")}
              />
              &nbsp;
              <p>Idrottshall</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Danslokal"
                {...register("typAvRum")}
              />
              &nbsp;
              <p>Danslokal</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Konferenslokal"
                {...register("typAvRum")}
              />
              &nbsp;
              <p>Konferenslokal</p>
            </label>
            <label className="request-form-checkbox-button">
              <input type="checkbox" value="Kontor" {...register("typAvRum")} />
              &nbsp;
              <p>Kontor</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Kontorshotell/Co-working"
                {...register("typAvRum")}
              />
              &nbsp;
              <p>Kontorshotell/Co-working</p>
            </label>
            <label className="request-form-checkbox-button">
              <input type="checkbox" value="Kyrka" {...register("typAvRum")} />
              &nbsp;
              <p>Kyrka</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Lager/Logistik"
                {...register("typAvRum")}
              />
              &nbsp;
              <p>Lager/Logistik</p>
            </label>
            <label className="request-form-checkbox-button">
              <input type="checkbox" value="Kök" {...register("typAvRum")} />
              &nbsp;
              <p>Kök</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Eventlokal"
                {...register("typAvRum")}
              />
              &nbsp;
              <p>Eventlokal</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Möteslokal"
                {...register("typAvRum")}
              />
              &nbsp;
              <p>Möteslokal</p>
            </label>
            <label className="request-form-checkbox-button">
              <input type="checkbox" value="Pop-up" {...register("typAvRum")} />
              &nbsp;
              <p>Pop-up</p>
            </label>
            <label className="request-form-checkbox-button">
              <input type="checkbox" value="Studio" {...register("typAvRum")} />
              &nbsp;
              <p>Studio</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Teaterlokal"
                {...register("typAvRum")}
              />
              &nbsp;
              <p>Teaterlokal</p>
            </label>
            <div className="request-form-checkbox-other">
              <label className="request-form-checkbox-button">
                <input
                  type="checkbox"
                  value="Övrigt"
                  {...register("typAvRum")}
                />
                &nbsp;
                <p>Övrigt:</p>
              </label>
              <label className="request-form-name">
                <input
                  name="descriptionRent"
                  className="form-control"
                  placeholder="Skriv ditt meddelande här..."
                  {...register("typAvRumOther")}
                />
              </label>
            </div>
          </div>

          <div className="request-form-name">
            <div>
              <p>Ytterligare beskrivning av lokalerna</p>
              <input
                placeholder="Exempel"
                {...register("ytterligareBeskrivning")}
              />
            </div>
          </div>

          <div className="request-form-radio-group">
            <p>Kan ni tänka er att hyra eller hyra ut i andrahand?</p>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Ja"
                {...register("andraHandsHyra", { defaultChecked: true })}
              />
              &nbsp;
              <p>Ja</p>
            </label>
            <label className="request-form-radio">
              <input type="radio" value="Nej" {...register("andraHandsHyra")} />
              &nbsp;
              <p>Nej</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Kanske"
                {...register("andraHandsHyra")}
              />
              &nbsp;
              <p>Kanske</p>
            </label>
          </div>

          <div className="request-form-checkbox-group">
            <p>När ska lokalen hyras?</p>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Lokalen ska hyras hela tiden"
                {...register("vilkaTider")}
              />
              &nbsp;
              <p>Lokalen ska hyras hela tiden</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Vardagar 6-18"
                {...register("vilkaTider")}
              />
              &nbsp;
              <p>Vardagar 6-18</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Kvällar 18-24"
                {...register("vilkaTider")}
              />
              &nbsp;
              <p>Kvällar 18-24</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Morgnar 00-05"
                {...register("vilkaTider")}
              />
              &nbsp;
              <p>Morgnar 00-05</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Helger dagtid"
                {...register("vilkaTider")}
              />
              &nbsp;
              <p>Helger dagtid</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Helger kvällstid"
                {...register("vilkaTider")}
              />
              &nbsp;
              <p>Helger kvällstid</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Under lov exempelvis påsklov"
                {...register("vilkaTider")}
              />
              &nbsp;
              <p>Under lov exempelvis "påsklov"</p>
            </label>
            <div className="request-form-checkbox-other">
              <label className="request-form-checkbox-button">
                <input
                  type="checkbox"
                  value="Övrigt"
                  {...register("andraTider")}
                />
                &nbsp;
                <p>Övrigt:</p>
              </label>
              <label className="request-form-name">
                <input
                  name="descriptionRent"
                  className="form-control"
                  placeholder="Skriv ditt meddelande här..."
                  {...register("andraTiderMessage")}
                />
              </label>
            </div>
          </div>

          <div className="request-form-radio-group">
            <p>Vad önskar ni för kontraktsform?</p>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Hyra per tillfälle"
                {...register("kontraktForm", { defaultChecked: true })}
              />
              &nbsp;
              <p>Hyra per tillfälle</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Korttidskontrakt (ca 6 månader)"
                {...register("kontraktForm")}
              />
              &nbsp;
              <p>Korttidskontrakt (ca 6 månader)</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Långtidskontrakt (ca 3-5 år)"
                {...register("kontraktForm")}
              />
              &nbsp;
              <p>Långtidskontrakt (ca 3-5 år)</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Co-working (arbetsplatser/rum)"
                {...register("kontraktForm")}
              />
              &nbsp;
              <p>Co-working (arbetsplatser/rum)</p>
            </label>
            <label className="request-form-radio-other">
              <input
                className="request-form-radio-other-radio"
                type="radio"
                value="Övrigt"
                {...register("kontraktForm")}
              />
              &nbsp;
              <p>Övrigt:</p>
              <input
                className="request-form-radio-other-text"
                type="text"
                {...register("kontraktFormOther")}
                placeholder="Ange övriga detaljer"
                disabled={selectedKontraktForm !== "Övrigt"}
              />
            </label>
          </div>

          <div className="request-form-radio-group">
            <p>Vad har ni för organisationsform?</p>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Förening"
                {...register("organisationsTyp")}
              />
              &nbsp;
              <p>Förening</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Aktiebolag"
                {...register("organisationsTyp")}
              />
              &nbsp;
              <p>Aktiebolag</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Enskild Firma"
                {...register("organisationsTyp")}
              />
              &nbsp;
              <p>Enskild Firma</p>
            </label>
            <label className="request-form-radio-other">
              <input
                className="request-form-radio-other-radio"
                type="radio"
                value="Övrigt"
                {...register("organisationsTyp")}
              />
              &nbsp;
              <p>Övrigt:</p>
              <input
                className="request-form-radio-other-text"
                type="text"
                {...register("organisationsTypOther")}
                placeholder="Ange övriga detaljer"
                disabled={selectedOrganisationsTyp !== "Övrigt"}
              />
            </label>
          </div>

          <div className="request-form-checkbox-group">
            <p>
              Om ni är en förening - får ni stöd från något studieförbund eller
              myndighet?
            </p>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Nej, vi får inget stöd"
                {...register("supportOrg")}
              />
              &nbsp;
              <p>Nej, vi får inget stöd</p>
            </label>
            <label className="request-form-checkbox-button">
              <input type="checkbox" value="ABF" {...register("supportOrg")} />
              &nbsp;
              <p>ABF</p>
            </label>
            <label className="request-form-checkbox-button">
              <input type="checkbox" value="NBV" {...register("supportOrg")} />
              &nbsp;
              <p>NBV</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Medborgarskolan"
                {...register("supportOrg")}
              />
              &nbsp;
              <p>Medborgarskolan</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Bilda"
                {...register("supportOrg")}
              />
              &nbsp;
              <p>Bilda</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Folkuniversitetet"
                {...register("supportOrg")}
              />
              &nbsp;
              <p>Folkuniversitetet</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Ibn Rushd"
                {...register("supportOrg")}
              />
              &nbsp;
              <p>Ibn Rushd</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Kulturens"
                {...register("supportOrg")}
              />
              &nbsp;
              <p>Kulturens</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Sensus"
                {...register("supportOrg")}
              />
              &nbsp;
              <p>Sensus</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Studiefrämjandet"
                {...register("supportOrg")}
              />
              &nbsp;
              <p>Studiefrämjandet</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Vuxenskolan"
                {...register("supportOrg")}
              />
              &nbsp;
              <p>Vuxenskolan</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Myndigheten för ungdoms- och civilsamhällesfrågor"
                {...register("supportOrg")}
              />
              &nbsp;
              <p>Myndigheten för ungdoms- och civilsamhällesfrågor</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Kommun"
                {...register("supportOrg")}
              />
              &nbsp;
              <p>Kommun</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Region"
                {...register("supportOrg")}
              />
              &nbsp;
              <p>Region</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="Länsstyrelse"
                {...register("supportOrg")}
              />
              &nbsp;
              <p>Länsstyrelse</p>
            </label>
            <div className="request-form-checkbox-other">
              <label className="request-form-checkbox-button">
                <input
                  type="checkbox"
                  value="Övrigt"
                  {...register("supportOrg")}
                />
                &nbsp;
                <p>Övrigt:</p>
              </label>
              <label className="request-form-name">
                <input
                  name="descriptionRent"
                  className="form-control"
                  placeholder="Skriv ditt meddelande här..."
                  {...register("supportOrgAnnan")}
                />
              </label>
            </div>
          </div>

          <div className="request-form-radio-group">
            <p>Vad har ni för budget?</p>
            <label className="request-form-radio">
              <input
                type="radio"
                value="1000 - 9 999 kr/månad"
                {...register("prisTyp")}
              />
              &nbsp;
              <p>1000 - 9 999 kr/månad</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="10 000 - 19 999 kr/månad"
                {...register("prisTyp")}
              />
              &nbsp;
              <p>10 000 - 19 999 kr/månad</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="20 000 - 29 999 kr/månad"
                {...register("prisTyp")}
              />
              &nbsp;
              <p>20 000 - 29 999 kr/månad</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="30 000 - 39 999 kr/månad"
                {...register("prisTyp")}
              />
              &nbsp;
              <p>30 000 - 39 999 kr/månad</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="40 000 - 49 999 kr/månad"
                {...register("prisTyp")}
              />
              &nbsp;
              <p>40 000 - 49 999 kr/månad</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="50 000 - 59 999 kr/månad"
                {...register("prisTyp")}
              />
              &nbsp;
              <p>50 000 - 59 999 kr/månad</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="60 000 - 69 999 kr/månad"
                {...register("prisTyp")}
              />
              &nbsp;
              <p>60 000 - 69 999 kr/månad</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="70 000 - 79 999 kr/månad"
                {...register("prisTyp")}
              />
              &nbsp;
              <p>70 000 - 79 999 kr/månad</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="80 000 - 89 999 kr/månad"
                {...register("prisTyp")}
              />
              &nbsp;
              <p>80 000 - 89 999 kr/månad</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="90 000+ kr/månad"
                {...register("prisTyp")}
              />
              &nbsp;
              <p>90 000+ kr/månad</p>
            </label>
            <label className="request-form-radio-other">
              <input
                className="request-form-radio-other-radio"
                type="radio"
                value="Övrigt"
                {...register("prisTyp")}
              />
              &nbsp;
              <p>Övrigt:</p>
              <input
                className="request-form-radio-other-text"
                type="text"
                {...register("prisTypOther")}
                placeholder="Ange övriga detaljer"
                disabled={selectedPrisTyp !== "Övrigt"}
              />
            </label>
          </div>

          <div className="request-form-checkbox-group">
            <p>Hur många kvadratmeter gäller det?</p>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="0-99 kvm"
                {...register("hurStor")}
              />
              &nbsp;
              <p>0-99 kvm</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="100 - 199 kvm"
                {...register("hurStor")}
              />
              &nbsp;
              <p>100 - 199 kvm</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="200-299 kvm"
                {...register("hurStor")}
              />
              &nbsp;
              <p>200-299 kvm</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="300 - 399 kvm"
                {...register("hurStor")}
              />
              &nbsp;
              <p>300 - 399 kvm</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="400 - 499 kvm"
                {...register("hurStor")}
              />
              &nbsp;
              <p>400 - 499 kvm</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="500 - 599 kvm"
                {...register("hurStor")}
              />
              &nbsp;
              <p>500 - 599 kvm</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="600 - 699 kvm"
                {...register("hurStor")}
              />
              &nbsp;
              <p>600 - 699 kvm</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="700 - 799 kvm"
                {...register("hurStor")}
              />
              &nbsp;
              <p>700 - 799 kvm</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="800 - 899 kvm"
                {...register("hurStor")}
              />
              &nbsp;
              <p>800 - 899 kvm</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="900 - 999 kvm"
                {...register("hurStor")}
              />
              &nbsp;
              <p>900 - 999 kvm</p>
            </label>
            <label className="request-form-checkbox-button">
              <input
                type="checkbox"
                value="1000+ kvm"
                {...register("hurStor")}
              />
              &nbsp;
              <p>1000+ kvm</p>
            </label>
          </div>

          <div className="request-form-radio-group">
            <p>Vad gäller när det kommer till moms?</p>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Lokal där moms läggs på"
                {...register("moms")}
              />
              &nbsp;
              <p>Lokal där moms läggs på</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Lokal utan moms"
                {...register("moms")}
              />
              &nbsp;
              <p>Lokal utan moms</p>
            </label>
            <label className="request-form-radio">
              <input type="radio" value="Vet inte" {...register("moms")} />
              &nbsp;
              <p>Vet inte</p>
            </label>
            <label className="request-form-radio-other">
              <input
                className="request-form-radio-other-radio"
                type="radio"
                value="Övrigt"
                {...register("moms")}
              />
              &nbsp;
              <p>Övrigt:</p>
              <input
                className="request-form-radio-other-text"
                type="text"
                {...register("momsAnnan")}
                placeholder="Ange övriga detaljer"
                disabled={selectedmoms !== "Övrigt"}
              />
            </label>
          </div>

          <div className="request-form-radio-group">
            <p>Kan ni tänka er att betala en överlåtelseavgift för en lokal?</p>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Ja"
                {...register("overlatelseAvgift", { defaultChecked: true })}
              />
              &nbsp;
              <p>Ja</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Nej"
                {...register("overlatelseAvgift")}
              />
              &nbsp;
              <p>Nej</p>
            </label>
            <label className="request-form-radio">
              <input
                type="radio"
                value="Kanske"
                {...register("overlatelseAvgift")}
              />
              &nbsp;
              <p>Kanske</p>
            </label>
          </div>

          <div className="request-form-message">
            <p>Har du/ni något önskemål som inte tas upp här?</p>
            <textarea
              placeholder="Skriv ditt meddelande här"
              {...register("message")}
            />
          </div>
          <br />
          <p>Fält markerade med * är obligatoriska</p>
          <br />
          <div className="request-form-checkbox">
            <input
              type="checkbox"
              {...register("checkbox", { required: true })}
            />
            <p>
              Jag har läst och accepterar{" "}
              <a
                href="/anvandarvillkor"
                target="_blank"
                rel="noopener noreferrer"
              >
                Användarvillkor
              </a>
            </p>
            <br />
            {errors.checkbox && <span>Du måste acceptera villkoren</span>}
          </div>
          <div className="request-form-error-msg">
            {errorMessage && errorMessage}
          </div>
          <div className="request-form-send-button-container">
            <RoundedButton
              className="request-form-send-button"
              color="green"
              label="Skicka"
              size="md"
            />
          </div>
        </form>
      </div>
    </>
  );
}
