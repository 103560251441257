import styled from "styled-components";

export const ForgotPasswordPage = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-light);
  border-bottom: 1px solid var(--gray-medium);
`;

export const ForgotPasswordComponent = styled.div`
  width: 60rem;
  border-radius: 52px;
  padding: 8rem 12rem 6rem;
  margin: 8rem 0 12rem;
  background-color: var(--white);
  box-shadow: var(--shadow-light);

  h1 {
    font-size: var(--font-size-small);
    font-family: var(--montserrat);
    font-weight: 700;
    margin-bottom: 5.5rem;
    text-align: center;
  }

  form {
    label {
      font-size: var(--font-size-xx-small);
    }

    input {
      display: block;
      width: 100%;
      height: 5rem;
      font-size: 1.6rem;
      padding: 0 2.5rem;
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      border: 1px solid #c6cbcf;
      border-radius: 50px;
    }

    span {
      color: red;
      margin-bottom: 1rem;
    }

    div {
      display: block;
      text-align: center;

      #forgot-password-confirm {
        text-align: center;
        white-space: nowrap;
        color: var(--green);
        margin-bottom: 2rem;
      }

      button {
        width: 18rem;
        height: 5rem;
        margin-top: 2.5rem;
        margin-bottom: 2rem;
        border-radius: 29px;
        font-size: 1.8rem;
        font-weight: 700;
        border: none;
        color: var(--white);
        background-color: var(--green);
        box-shadow: var(--shadow-light);
      }
    }
  }

  p {
    font-size: var(--font-size-xx-small);
    padding-top: 1rem;
    margin-bottom: 2.5rem;
  }

  a {
    color: var(--green);
  }

  #forgot-password-server-error-msg {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    color: var(--red);
    margin-bottom: 2rem;
  }

  .forgot-password-input-container {
    display: grid;
  }

  .forgot-password-error-msg {
    font-size: 1.7rem;

    color: var(--red);
    margin-left: 0rem;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    border-radius: 28px;
    padding: 4rem 6rem 3rem;
    margin: 4rem 0 6rem;
  }
`;

export const ResendValidationComponent = styled.div`
  width: 60rem;
  border-radius: 52px;
  padding: 8rem 12rem 6rem;
  margin: 8rem 0 12rem;
  background-color: var(--white);
  box-shadow: var(--shadow-light);

  h1 {
    font-size: var(--font-size-small);
    font-family: var(--montserrat);
    font-weight: 700;
    margin-bottom: 5.5rem;
    text-align: center;
  }

  form {
    label {
      font-size: var(--font-size-xx-small);
    }

    input {
      display: block;
      width: 100%;
      height: 5rem;
      font-size: 1.6rem;
      padding: 0 2.5rem;
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      border: 1px solid #c6cbcf;
      border-radius: 50px;
    }

    span {
      color: red;
      margin-bottom: 1rem;
    }

    div {
      display: block;
      text-align: center;

      #forgot-password-confirm {
        text-align: center;
        white-space: nowrap;
        color: var(--green);
        margin-bottom: 2rem;
      }

      button {
        width: 18rem;
        height: 5rem;
        margin-top: 2.5rem;
        margin-bottom: 2rem;
        border-radius: 29px;
        font-size: 1.8rem;
        font-weight: 700;
        border: none;
        color: var(--white);
        background-color: var(--green);
        box-shadow: var(--shadow-light);
      }
    }
  }

  p {
    font-size: var(--font-size-xx-small);
    padding-top: 1rem;
    margin-bottom: 2.5rem;
  }

  a {
    color: var(--green);
  }

  #forgot-password-server-error-msg {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    color: var(--red);
    margin-bottom: 2rem;
  }

  .forgot-password-error-msg {
    font-size: 1.7rem;

    color: var(--red);
    margin-left: 0rem;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    border-radius: 28px;
    padding: 4rem 2rem 3rem;
    margin: 4rem 0 6rem;
  }
`;
