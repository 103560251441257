import { createContext } from "react";

//  Reducer function
export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_MULTIPLE:
      return { ...state, ...action.payload };
    case actionTypes.UPDATE:
      return { ...state, ...action.payload, };
    case actionTypes.SET_FILTER:
      return { ...state, ...action.payload };
    case actionTypes.TYPE_OF_ROOM:
      return { ...state, type_of_room: action.payload };
    case actionTypes.MUNICIPALITY:
      return { ...state, municipality: action.payload };
    case actionTypes.PRICE:
      return {
        ...state,
        min_rent: action.payload[0],
        max_rent: action.payload[1],
      };
    case actionTypes.PRICE_TYPES:
      return {
        ...state,
        priceTypes: action.payload
      };
    case actionTypes.AREA:
      return {
        ...state,
        min_area: action.payload[0],
        max_area: action.payload[1],
      };
    case actionTypes.RESET:
      return {};
    default:
      throw new Error("Action type is not recognised");
  }
};

export const actionTypes = {
  SET_MULTIPLE: "SET_MULTIPLE",
  TYPE_OF_ROOM: "TYPE_OF_ROOM",
  MUNICIPALITY: "MUNICIPALITY",
  PRICE: "PRICE",
  PRICE_TYPES: "PRICE_TYPES",
  AREA: "AREA",
  RESET: "RESET",
  SET_FILTER: "SET_FILTER",
  UPDATE: "UPDATE",
};

// Initial reducer state
export const initialState = {};

// Create search context
const SearchFilterContext = createContext();
export default SearchFilterContext;
