// Libraries
import React, { useEffect, useReducer, useState } from "react";
import { Route, Routes } from "react-router-dom";

// Context
import AdPreviewContext from "./context/AdPreviewContext";
import SearchFilterContext, {
  reducer,
  initialState,
} from "./context/SearchFilterContext";
import { AuthProvider, RequireAuth } from "./context/AuthContext";

// Components
import Navbar from "./components/menus/Navbar/Navbar.js";
import LoginModal from "./components/menus/LoginModal/LoginModal.js";
import AdPage from "./pages/AdPage/AdPage";
import Register from "./pages/Register/Register.js";
import Footer from "./components/menus/Footer/Footer.js";

import Home from "./pages/Home/Home.js";
import Ad from "./pages/Ad/Ad";
import RentOutSpace from "./pages/RentOutSpacePage/RentOutSpacePage.js";
import Coworking from "./pages/CoworkingPage/CoworkingPage.js";
import AboutUs from "./pages/AboutUsPage/AboutUsPage.js";
import Dashboard from "./pages/Dashboard/Dashboard";
import AdPreview from "./pages/Dashboard/components/AdPreview";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Terms from "./pages/Terms/Terms";
import FAQ from "./pages/FAQ/FAQ";
import LandlordFAQ from "./pages/FAQ/LandlordFAQ/LandlordFAQ.js";
import RenterFAQ from "./pages/FAQ/RenterFAQ/RenterFAQ.js";
import VakansaFAQ from "./pages/FAQ/VakansaFAQ/VakansaFAQ.js";
import NotFound from "./pages/NotFound/NotFound";
import ContactUs from "./pages/ContactUs/ContactUs";
import Interested from "./pages/DeclarationOfInterest/Interested.js";
import ResendValidationPage from "./pages/ResendValidation/ResendValidationPage.js";
import Request from "./pages/Request/Request.js";
import PopularAdsPage from "./pages/PopularAdsPage/PopularAdsPage.js";
import Subletting from "./pages/Subletting/Subletting.jsx";
import Sustainability from "./pages/Sustainability/Sustainability.jsx";
import ForRenter from "./pages/ForRenter/ForRenter.jsx";

function App() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [adPreviewInfo, setAdPreviewInfo] = useState({
    info: {},
    images: [],
    hasChanges: false,
  });

  const [searchFilter, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" }); // scrolls to top on first render
  }, []);

  return (
    <>
      <AuthProvider>
        <SearchFilterContext.Provider value={{ searchFilter, dispatch }}>
          <AdPreviewContext.Provider
            value={{ adPreviewInfo, setAdPreviewInfo }}
          >
            <Navbar setShowLoginModal={setShowLoginModal} />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/lediga-lokaler/*" element={<AdPage />} />
              <Route
                path="/lediga-lokaler/:municipality/:type_of_room/:address/:id"
                element={<Ad />}
              />
              <Route path="/intresseanmalan" element={<Interested />} />
              <Route path="/kontorshotell" element={<Coworking />} />
              <Route path="/hyr-ut-lokal" element={<RentOutSpace />} />
              <Route path="/hyr-en-lokal" element={<ForRenter />} />
              <Route path="/om-oss" element={<AboutUs />} />
              <Route path="/populara-lokaltyper" element={<PopularAdsPage />} />
              <Route path="/anvandarvillkor" element={<Terms />} />
              <Route path="/kontakta-oss" element={<ContactUs />} />
              <Route path="/andrahandsuthyrning" element={<Subletting />} />
              <Route path="/hallbarhet" element={<Sustainability />} />
              <Route
                path="/fragor-och-svar/hyresvard"
                element={<LandlordFAQ />}
              />
              <Route
                path="/fragor-och-svar/hyresgast"
                element={<RenterFAQ />}
              />
              <Route
                path="/fragor-och-svar/vad-ar-vakansa"
                element={<VakansaFAQ />}
              />
              <Route path="/fragor-och-svar">
                <Route index element={<FAQ />} />
                <Route path=":id" element={<FAQ />} />
              </Route>
              <Route path="/lokalforslag" element={<Request />} />
              <Route path="/registrera-konto" element={<Register />} />
              <Route
                path="/skicka-validering"
                element={<ResendValidationPage />}
              />
              <Route path="/glomt-losenord">
                <Route path="e-post" element={<ForgotPassword emailStep />} />
                <Route
                  path="valj-losenord/:token"
                  element={<ForgotPassword />}
                />
              </Route>
              {/* Acc gets verified when this route is reached */}
              <Route
                path="/verifiera-konto/:userId"
                element={<Home verifyUser />}
              />
              <Route path="/mina-sidor/:page">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path=":id" // for fetching ad data when editing ad
                  element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="forhandsvy"
                  element={
                    <RequireAuth>
                      <AdPreview />
                    </RequireAuth>
                  }
                />
                <Route
                  path="forhandsvy"
                  element={
                    <RequireAuth>
                      <AdPreview />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="/*" element={<NotFound />} />
            </Routes>
            <LoginModal
              showModal={showLoginModal}
              setShowModal={setShowLoginModal}
            />
            <Footer />
          </AdPreviewContext.Provider>
        </SearchFilterContext.Provider>
      </AuthProvider>
    </>
  );
}

export default App;
