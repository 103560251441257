import React from "react";
import "./activeLinkButton.css";
import { Link } from "react-router-dom";

export default function ActiveLinkButton({ size, label, isActive, href }) {
  return (
    <Link
      className={`active-link-button ${isActive ? "active" : ""} ${size}`}
      to={href}
    >
      {label}
    </Link>
  );
}
