/**
 * Prepends the provided url with the backend API URL
 * In local development, this is picked up by .env.local while
 * deployment value comes from the environment variable
 * REACT_APP_BACKEND_API
 * @param url the url to be expanded
 * @returns a complete URL to call teh backend API
 */
export function backend(url) {
    return process.env.REACT_APP_BACKEND_API + url;
}
