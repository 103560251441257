import React from "react";
import "./pricing.css";
import { useAuth } from "../../../context/AuthContext";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";

export default function Pricing() {
  const auth = useAuth();

  const CheckSquare = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.02 59.77">
        <defs></defs>
        <g id="a" />
        <g id="b">
          <g>
            <path
              className="d"
              d="M48.28,16.41c-.66-1.19-1.7-2.14-2.95-2.7-.31-.14-.64-.26-.97-.35-.53-.14-1.1-.22-1.68-.22H21.51c-3.55,0-6.43,2.88-6.43,6.43v20.64c0,3.55,2.88,6.43,6.43,6.43h21.17c3.55,0,6.43-2.88,6.43-6.43V19.56c0-1.14-.3-2.22-.83-3.15Zm-2.28,23.61c0,2.16-1.75,3.9-3.9,3.9H22.01c-2.16,0-3.9-1.75-3.9-3.9V19.95c0-2.15,1.75-3.9,3.9-3.9h20.08c1.74,0,3.21,1.14,3.72,2.71l-11.35,10.76c-.62,.59-1.6,.57-2.19-.05l-5.16-5.33c-.6-.61-1.58-.63-2.2-.03l-2.85,2.76c-.62,.6-.63,1.58-.04,2.2l10.03,10.35c.59,.61,1.57,.63,2.19,.05l11.75-11.13v11.7Z"
            />
            <path
              className="f"
              d="M45.99,19.95v8.38l-11.75,11.13c-.62,.59-1.6,.57-2.19-.05l-10.03-10.35c-.6-.62-.58-1.6,.04-2.2l2.85-2.76c.62-.6,1.6-.58,2.2,.03l5.16,5.33c.59,.61,1.57,.63,2.19,.05l11.35-10.76c.12,.38,.19,.78,.19,1.19Z"
            />
            <path d="M54.46,20.3l-5.35,5.07-3.11,2.95-11.75,11.13c-.62,.59-1.6,.57-2.19-.05l-10.03-10.35c-.6-.62-.58-1.6,.04-2.2l2.85-2.76c.62-.6,1.6-.58,2.2,.03l5.16,5.33c.59,.61,1.57,.63,2.19,.05l11.35-10.76,2.47-2.34,1.32-1.25c.62-.59,1.61-.57,2.2,.06l2.73,2.88c.59,.62,.57,1.61-.06,2.2Z" />
            <line className="e" x1="45.99" y1="33.27" x2="49.11" y2="30.23" />
            <path d="M48.23,31.26c-.56,0-1.01,.45-1.01,1.01s.45,1.01,1.01,1.01,1.01-.45,1.01-1.01-.45-1.01-1.01-1.01h0Z" />
            <path d="M44.63,13.31c-.45,0-.82,.37-.82,.82s.37,.82,.82,.82,.82-.37,.82-.82-.37-.82-.82-.82h0Z" />
            <line className="c" x1="43.39" y1="16.22" x2="45.07" y2="14.54" />
          </g>
        </g>
      </svg>
    );
  };

  const destinationUrl = !auth.user
    ? "/registrera-konto"
    : "/mina-sidor/skapa-annons";

  return (
    <div className="how-it-works-pricing-wrapper">
      <br />
      <h3 id="how-it-works-title">Vakansas prismodell</h3>
      <div className="how-it-works-pricing-text">
        <p>
          För mer information om prismodellen och våra användarvillkor klicka{" "}
          <a href={"/anvandarvillkor"}>här</a>. Alla priser är exklusive moms
        </p>
      </div>
      <div className="how-it-works-pricing">
        <div className="how-it-works-pricing-card how-it-works-pricing-bas">
          <span id="bas-span">BAS</span>
          <h4>Vakansa Bas</h4>
          <p>
            Vid lyckad förmedling av betalande hyresgäster till er/era lokaler,
            tar vi på Vakansa 15% av hyresintäkterna som förmedlingsavgift per
            månad i upp till maximalt 12 månader.
          </p>
          <br />
          <ul>
            <li>
              <CheckSquare />
              Registrera ett konto för att inventera era lediga ytor
            </li>
            <li>
              <CheckSquare />
              Annonsera utvalda ytor kostnadsfritt på vår marknadsplats
            </li>
            <li>
              <CheckSquare />
              Få löpande förfrågningar på era lokaler från hyresgäster
            </li>
            <li>
              <CheckSquare />
              Välj till vår marknadsföringstjänst “Vakansa Boost” för spridning
              av era annonser på sociala medier för att öka chanserna till
              uthyrning - fr 500 kr/månad.
            </li>
          </ul>

          <a href={destinationUrl}>
            {!auth.user ? "Skapa konto" : "Skapa annons"}
          </a>
        </div>
        <div className="how-it-works-pricing-card how-it-works-pricing-mest">
          <span id="mest-span">MEST</span>
          <h4>Vakansa Mest</h4>
          <p>
            Allt som ingår i Vakansa Bas, inklusive möjlighet att välja en eller
            flera av nedan kringtjänster för hantering vid uthyrning av era
            lokaler:
          </p>
          <ul>
            <li>
              <CheckSquare />
              Hjälp med avtal
            </li>
            <li>
              <CheckSquare />
              Hjälp med momsregler
            </li>
            <li>
              <CheckSquare />
              Hjälp med bokningssystem
            </li>
            <li>
              <CheckSquare />
              Hjälp med nyckelhantering
            </li>
            <li>
              <CheckSquare />
              Hjälp med försäkring
            </li>
          </ul>
          <RoundedLinkButton
            label={"Pris på förfrågan"}
            color={"whitest"}
            size={"lg"}
            href={"/kontakta-oss"}
          />
        </div>
      </div>
    </div>
  );
}
