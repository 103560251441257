import React from "react";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";
import "./renter.css";
import howImg from "../../../assets/illustrations/how-it-works-person.png";

export default function Renter() {

  return (
    <div className="rent-out-space-renter-section">
      <div className="rent-out-space-renter-info">
        <img
          src={howImg}
          alt="Person with a question mark above it's head looking at phone screen"
        />
        <div className="rent-out-space-inside-text">
          <div>
            <h2>För lokalsökande</h2>
            <p>
              Saknar du en lokal för din förening eller organisation där ni kan
              vistas? Att hyra en lokal med Vakansa är superenkelt!
            </p>
          </div>
          <RoundedLinkButton
            color="green"
            label="Alla annonser"
            size="lg"
            href={"/lediga-lokaler"}
          />
        </div>
      </div>
    </div>
  );
}
