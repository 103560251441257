import React, { useState, useEffect } from "react";
import { backend } from "../../backend";
import axios from "axios";
import { logAxiosError } from "../../logging/error";
import UnitTypeSection from "./UnitTypeSection/UnitTypeSection";
import "./popularAdsPage.css";
import SEOTags from "../../utils/SEOTags";

const PopularAdsPage = () => {
  const [ads, setAds] = useState([]);

  const fetchFilteredAds = async (filterParams) => {
    try {
      const { data } = await axios.get(backend("/api/listings/filter"), {
        params: filterParams,
      });
      return { ads: data.ads };
    } catch (error) {
      logAxiosError(error);
      throw error;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" }); // scrolls to top on first render
    const filterParams = {};
    fetchFilteredAds(filterParams)
      .then((result) => {
        setAds(result.ads);
      })
      .catch((error) => {
        console.error("Error fetching ads:", error);
      });
  }, []);
  return (
    <>
      <SEOTags
        title={"Populära Lokaltyper - Vakansa"}
        description={
          "På Vakansa hittar du den perfekta lokalen för dina behov, vare sig det är kontor, biograf, konferensrum eller idrottshall, i hela landet."
        }
        canonicalUrl={"/populara-lokaltyper"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main className="outer-popular-ads-copy">
        <div className="popular-ads-copy-wrapper">
          <div className="popular-ads-copy-intro">
            <h1>Populära Lokaltyper</h1>
            <h2>
              Välkommen till <a href="https://vakansa.se">Vakansa.se</a>
              <br />
              Din Ledande Plattform för Uthyrning av Lokaler i Sverige
            </h2>

            <p>
              På <a href="https://vakansa.se">Vakansa.se</a> förstår vi vikten
              av att hitta rätt lokal som uppfyller dina krav och behov. Oavsett
              om du letar efter ett kontor, biograf, konferensrum eller
              idrottshall i våra storstäder <br />
              Stockholm, Malmö, Uppsala, Göteborg eller ute i landet, är vi din
              pålitliga partner för att hitta den perfekta lokalen för dig eller
              ditt företag.
            </p>
          </div>

          <div className="popular-ads-copy-list-wrapper">
            <div className="popular-ads-copy-list">
              <b>Upptäck vårt breda utbud av lokaler:</b>
              <ul>
                <li>
                  Kontor och Kontorshotell: Våra kontorslösningar är idealiska
                  för alla från startups till stora företag. <br />
                  Välj mellan privata kontorslokaler eller flexibla
                  kontorshotell som erbjuder allt från möbler till teknik – allt
                  under flexibla hyresavtal.
                </li>

                <li>
                  Möteslokaler: Våra professionella mötesrum är tillgängliga för
                  uthyrning per timme eller dag <br />
                  och är utrustade med all nödvändig teknologi för effektiva
                  möten och workshops.
                </li>

                <li>
                  Idrotts- och Danslokaler: Vi erbjuder anpassningsbara lokaler
                  för en mängd aktiviteter, perfekt för allt från yoga, gympa
                  till streetdance.
                </li>

                <li>
                  Eventlokaler: Planera ditt nästa stora event i en av våra
                  mångsidiga eventlokaler, perfekt för allt från företagsevent,
                  konferenser till privata festligheter.
                </li>
              </ul>

              <p>
                Med <a href="https://vakansa.se">Vakansa.se</a> får du tillgång
                till:
              </p>

              <ul>
                <li>
                  Ett brett utbud av lokaler i Stockholm, Malmö, Uppsala,
                  Göteborg och andra städer.
                </li>

                <li>Flexibla hyresavtal och konkurrenskraftiga priser.</li>

                <li>
                  En användarvänlig plattform som gör det enkelt att söka och
                  jämföra olika lokaler.
                </li>

                <li>
                  Professionell support och personlig service för att
                  säkerställa en smidig uthyrningsprocess.
                </li>
              </ul>
            </div>
          </div>

          <div className="popular-ads-copy-outro">
            <p>
              Använd sökord som "hyr kontor i Stockholm" eller "hyra danslokal i
              Malmö" för att snabbt hitta vad du letar efter. Vare sig du är en
              entreprenör, ett växande företag <br /> eller en kreativ själ som
              letar efter den perfekta arbetsplatsen eller dansstudion, är{" "}
              <a href="https://vakansa.se">Vakansa.se</a> här för att hjälpa dig
              hitta din idealiska lokal.
            </p>

            <h2>
              Sök efter din drömlokal på{" "}
              <a href="https://vakansa.se">Vakansa.se</a> idag och upptäck en
              plats som inte bara möter, utan överträffar dina förväntningar!
            </h2>
          </div>
        </div>
        <UnitTypeSection listings={ads} />
      </main>
    </>
  );
};

export default PopularAdsPage;
