import React from "react";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";
import "./vakansaFAQ.css";
import SEOTags from "../../../utils/SEOTags";

const VakansaFAQ = () => {
  return (
    <>
      <SEOTags
        title={"Vad är Vakansa? - Vakansa"}
        description={
          "Vakansa är en lokaldelningsplattform som underlättar för hyresgäster att börja dela lokaler med andra och göra det billigare för alla!"
        }
        canonicalUrl={"/fragor-och-svar/vad-ar-vakansa"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main>
        <div className="faq-info-wrapper">
          <h1>Vad är Vakansa?</h1>
          <>
            <p>
              Vakansa är en lokaldelningsplattform som underlättar för
              hyresgäster att börja dela lokaler med andra och göra det
              billigare för alla!
            </p>
            <br />
            <p>
              {" "}
              Genom att sammanföra outnyttjad yta i fastigheter såsom kontor,
              kontorshotell, biografer, danslokaler, skolor, idrottshallar,
              slöjdsalar, samlingslokaler, konferenslokaler och föreningslokaler
              med efterfrågan från potentiella hyresgäster, ger Vakansa
              hyresgäster tillgång till lokaler som kan hyras per timme, dag,
              vecka, månad och gärna över flera år!
            </p>
            <br />
            <p>
              {" "}
              För att skapa en väl fungerande digital marknadsplats samarbetar
              Vakansa med fastighetsägare och hyresgäster av alla slag som har
              oanvända utrymmen. Syftet är att hjälpa dem att utnyttja sitt
              utrymme mer effektivt och säkert genom att dela det med andra
              hyresgäster som är i behov av flexibla, billiga och passande ytor.
              Om du själv äger ett outnyttjat utrymme som du vill erbjuda på
              marknadsplatsen, kan du enkelt annonsera dessa{" "}
              <a href="/hyr-ut-lokal">här</a>.
            </p>{" "}
            <h2>Vilka är fördelarna med att använda Vakansa?</h2>{" "}
            <ul>
              <li>Hitta olika lokaltyper efter dina eller era behov</li>
              <li>
                Finn lokaler över hela Sverige med vår digitala marknadsplats
              </li>
              <li>
                Spara pengar, slipp långa bindningstider och betala för ytor du
                faktiskt använder, när du behöver dem
              </li>
              <li>
                Öka nyttjandegraden genom att samnyttja lokaler med andra och
                gör det billigare för alla. På det sättet kan vi minska på
                antalet lokaler som står helt eller delvis tomma överallt i våra
                städer.
              </li>
              <li>
                Gå in på en annons och anmäl ditt intresse så återkommer
                hyresvärden till dig snarast
              </li>
            </ul>
          </>
          <br />
          <br />
          <p>
            Kontakta oss gärna på e-postadressen:{" "}
            <a href="mailto:info@vakansa.se">info@vakansa.se</a>,{" "}
            <a href="tel:+46767065662">Telefon: 076-706 56 62</a>
            <br />
            <br />
          </p>
          <br />
          <RoundedLinkButton
            className="button-faq-you"
            label="Tillbaka"
            size="md"
            color="white"
            href={"/fragor-och-svar"}
          />
        </div>
      </main>
    </>
  );
};

export default VakansaFAQ;
