import React from "react";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

const StyledSpinner = styled(CircularProgress)({
  zIndex: "calc(var(--z-index-backdrop) + 1)",
  "& .MuiCircularProgress-svg": {
    color: "var(--green)",
  },
});

function Spinner() {
  return <StyledSpinner size="8rem" thickness={3} />;
}

export default Spinner;
