import React, { useRef, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import "./navbar.css";
import MobileNav from "../MobileNav/MobileNav";
import { useAuth } from "../../../context/AuthContext";
import useOnClickOutside from "../../../customHooks/useOnClickOutside";
import Logo from "../../../assets/logo/mail-logo.png";

function Navbar({ setShowLoginModal }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setShowDropdown(false));

  const activeStyleHandler = ({ isActive }) => {
    return isActive ? { color: "var(--green)", fontWeight: "700" } : undefined;
  };

  const getUserInitials = () => {
    const firstname = auth.user?.first_name;
    const lastname = auth.user?.last_name;
    const organization = auth.user?.organization;

    if (firstname && lastname) {
      // If user has both first and last name, return initials
      return (firstname[0] + lastname[0]).toUpperCase();
    } else if (firstname) {
      // else if user has only first name, return first letter
      return firstname[0].toUpperCase();
    } else if (organization) {
      // else if user has only organization name, return its first letter
      return organization[0].toUpperCase();
    } else {
      // else as backup, return white version of the user icon
      return <img src="/images/icons/user-white.svg" alt="" />;
    }
  };

  return (
    <nav className="navbar-component" aria-label="navigation-menu">
      <img
        data-testid="navbar-logo"
        className="navbar-logo"
        width="150"
        height="38"
        rel="preload"
        alt="Vakansa logo"
        src={Logo}
        onClick={() => navigate("/")}
      />
      <nav>
        <NavLink to="/lediga-lokaler" style={activeStyleHandler}>
          Lediga lokaler
        </NavLink>
        <NavLink to="/hyr-ut-lokal" style={activeStyleHandler}>
          Hyr ut lokal
        </NavLink>
        <NavLink to="/om-oss" style={activeStyleHandler}>
          Om oss
        </NavLink>
      </nav>
      <div
        className="navbar-dropdown"
        onClick={() => setShowDropdown((prev) => !prev)}
        ref={dropdownRef}
      >
        <button
          aria-label="dropdown-button-sign-in"
          data-testid="navbar-dropdown"
          id="navbar-dropdown-icon"
        >
          {auth.user ? (
            <p>{getUserInitials()}</p>
          ) : (
            <img src="/images/icons/user.svg" alt="icon of a user" />
          )}
        </button>
        {showDropdown && (
          <div id="navbar-dropdown-menu">
            {auth.user ? (
              <>
                <Link to="/mina-sidor/annonser">Mina sidor</Link>
                <Link to="/mina-sidor/support">Support</Link>
                <hr />
                <button data-testid="log-out-btn" onClick={auth.logout}>
                  Logga ut
                </button>
              </>
            ) : (
              <>
                <button onClick={() => setShowLoginModal(true)}>
                  <b data-testid="navbar-login-btn">Logga in</b>
                </button>
                <Link data-testid="navbar-register" to="/registrera-konto">
                  Registrera dig
                </Link>
              </>
            )}
          </div>
        )}
      </div>
      <div className="navbar-mobile-login">
        <MobileNav setShowLoginModal={setShowLoginModal} />
      </div>
    </nav>
  );
}

export default Navbar;
