import React from "react";
import styled from "styled-components";

function WarningModal({
  heading,
  text,
  setShowModal,
  onCancel,
  onConfirm,
  cancelBtnText = "Avbryt",
  confirmBtnText = "Okej",
}) {
  const onCancelHandler = onCancel ? onCancel : () => setShowModal(false);
  const onConfirmHandler = onConfirm ? onConfirm : () => setShowModal(false);

  return (
    <>
      <WarningModalComponent>
        <h2>{heading}</h2>
        <p>{text}</p>
        <div className="warning-modal-btn-row">
          <button
            className="warning-modal-btn warning-modal-btn-cancel"
            onClick={onCancelHandler}
          >
            {cancelBtnText}
          </button>
          <button
            data-testid="confirm-btn-warningmodal"
            className="warning-modal-btn warning-modal-btn-confirm"
            onClick={onConfirmHandler}
          >
            {confirmBtnText}
          </button>
        </div>
        <button className="warning-modal-close-btn" onClick={onCancelHandler}>
          <img src="/images/icons/close.svg" alt="close-icon-button" />
        </button>
      </WarningModalComponent>
      <WarningModalBackdrop onClick={onCancelHandler} />
    </>
  );
}

const WarningModalComponent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-modal);
 width: 500px;
  padding: var(--spacing-lg);
  background-color: var(--white);
  border-radius: 28px;
  text-align: center;

  h2 {
    font-size: 3.6rem;
    font-weight: 700;
    margin-bottom: 1.75rem;
    white-space: nowrap;
  }

  p {
    font-size: 1.8rem;
    line-height: 25px;
    overflow-wrap: break-word;
    margin-bottom: 1rem;
  }

  span {
    font-size: 1.8rem;
    font-weight: 700;
    overflow-wrap: break-word;
    color: var(--red);
  }

  button {
    cursor: pointer;
  }
  
  .warning-modal-btn-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }

  .warning-modal-btn-wrapper {
    width: auto;
  }

  .warning-modal-btn {
    min-width: 17.5rem;
    padding: 1.75rem 5rem;
    white-space: nowrap;
    margin: 0 0.7rem;
    font-size: 1.8rem;
    font-weight: 700;
    text-decoration: none;
    border-radius: 29px;
    border: none;
    background-color: var(--green);
    color: var(--white);

    &-cancel {
      color: var(--text-dark);
      background-color: var(--white);
      box-shadow: var(--shadow-light);
    }

    &-confirm {
      color: var(--white);
      background-color: var(--red);
    }
  }

  .warning-modal-close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    height: 8rem;
    width: 8rem;
    background: none;
    border: none;
  }

  @media only screen and (max-width:550px) {
  width:430px;
  }

   @media only screen and (max-width:550px) {
  width:430px;
  }

  @media only screen and (max-width:450px) {
  width:400px;
  }

  @media only screen and (max-width:430px) {
  width:365px;

  h2 {
    margin-top: 2.75rem;
    margin-bottom: 0.2rem;
  }

  .warning-modal-btn-row {
    flex-direction: column;
    gap: 1rem;
  }
  }
`;

const WarningModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-backdrop);
  cursor: pointer;

  background-color: var(--gray-black);
  opacity: 0.5;
`;

export default WarningModal;
