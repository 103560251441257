import React, { useState, useEffect } from "react";
import "./areaDropdown.css";

import TextButton from "../../../../components/Buttons/TextButton/TextButton";

export default function AreaDropdown({
  selectedAreaRange,
  setSelectedAreaRange,
  closeAreaDropdown,
}) {
  const [, setIsSaveButtonPressed] = useState(false);
  const [minArea, setMinArea] = useState(selectedAreaRange.min);
  const [maxArea, setMaxArea] = useState(selectedAreaRange.max);

  const handleMinInputChange = (event) => {
    let value = event.target.value.trim();
    if (value === "") {
      setMinArea("");
    } else {
      value = Math.max(Number(value), 1);
      value = Math.min(value, maxArea);
      setMinArea(value);
    }
    handleAreaRangeChange({
      min: value === "" ? value : Number(value),
      max: maxArea,
    });
  };

  const handleMaxInputChange = (event) => {
    let value = event.target.value.trim();
    if (value === "") {
      setMaxArea("");
    } else {
      value = Math.max(Number(value), minArea);
      value = Math.min(value, 2000);
      setMaxArea(value);
    }
    handleAreaRangeChange({
      min: minArea,
      max: value === "" ? value : Number(value),
    });
  };

  const handleAreaRangeChange = ({ min, max }) => {
    setMinArea(min);
    setMaxArea(max);
    setSelectedAreaRange({ min, max });

    sessionStorage.setItem("selectedAreaRange", JSON.stringify({ min, max }));
  };

  const handleClearButtonClick = () => {
    setSelectedAreaRange({ min: 0, max: 2000 });
    setMinArea(0);
    setMaxArea(2000);
    sessionStorage.removeItem("selectedAreaRange");
    setIsSaveButtonPressed(false);
  };

  useEffect(() => {
    const savedSelectedAreaRange = JSON.parse(
      sessionStorage.getItem("selectedAreaRange")
    );
    if (savedSelectedAreaRange) {
      setSelectedAreaRange(savedSelectedAreaRange);
      setMinArea(savedSelectedAreaRange.min);
      setMaxArea(savedSelectedAreaRange.max);
      if (
        savedSelectedAreaRange.min !== 0 ||
        savedSelectedAreaRange.max !== 2000
      ) {
        setIsSaveButtonPressed(true);
      } else {
        setIsSaveButtonPressed(false);
      }
    }
  }, [setSelectedAreaRange]);

  return (
    <div className="area-dropdown">
      <p>Yta</p>
      <div className="area-slider">
        <div className="area-slider-container">
          <div className="area-slider-area">
            <div className="area-slider__left-value">
              <p>Min yta:</p>
              <input
                type="number"
                min={0}
                max={maxArea}
                value={minArea}
                defaultValue={minArea}
                onChange={handleMinInputChange}
              />
            </div>
            <div className="area-slider__right-value">
              <p>Max yta:</p>
              <input
                type="number"
                min={0}
                max={maxArea}
                value={maxArea}
                defaultValue={maxArea}
                onChange={handleMaxInputChange}
              />
              {maxArea === 2000 && <span className="area-slider-plus">+</span>}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="area-button-wrapper">
        <TextButton label="Rensa" onClick={handleClearButtonClick} />
      </div>
    </div>
  );
}
