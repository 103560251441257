import React from "react";
import importedFacilities from "../../../../assets/facilities.json";
import "./facilities.css";

const { facilities } = importedFacilities;

function Facilities({ ad }) {
  return (
    <div className="facilities-section">
      <p className="facilities-top-text">Vad som ingår i lokalen</p>
      <table>
        <tbody>
          {ad.facilities?.map((facility, i) => (
            <tr key={i}>
              <th scope="col">
                <img
                  src={facilities[facility]?.icon}
                  alt={`icon-for-${facilities[facility]?.key}`}
                />
              </th>
              <td>
               {facilities[facility]?.value}
              </td> 
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Facilities;
