import React from "react";
import "./roundedLinkButton.css";
import {Link} from 'react-router-dom'

export default function RoundedLinkButton({
  label,
  color = '',
  size = '',
  href,
}) {
  return (
    <Link
      className={`rounded-link-button ${color} ${size}`}
      to={href}
    >
      {label}
    </Link>
  );
}
