import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";
import { backend } from "../../../backend";
import { logAxiosError } from "../../../logging/error";
import WarningModal from "../../../components/WarningModal";

const defaultUserInfo = {
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  organization: "",
  organization_number: "",
};

function Settings() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(defaultUserInfo);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [userInfoMessage, setUserInfoMessage] = useState("");
  const [passwordMessage, setpasswordMessage] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    setUserInfo({ ...defaultUserInfo, ...auth.user });
  }, [auth.user]);

  const onChangeHandler = (e) => {
    setUserInfo((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmitUserInfoHandler = async (e) => {
    e.preventDefault();
    if (!userInfo) return;

    // make email lowercase
    const updatedUserInfo = {
      ...userInfo,
      email: userInfo.email.toLowerCase(),
    };

    try {
      const { data } = await axios.post(backend("/api/users/update"), {
        token: localStorage.getItem("token"),
        userInfo: updatedUserInfo,
      });

      if (data.status) {
        localStorage.setItem("user", JSON.stringify(data.user));
        setUserInfoMessage("Dina uppgifter har sparats");
        auth.setUser(data.user);
      }
    } catch (error) {
      logAxiosError(error);
      // TODO: notify user
      if (error.response?.status === 401) {
        // invalid JWT token -> log out and return to landing page
        auth.logout();
      }
    }
  };

  const onSubmitPasswordHandler = async (e) => {
    e.preventDefault();
    if (!passwordValidator()) return;

    try {
      const { data } = await axios.post(backend("/api/users/update/password"), {
        token: localStorage.getItem("token"),
        password,
      });

      if (data.status) {
        localStorage.setItem("user", JSON.stringify(data.user));
        setpasswordMessage("Ditt nya lösenord har sparats");
        setIsPasswordValid(true);
      }
    } catch (error) {
      logAxiosError(error);
      // TODO: notify user
      if (error.response?.status === 401) {
        // invalid JWT token -> log out and return to landing page
        auth.logout();
      }
    }
  };

  const passwordValidator = () => {
    if (password.length < 8) {
      setpasswordMessage("Lösenordet ska bestå av minst 8 tecken");
      setIsPasswordValid(false);
      return false;
    } else if (password !== repeatedPassword) {
      setpasswordMessage("Lösenorden matchar ej");
      setIsPasswordValid(false);
      return false;
    } else {
      return true;
    }
  };

  /// Function for deleting all ads - chaining the deleteAccountHandler
  const deleteAllAds = async () => {
    try {
      const { data } = await axios.delete(backend("/api/listings/delete/all"), {
        data: {
          token: localStorage.getItem("token"),
        },
      });
      if (data.status) {
        await deleteAccountHandler();
      }
    } catch (error) {
      logAxiosError(error);
    }
  };

  const deleteAccountHandler = async () => {
    try {
      const { data } = await axios.post(backend("/api/users/delete"), {
        token: localStorage.getItem("token"),
        first_name: auth.user.first_name,
        email: auth.user.email,
      });

      if (data.status) {
        auth.logout();
        navigate("/");
      }
    } catch (error) {
      logAxiosError(error);
      // TODO: notify user
      if (error.response?.status === 401) {
        // invalid JWT token -> log out and return to landing page
        auth.logout();
      }
    }
  };

  return (
    <>
      <SettingsContainer>
        <h2>Inställningar</h2>
        <div>
          <div id="settings-form-container">
            <form onSubmit={onSubmitUserInfoHandler}>
              <h3>Dina uppgifter</h3>
              <div>
                <label htmlFor="settings-first-name">Förnamn</label>
                <input
                  aria-label="first name"
                  id="settings-first-name"
                  name="first_name"
                  type="text"
                  onChange={onChangeHandler}
                  value={userInfo.first_name}
                />
              </div>
              <div>
                <label htmlFor="settings-last-name">Efternamn</label>
                <input
                  aria-label="last name"
                  id="settings-last-name"
                  name="last_name"
                  type="text"
                  onChange={onChangeHandler}
                  value={userInfo.last_name}
                />
              </div>
              <div>
                <label htmlFor="settings-email">E-postadress</label>
                <input
                  aria-label="email"
                  id="settings-email"
                  name="email"
                  type="email"
                  onChange={onChangeHandler}
                  value={userInfo.email}
                  disabled={true}
                />
              </div>
              <div>
                <label htmlFor="settings-mobile">Mobil</label>
                <input
                  aria-label="mobile"
                  id="settings-mobile"
                  name="mobile"
                  type="text"
                  onChange={onChangeHandler}
                  value={userInfo.mobile}
                />
              </div>
              <div>
                <label htmlFor="settings-organization">Organisation</label>
                <input
                  aria-label="organization"
                  id="settings-organization"
                  name="organization"
                  type="text"
                  onChange={onChangeHandler}
                  value={userInfo.organization}
                />
              </div>
              <div>
                <label htmlFor="settings-organization-number">
                  Organisationsnummer
                </label>
                <input
                  aria-label="organization number"
                  id="settings-organization-number"
                  name="organization_number"
                  type="text"
                  onChange={onChangeHandler}
                  value={userInfo.organization_number}
                />
              </div>
              {userInfoMessage && (
                <p className="settings-message-success">{userInfoMessage}</p>
              )}
              <button className="settings-save-btn">Spara ändringar</button>
            </form>
            <form onSubmit={onSubmitPasswordHandler}>
              <h3>Ändra lösenord</h3>
              <div>
                <label htmlFor="settings-password">Nytt lösenord</label>
                <div className="settings-form-show-password-div">
                  <input
                    aria-label="password"
                    id="settings-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <img
                    className="settings-form-show-password"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                    src={`/images/icons/eye_${
                      showPassword ? "visible" : "hidden"
                    }.svg`}
                    alt="Show password"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="settings-repeat-password">
                  Upprepa lösenord
                </label>
                <div className="settings-form-show-password-div">
                  <input
                    aria-label="repeat password"
                    id="settings-repate-password"
                    type={showRepeatPassword ? "text" : "password"}
                    value={repeatedPassword}
                    onChange={(e) => setRepeatedPassword(e.target.value)}
                  />
                  <img
                    className="settings-form-show-password"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    src={`/images/icons/eye_${
                      showRepeatPassword ? "visible" : "hidden"
                    }.svg`}
                    alt="Show password"
                  />
                </div>
              </div>
              {passwordMessage && (
                <p
                  className={
                    isPasswordValid
                      ? "settings-message-success"
                      : "settings-message-error"
                  }
                >
                  {passwordMessage}
                </p>
              )}
              <button className="settings-save-btn">Spara ändringar</button>
            </form>
          </div>
          <button
            className="settigns-block-btn settigns-block-btn--green"
            onClick={() => auth.logout()}
          >
            Logga ut
          </button>
          <button
            className="settigns-block-btn settigns-block-btn--red"
            onClick={() => setShowWarningModal(true)}
          >
            Avsluta konto
          </button>
        </div>
      </SettingsContainer>
      {showWarningModal && (
        <WarningModal
          heading="Avsluta konto"
          text="Är du säker att du vill avsluta ditt konto hos Vakansa? Detta går inte att återställa och alla dina annonser kommer tas bort."
          setShowModal={setShowWarningModal}
          onConfirm={() => {
            deleteAllAds();
          }}
          confirmBtnText="Avsluta"
        />
      )}
    </>
  );
}

const SettingsContainer = styled.div`
  h2 {
    font-size: 4.5rem;
    font-weight: 700;
  }

  h3 {
    font-size: 2.3rem;
    margin-bottom: 4rem;
  }

  button {
    cursor: pointer;
  }

  & > div {
    padding-top: 1rem;
    margin-left: 10.5%;
    margin-right: 10.5%;
  }

  #settings-form-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5rem;

    form {
      width: 45rem;

      &:first-child {
        margin-right: 15rem;
      }
    }

    label {
      font-size: 2rem;
        
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
    }

    input {
      width: 100%;
      height: 4rem;
      border: 1px solid var(--gray-dark);
      border-radius: 100px;
      padding: 0 2rem;
      margin-bottom: 1.7rem;
      font-size: 1.6rem;
    }

    p {
      font-size: 1.8rem;
        
      margin-left: 0.5rem;
      white-space: nowrap;
    }

    .settings-form-show-password-div {
      position: relative;
   }
    .settings-form-show-password {
       width: 30px;
       position: absolute;
       top: 35%; 
       right: 20px;
       transform: translate(50%,-50%);
   }

    .settings-message {
      font-size: 0.85rem;
      text-align: right;
      margin: 0;
    

      &-success {
        color: var(--green);
      }

      &-error {
        color: var(--red);
      }
    }
  }

  .settings-save-btn {
    display: block;
    width: 21rem;
    height: 5rem;
    margin: 2rem 1rem 0 auto;
    border-radius: 50px;
    border: none;
    font-size: 2rem;
    font-weight: 700;
    background-color: var(--green);
    color: var(--white);
  }

  .settigns-block-btn {
    display: block;
    width: 100%;
    height: 5rem;
    font-size: 1.8rem;
    font-weight: 700;
    border-radius: 50px;
    margin-bottom: 1.5rem;
    transition: all 0.125s ease;

    &--green {
      color: var(--white);
      background-color: var(--green);
      border: none;
    }

    &--red {
      border: 1px solid var(--red);
      color: var(--red);
      background-color: transparent;

      &:hover {
        background-color: var(--red);
        color: var(--white);
      }
    }
  }

  @media only screen and (max-width: 1480px) {
    & > div {
      margin-left: 0;
      margin-right: 0;
    }

    #settings-form-container form:first-child {
        margin-right: var(--spacing-md);
      }
    }
  }
  @media only screen and (max-width:980px) {
     #settings-form-container {
      display:flex;
      flex-direction: column;
       & form {
        width: 100%;

        & p {
            white-space: normal;
        }
       }
    }
    .settings-save-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;
    }
    h2 {
      font-size: 3.5rem;
    }
      & > div {
    padding-top: 5rem;
  }
  @media only screen and (max-width:470px) {
    #settings-form-container {
    form {
      width: 40rem !important;
    }
    }
  }

  @media only screen and (max-width:420px) {
    #settings-form-container {
    form {
      width: 35rem !important;
    }
    }
  }

   @media only screen and (max-width:370px) {
    #settings-form-container {
    form {
      width: 30rem !important;
    }
    }
  }
`;

export default Settings;
