import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import AdPreviewContext from "../../../context/AdPreviewContext";
import axios from "axios";
import { backend } from "../../../backend";
import { logAxiosError } from "../../../logging/error";
import WarningModal from "../../../components/WarningModal";
import { useCallbackPrompt } from "../../../context/useCallbackPrompt";
import { Helmet } from "react-helmet-async";
import Carousel from "../../Ad/sections/Carousel/Carousel";
import SimplifiedBooking from "../../Ad/sections/SimplifiedBooking/SimplifiedBooking";
import Heading from "../../Ad/sections/Heading/Heading";
import Info from "../../Ad/sections/Info/Info";
import Description from "../../Ad/sections/Description/Description";
import Facilities from "../../Ad/sections/Facilities/Facilities";
import RoundedButton from "../../../components/Buttons/RoundedButton/RoundedButton";

function AdPreview(props) {
  // eslint-disable-next-line no-unused-vars
  const [coordinates, setCoordinates] = useState(null);


  // Set to true when going back to form, this triggers
  // useEffect that disables prompt and navigates back
  const [goBack, setGoBack] = useState(false);

  // Warn users when leaving that their ad is not saved
  // enable and disable the prompt with this state
  const [showPrompt, setShowPrompt] = useState(false);
  const {
    showPrompt: showWarningModal,
    confirmNavigation,
    cancelNavigation,
  } = useCallbackPrompt(showPrompt);

  const {
    adPreviewInfo: { info, hasChanged },
    setAdPreviewInfo,
  } = useContext(AdPreviewContext);

  const location = useLocation();
  const navigate = useNavigate();

  const getCoordinates = async () => {
    try {
      const { data } = await axios.get(backend("/api/utilities/geolocate"), {
        params: {
          address: info?.address,
          city: info?.city,
          zip_code: info?.zip_code,
        },
      });

      if (data.status) {
        setCoordinates({
          lat: data.latitude,
          lng: data.longitude,
        });
      }
    } catch (error) {
      logAxiosError(error);
      // TODO: notify user?
    }
  };

  useEffect(() => {
    setShowPrompt(hasChanged);
    getCoordinates();

    // eslint-disable-next-line
  }, [hasChanged]);

  useEffect(() => {
    if (!goBack || showPrompt) return;

    // Navigate back to previous route
    navigate(location.state.prevRoute, { state: { from: "preview" } });

    // eslint-disable-next-line
  }, [goBack]);

  const navigateBackHandler = () => {
    // Disable prompt
    setShowPrompt(false);

    // Navigate back to form (in useEffect, wait to disable prompt)
    setGoBack(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" }); // scrolls to top on first render
  }, []);

  /**
   * Get all available price types for the ad. If the ad
   * does not have any prices. Say that price can be discussed
   * @param {object} ad - Ad object fetched from database
   */

  return (
    <>
      <Helmet>
        <title>Förhandsvisning</title>
      </Helmet>
      <AdComponent>
        <div id="ad-preview-nav">
          <RoundedButton
            size={"sm"}
            color={"green"}
            label={"Tillbaka"}
            onClick={navigateBackHandler}
          />
          <p>
            <i>*Det här är en förhandsvy av din annons</i>
          </p>
        </div>
        <div id="ad-page-body">
          <>
            <Carousel ad={info} />
            <SimplifiedBooking ad={info} />
            <div id="ad-page-info-prev">
              <Heading ad={info} />
              <Info ad={info} />
              <Description ad={info} />
              <Facilities ad={info} />
            </div>
            <div className="watermark">förhandsvy</div>
          </>
        </div>

      </AdComponent>
      {showWarningModal && (
        <WarningModal
          heading="Lämna utan att spara"
          text="Är du säker att du vill lämna sidan? Din annons kommer ej att sparas."
          confirmBtnText="Lämna"
          onCancel={cancelNavigation}
          onConfirm={() => {
            setAdPreviewInfo({ info: {}, images: [], hasChanged: false });
            confirmNavigation();
          }}
        />
      )}
    </>
  );
}

const AdComponent = styled.main`
    width: 100%;
  min-height: calc(100vh - var(--navbar-height));
  padding: 3rem var(--spacing-xs) 8rem;
  background-color: var(--gray-light);

  .watermark {
    position: fixed;
    top: 35%;
    left: 20%;
    opacity: 0.4;
    z-index: var(--z-index-above);
    transform: rotate(-35deg);
    color: var(--pink);
    font-size: 15rem;
  }

  #ad-preview-nav
    p,
    button {
      border: none;
      background: none;
      margin: 0;
      cursor: pointer;
      font-size: var(--font-size-xx-small)
    }
  }

  .ad-page-info-prev {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 980px) {
    .watermark {
      font-size: 7.5rem;
      left: 10%;
    }
  }

    @media only screen and (max-width: 480px) {
    .watermark {
      font-size: 7.2rem !important;
      left: 0%;
    }
  }

 width: 100%;
  min-height: calc(100vh - var(--navbar-height));
  padding: 3rem var(--spacing-xs) 8rem;
  background-color: var(--gray-light);

  #ad-page-body {
    max-width: 1620px;
    margin: 0 auto;
  }

  #ad-page-body {
    display: grid;
    grid-template-columns: 2fr minmax(360px, 1fr);
    grid-template-rows: 66.2rem auto;
    column-gap: 2.5%;

    #ad-page-images {
      grid-column: 1 / 2;
    }

    #ad-page-booking {
      grid-column: 2 / 3;
    }

    #ad-page-info {
      grid-row: 2 / 3;
    }
  }

  section {
    padding: 2rem 0.8rem;
    border-bottom: 1px solid #c4c3c3;

    h3 {
      font-size: 3rem;
      font-weight: 700;
        
      margin-bottom: 2.5rem;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  @media only screen and (max-width: 1560px) {
    #ad-page-body {
      grid-template-rows: 60rem auto;

      #ad-page-info {
        grid-column: 1 / -1;
        grid-row: 3 / 4;
      }
    }
  }

  @media only screen and (max-width: 980px) {
    #ad-page-body {
      grid-template-columns: 1fr;
      grid-template-rows: 47rem auto;
      gap: 2.4rem;

      #ad-page-images {
        grid-column: span 1;
        & div {
          height: 100%;
        }
      }

      #ad-page-booking {
        grid-column: span 1;
        grid-row: 2 / 3;
        justify-self: end;
        align-self: stretch;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    #ad-page-body {
      grid-template-rows: 40rem auto;
    }
}
`;

export default AdPreview;
