import React from "react";
import importedFacilities from "../../../assets/facilities.json";
import styled from "styled-components";

const { facilities } = importedFacilities;
function FacilitiesForm({ formData, setFormData }) {
  const isCheckedHandler = (facility) => {
    return formData?.facilities.includes(facility);
  };

  const addFacility = (facility) => {
    setFormData((prevData) => ({
      ...prevData,
      facilities: [...prevData?.facilities, facility],
    }));
  };

  const removeFacility = (facility) => {
    setFormData((prevData) => ({
      ...prevData,
      facilities: prevData?.facilities.filter(
        (current) => current !== facility
      ),
    }));
  };

  const onChangeHandler = (facility) => {
    if (formData?.facilities.includes(facility)) {
      removeFacility(facility);
    } else {
      addFacility(facility);
    }
  };

  return (
    <FacilitiesFormComponent>
      <h5>Vad ingår</h5>
      <div className="facilities-checkboxes-container">
        {Object.keys(facilities).map((facility, i) => (
          <div className="full-vacancy-checkbox-container">
            <label className="full-vacancy-checkbox-wrapper">
              <input
                key={i}
                id={`facilities-${facility}`}
                className="full-vacancy-checkbox-input"
                type="checkbox"
                checked={isCheckedHandler(facility)}
                onChange={() => onChangeHandler(facility)}
              />
              <span className="full-vacancy-checkbox-label">{facilities[facility].value}</span>
            </label>
          </div>
        ))}
      </div>
    </FacilitiesFormComponent>
  );
}

const FacilitiesFormComponent = styled.div`
  white-space: nowrap;
  margin-bottom: 1rem;

  .facilities-checkboxes-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 225px);
    margin-top: 4rem;

    & > * {
      width: 20%;
    }
  }
  @media only screen and (max-width: 767px) {
    .facilities-checkboxes-container {
      grid-template-columns: repeat(auto-fill, 175px);
      grid-gap: 0.5rem 5rem;
    }
  }
`;
export default FacilitiesForm;
